import React, {useEffect, useState} from 'react';
import {ConditionalComponent} from "../../../../vendors/internalComponents/ConditionalComponent/ConditionalComponent";
import {FileService} from "../../../services/file.service";

export default function CheckinCarteirinha(props) {
    const [url, setUrl] = useState('');
    const fileService = new FileService();
    useEffect(() => {
        if (props.controller.tipoAtendimento === 'P') {
            props.controller.goTo(5, {ieParticular: 'S'});
        }
    }, []);

    useEffect(() => {
        const carteirinha = props.controller.carteirinha;
        if (carteirinha && carteirinha.fileImage) {
            setUrl(fileService.createUrl(carteirinha.fileImage, 'carteirinha', carteirinha.dsExtensao));
        } else {
            setUrl('');
        }
    }, [props.controller.carteirinha]);

    return (
        <>
            <div className="checkin-card">
                <h3>Upload da carteirinha:</h3>

                <div className="configuracao-alterar-foto">
                    <ConditionalComponent
                        condition={!props.controller.carteirinha}
                        positive={
                            <div className="configuracao-alterar-foto-icon">
                                <label htmlFor="file">
                                    <i className="fas fa-camera camera-icon"></i>
                                </label>
                            </div>
                        }
                        negative={
                            <div className="configuracao-alterar-foto-img">
                                {
                                    url &&
                                    <img src={url} className="configuracao-foto"
                                         alt=""/>
                                }
                                <i className="fas fa-times-circle" onClick={props.controller.removeFile}
                                   style={{fontSize: '25px', color: '#d25f78', marginRight: '20px'}}/>
                            </div>
                        }
                    />
                    <div className="configuracao-foto-recomendacao">
                        {
                            !props.controller.carteirinha ?
                                <h2 className="configuracao-recomendacao-title">Selecionar imagem da carteirinha</h2>
                                :
                                <h2 className="configuracao-recomendacao-title">Imagem da carteirinha selecionada</h2>
                        }
                    </div>
                </div>
                <input type="file"
                       onChange={event => {
                           props.controller.getFile();
                       }}
                       name="file" id="file" style={{visibility: 'hidden'}}/>
            </div>
        </>
    );
}
