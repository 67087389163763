import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import AgendamentoService from "../../MeusAgendamentos/services/agendamento.service";
import AuthService from "../../../services/auth.service";
import {BlockuiService} from "../../../services/blockui.service";
import CheckinAgendadoInicio from "../components/CheckinAgendadoInicio";
import {ConditionalComponent} from "../../../../vendors/internalComponents/ConditionalComponent/ConditionalComponent";
import CheckinStepsController from "../controller/checkin-steps.controller";
import CheckinUnidade from "../components/CheckinUnidade";
import CheckinAtendimento from "../components/CheckinAtendimento";
import CheckinData from "../components/CheckinData";
import CheckinDocumento from "../components/CheckinDocumento";
import CheckinCarteirinha from "../components/CheckinCarteirinha";
import CheckinPedido from "../components/CheckinPedido";
import {Card} from "primereact/card";
import CheckinBullets from "../components/CheckinBullets";
import CheckInService from "../services/checkIn.service";
import '../style.scss'

export default function CheckinAgendado() {
    const history = useHistory();

    const agendamentoService = new AgendamentoService();
    const checkinService = new CheckInService();
    const user = AuthService.getUser();
    const [exame, setExame] = useState();
    const [iniciado, setIniciado] = useState(false);

    const controller = new CheckinStepsController(true, 1);


    useEffect(() => {
        agendamentoService.dispatch(BlockuiService.start);
        agendamentoService.consultaAgendamentoDisponivel(user.cdPessoaFisica).then(resp => {
            if (resp.data !== "Não há exames agendados para você!") {
                setExame(resp.data);
            }
            controller.init({
                cdEstabelecimento: resp.data.cdEstabelecimento,
                dtCheckin: resp.data.dtAgenda.replace(' ', 'T')
            })
            agendamentoService.dispatch(BlockuiService.stop);
        }, error => {
            agendamentoService.dispatch(BlockuiService.stop);
        });
    }, []);

    function iniciar() {
        checkinService.dispatch(BlockuiService.start);
        checkinService.consultaCheckin({
                "cdPessoaFisica": user?.cdPessoaFisica,
                "dtCheckin": exame.dtAgenda.replace(' ', 'T')
            }
        ).then(res => {
            checkinService.dispatch(BlockuiService.stop);
            if (!res.data.cdEstabelecimento) {
                setIniciado(true);
            } else {
                const dados = res.data;
                controller.setExame({cdEstabelecimento: res.data.cdEstabelecimento, dtCheckin: res.data.dtCheckin});
                if (dados.rg) {
                    controller.setCheckinDocumento(dados.rg);
                    controller.setDocumento(dados.rg.fileImage);
                    controller.setPossuiDocumento(true);
                    if (dados.ieParticular === 'S') {
                        controller.goTo(5, dados);
                    } else {
                        controller.setBulletActive([false, false, false, false, true]);
                        controller.goTo(4, dados);
                    }
                }
                if (dados.convenio) {
                    controller.setCheckinConvenio(dados.convenio);
                    controller.setCarteirinha(dados.convenio.fileImage);
                    controller.setPossuiCarteirinha(true);
                    controller.goTo(5, dados);
                }
                setIniciado(true);

            }
        }).catch(err => {
            checkinService.dispatch(BlockuiService.stop);
        });
    }


    const getTemplateByStep = () => {
        if (controller.step === 0) {
            return <CheckinUnidade controller={controller}/>
        }
        if (controller.step === 1) {
            return <CheckinData controller={controller}/>
        }
        if (controller.step === 2) {
            return <CheckinAtendimento controller={controller}/>
        }
        if (controller.step === 3) {
            return <CheckinDocumento controller={controller}/>
        }
        if (controller.step === 4) {
            return <CheckinCarteirinha controller={controller}/>
        }
        if (controller.step === 5) {
            return <CheckinPedido controller={controller}/>
        }
        return <></>;
    }


    return (
        <>

            <ConditionalComponent
                condition={iniciado}
                negative={
                    <CheckinAgendadoInicio exame={exame} iniciarCheckin={() => iniciar()}/>
                }
                positive={
                    <>
                        <Card id="agendamento-card">
                            {
                                getTemplateByStep()
                            }
                        </Card>
                        <CheckinBullets controller={controller}/>
                    </>
                }
            />
        </>
    );
}
