import React, {useState} from "react";
import {Button} from "primereact/button";
import {Link, useHistory} from "react-router-dom";
import {Password} from "primereact/password";
import Criterio from "./Criterio";
import {ToastService} from "../../services/utils/toast.service";
import {CadastroService} from "../../services/common/cadastro.service";
import {useDispatch} from "react-redux";
import {BlockuiService} from "../../services/blockui.service";
import MessageUtil from "../../shared/utils/MessageUtil";
import Environment from "../../../environments/environment";
import {UserService} from "../../services/user.service";
import AuthService from "../../services/auth.service";

export default function Senha(props) {
    const maiuscula = /.*[a-z].*/;
    const minuscula = /.*[A-Z].*/;
    const numero = /.*[0-9].*/;
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const cadastroService = new CadastroService();
    const authService = new AuthService();
    const dispatch = useDispatch();
    const history = useHistory();
    const cadastrar = () => {
        if (!criteriosValidos()) {
            ToastService.showError('A senha informada não atende aos requisitos mínimos de segurança');
            return;
        }
        if (password != confirm) {
            ToastService.showError('Senhas informadas não conferem');
            return;
        }
        const medico = props.medico;
        const nrDocumento = !!medico.nrCpf ? medico.nrCpf : medico.nrCartaoEstrangeiro;
        const obj = {username: nrDocumento, dsSenha: password, appCode: Environment.getAppCode()}
        dispatch(BlockuiService.start);
        debugger;
        if (!props.isEdit) {
            cadastroService.cadastrar(obj).then(res => {
                ToastService.showSuccess(MessageUtil.OPERACAO_REALIZADA_SUCESSO);
                authService.doLogin(nrDocumento, password);
            }, err => {
                dispatch(BlockuiService.stop);

            });
        } else {
            obj.username = medico.nrDocumento;
            cadastroService.atualizarSenha(obj).then(res => {
                ToastService.showSuccess(MessageUtil.OPERACAO_REALIZADA_SUCESSO);
                debugger;
                authService.doLogin(nrDocumento, password);
            }, err => {
                dispatch(BlockuiService.stop);

            });
        }


    };

    let criteriosValidos = () => {
        return password && test(minuscula) &&
            test(maiuscula) &&
            test(numero) &&
            password.length;
    }

    let test = (regex) => {
        if (!password) {
            return false;
        }
        return regex.test(password);
    }
    return (
        <>
            <div className="public-login-container">
                <div className="public-input-group">
                    <h6 className="public-informe">
                        Por favor, {props.isEdit ? 'recadastre' : 'cadastre'} sua senha
                    </h6>
                </div>
                <div className="public-input-group">
                    <label className="public-icon">
                        <i className="fas fa-lock "></i>
                    </label>
                    <Password placeholder="Senha"
                              value={password}
                              feedback={false}
                              onChange={(e) => setPassword(e.target.value)}
                              maxLength={15}
                              className="public-input"
                              toggleMask={true}/>
                </div>
                <div className="public-input-group">
                    <label className="public-icon">
                        <i className="fas fa-lock "></i>
                    </label>
                    <Password placeholder="Confirmar Senha"
                              promptLabel="Confirme a senha desejada"
                              value={confirm}
                              onChange={(e) => setConfirm(e.target.value)}
                              maxLength={15}
                              feedback={false}
                              className="public-input"
                              toggleMask={true}/>
                </div>

                <div className="public-button-group public-criterio-senha" style={{marginTop: '30px'}}>
                    <label style={{color: '#005DA4'}}>

                        Critérios de segurança
                    </label>
                    <label>
                        <Criterio condition={test(minuscula)}/>
                        Deve possuir letras maiúsculas
                    </label>
                    <label>
                        <Criterio condition={test(maiuscula)}/>
                        Deve possuir letras minúsculas
                    </label>
                    <label>
                        <Criterio condition={test(numero)}/>
                        Deve possuir números
                    </label>
                    <label>
                        <Criterio condition={password && password.length >= 8}/>
                        Limite de 8 a 15 caracteres
                    </label>
                </div>
                <div className="public-button-group" style={{marginTop: '20px'}}>
                    <Button
                        onClick={(e) => cadastrar('sms')}
                        type="text"
                        className="public-button public-blue-button">{props.isEdit ? 'Recuperar' : 'Cadastrar'}</Button>
                </div>
                <div className="public-button-group" style={{marginTop: '30px',}}>
                    <Link to="/public/login">
                        <Button className="public-button public-white-button"
                                onClick={() => props.voltar(props.backId)}>Voltar</Button>
                    </Link>
                </div>
            </div>

        </>
    );
}
