import React from 'react'
import {Button} from "primereact/button";

export default function Abstract({content, length}) {
    if (!content) {
        return <></>;
    }
    if (content.length <= length) {
        return <>{content}</>;
    }
    return (
        <>
            {content.substring(0, length)}...
            <Button className="btn-blue-icon"
                    style={{padding: '0', margin: '0 10px'}}
                    tooltipOptions={{className: 'abstract-tooltip', position: 'top'}}
                    tooltip={content}>
                <i className="fas fa-info-circle"/>
            </Button>
        </>
    );
}
