import React from "react";
import {Route, Switch} from "react-router-dom";
import PublicLayout from "./PublicLayout/PublicLayout";
import Logout from "./Logout/Logout";
import Login from "./Login/Login";
import Cadastro from "./Cadastro/Cadastro";
import FaleConosco from "./FaleConosco/FaleConosco";
import Recover from "./Recover/Recover";

export default function PublicPage() {
    return (
        <>

            <PublicLayout>

                <Switch>
                    <Route exact path="/public/login" component={Login}/>
                    <Route exact path="/public/logout" component={Logout}/>
                    <Route exact path="/public/cadastro" component={Cadastro}/>
                    <Route exact path="/public/recover" component={Recover}/>
                    <Route exact path="/public/fale-conosco" component={FaleConosco}/>

                </Switch>
            </PublicLayout>
        </>
    );
}
