import AbstractController from "../../../shared/abstracts/abstract.controller";
import {useState} from "react";
import {BlockuiService} from "../../../services/blockui.service";
import {ToastService} from "../../../services/utils/toast.service";
import moment from "moment";
import CheckInService from "../services/checkIn.service";
import AuthService from "../../../services/auth.service";

export default class CheckinProntoSocorroController extends AbstractController {
    static bulletPs = [true, false, false, false, false, false];

    constructor() {
        super();
        [this.step, this.setStep] = useState(0);
        [this.dtCheckin, this.setDtCheckin] = useState(new Date());
        [this.bulletActive, this.setBulletActive] = useState(CheckinProntoSocorroController.bulletPs);
        [this.dsHorario, this.setDsHorario] = useState("");
        [this.ieTipoAtendimento, this.setIeTipoAtendimento] = useState("");
        [this.tipoAtendimento, this.setTipoAtendimento] = useState("");
        [this.checkin, this.setCheckin] = useState('');

        [this.documento, this.setDocumento] = useState(null);
        [this.carteirinha, this.setCarteirinha] = useState(null);

        this.checkinService = new CheckInService();
    }

    init() {
        super.init();
    }

    nextStep() {
        const i = this.step;
        switch (i) {
            case 0:
                this.next();
                break;
            case 1:
                if (!this.dsHorario) {
                    ToastService.showError("Selecione a previsão de chegada.");
                    return;
                }
                this.next();
                break;
            case 2:
                debugger;
                if (!this.ieTipoAtendimento) {
                    ToastService.showError("Selecione o tipo de atendimento.");
                    return;
                }
                this.criarCheckin(this.tipoAtendimento);
                break;
            case 3:
                if (!this.carteirinha) {
                    ToastService.showError(
                        "É preciso fazer upload de uma foto de uma carteirinha para continuar."
                    );
                    return;
                }
                this.handleDocumentUpload(5, this.carteirinha);
                break;
            case 5:
                if (!this.documento) {
                    ToastService.showError(
                        "É preciso fazer upload de uma foto de um documento."
                    );
                    return;
                }
                this.handleDocumentUpload(1, this.documento);
                break;
        }
    }

    handleDocumentUpload(ieTipoDocumento, documento) {
        debugger;
        this.start();
        this.checkinService
            .documentos({
                nrSeqCheckin: this.checkin.nrSequencia,
                fileImage: documento.fileImage,
                dsExtensao: documento.dsExtensao,
                ieTipoDocumento: ieTipoDocumento
            })
            .then(() => {
                this.stop();
                if (this.step === 5) {
                    this.finalizaCheckin();
                } else {
                    this.goTo(5);
                }
            })
            .catch(error => {
                this.stop();
                ToastService.showError("Não foi possível enviar a imagem.");
            });
    }

    finalizar() {
        if (!this.documento) {
            ToastService.showError(
                "É preciso fazer upload de uma foto de um documento."
            );
            return;
        }
        this.handleDocumentUpload(1, this.documento);
    }

    finalizaCheckin() {
        this.start();
        this.checkinService
            .confirmaCheckin({
                cdPessoaFisica: AuthService.getUser().cdPessoaFisica,
                dtCheckin: moment(this.dtCheckin).format("YYYY-MM-DD[T]HH:mm"),
                cdEstabelecimento: 1,
                ieParticular: this.tipoAtendimento === "P" ? "S" : "N",
                dsHorario: this.dsHorario,
                ieTipoCheckin: 3,
                ieTipoAtendimento: this.ieTipoAtendimento
            })
            .then(
                () => {
                    this.stop();
                    ToastService.showSuccess("Check-in realizado com sucesso.");
                    this.history.push("/checkin/finalizado");
                },
                error => {
                    this.stop();
                    ToastService.showError("Não foi possível finalizar o Check-in.");
                }
            );
    }

    criarCheckin(tipoAtendimento) {
        this.start();
        this.checkinService.create({
            cdPaciente: AuthService.getUser().cdPessoaFisica,
            cdEstabelecimento: 1,
            ieParticular: tipoAtendimento === "P" ? "S" : "N",
            dsHorario: this.dsHorario,
            ieTipoCheckin: 3,
            ieTipoAtendimento: this.ieTipoAtendimento,
        }).then(res => {
            this.stop();
            this.setCheckin(res.data);
            this.next();
        }).catch(err => this.stop());
    }

    selectIeTipoAtendimento(ieTipoAtendimento) {
        this.setIeTipoAtendimento(ieTipoAtendimento);
        this.next();
    }

    selectTipoAtendimento(tipoAtendimento) {
        this.setTipoAtendimento(tipoAtendimento);
        this.criarCheckin(tipoAtendimento);
    }

    selectDsHorario(dsHorario) {
        this.setDsHorario(dsHorario);
        this.next();
    }

    goTo(index) {
        const newBullet = [...CheckinProntoSocorroController.bulletPs];
        newBullet[0] = false;
        newBullet[index] = true;
        this.setBulletActive(newBullet);
        this.setStep(index);
    }

    next() {
        const i = this.step;
        const nextIndex = i + 1;
        const newBullets = [...this.bulletActive];
        newBullets[i] = !newBullets[i];
        newBullets[nextIndex] = !newBullets[nextIndex];
        this.setBulletActive(newBullets);
        this.setStep(nextIndex);
    }

    previousStep() {
        const i = this.step;
        let previusIndex = i - 1;
        if (this.step === 5 && this.tipoAtendimento === "P") {
            previusIndex = previusIndex - 1;
        }
        const newBullets = [...this.bulletActive];
        newBullets[i] = !newBullets[i];
        newBullets[previusIndex] = !newBullets[previusIndex];
        this.setBulletActive(newBullets);
        this.setStep(previusIndex);
    }

    removeFile() {
        const that = this;
        switch (that.step) {
            case 3:
                that.setCarteirinha(null);
                break;
            case 5:
                that.setDocumento(null);
                break;
            default:
        }
    }

    getFile() {
        const dom = document.getElementById("file").files;
        if (dom && dom.length <= 0) {
            return;
        }
        var file = dom[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        const that = this;
        reader.onload = function () {
            let result = reader.result;
            if (result.indexOf(',') >= 0) {
                result = result.split(',')[1];
            }
            debugger;
            switch (that.step) {
                case 3:
                    that.setCarteirinha({
                        fileImage: result,
                        dsExtensao: file.type
                    });
                    break;
                case 5:
                    that.setDocumento({
                        fileImage: result,
                        dsExtensao: file.type
                    });
                    break;

                default:
            }
        };
        reader.onerror = function (error) {
            return error(true);
        };
    }
}
