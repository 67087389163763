import React, {useEffect, useMemo, useState} from "react";
import objectPath from "object-path";
// LayoutContext
import {useHtmlClassService} from "../_core/MetronicLayout";
// Import Layout components
import {Header} from "./header/Header";
import {HeaderMobile} from "./header-mobile/HeaderMobile";
import {Aside} from "./aside/Aside";
import {LayoutInit} from "./LayoutInit";
import {SubHeader} from "./subheader/SubHeader";
import {QuickPanel} from "./extras/offcanvas/QuickPanel";
import {QuickUser} from "./extras/offcanvas/QuickUser";
import {ScrollTop} from "./extras/ScrollTop";
import {useLocation} from "react-router-dom";
import {EmptyComponent} from "../../../internalComponents/EmptyComponent/EmptyComponent";
import {toAbsoluteUrl} from "../../_helpers";
import {Button} from "primereact/button";
import CheckInService from "../../../../app/modules/Checkin/services/checkIn.service";
import Container from "../../../../app/components/Container/Container";
import Field from "../../../../app/components/Field/Field";
import DialogHome from "./DialogHome";

export function Layout({children}) {
    const uiService = useHtmlClassService();
    const [isHome, setIsHome] = useState(false);
    const [isPsAtivo, setIsPsAtivo] = useState(false);
    const checkinService = new CheckInService();
    const [showDialogHome, setShowDialogHome] = useState(true);
    const closeDialogHome = () => {
        setShowDialogHome(false);
    };
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, "self.layout"),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
            desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true),
            contentExtended: objectPath.get(uiService.config, "content.extended")
        };
    }, [uiService]);

    let location = useLocation();

    useEffect(() => {
        setIsHome(window.location.href.indexOf("home") >= 0);
        if (window.location.href.indexOf("home") >= 0) {
            // checkinService.start();
            // checkinService
            //   .verificaCheckinPsAtivo()
            //   .then(res => {
            //     if (res.data === true) {
            //       setIsPsAtivo(true);
            //     } else {
            //       setIsPsAtivo(false);
            //     }
            //     checkinService.stop();
            //   })
            //   .catch(err => {
            //     checkinService.stop();
            //   });
        }
    }, [location]);

    const homeLayout = (
        <>
            <div className="home-banner">
                <h1>
                    Por aqui, você pode contar com recursos que facilitam o seu
                    atendimento:
                </h1>
                <img
                    src={toAbsoluteUrl("/media/hcor/person.png")}
                    className="home-pessoa"
                />
            </div>
            <div className="home-wrapper">
                <div className="home-subwrapper">

                    <div className="home-info">
                        <div>
                            <h3>Sinta o nosso cuidado onde estiver.</h3>
                            <h4>
                                Aproveite e baixe o nosso app para ter os serviços direto no seu
                                celular.
                            </h4>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                            <img
                                src={toAbsoluteUrl("/media/hcor/person.png")}
                                className="home-pessoa-2"
                            />
                        </div>

                        <div className="home-lojas">


                            <div className="home-stores">


                                <div className="home-imagens">

                                    <img
                                        src={toAbsoluteUrl("/media/hcor/phone.png")}
                                        className="home-celular"
                                    />
                                    <img
                                        src={toAbsoluteUrl("/media/hcor/grafismo_rosa.png")}
                                        className="hide-mobile home-grafismo"
                                    />
                                </div>

                                {/*<div className="home-hcor-fluery"*/}
                                {/*     onClick={() => window.open('https://www.hcor.com.br/faq-hcor-e-fleury/?_thumbnail_id=80017', '_blank')}>*/}


                                {/*</div>*/}
                                <div className="home-imagens">
                                    <a
                                        href="https://apps.apple.com/br/app/hcor/id1586998626"
                                        target="_blank"
                                    >
                                        <img
                                            src={toAbsoluteUrl("/media/hcor/apple_store.png")}
                                            style={{width: "120px", marginLeft: "-15px"}}
                                        />
                                    </a>
                                    <a
                                        href="https://apps.apple.com/br/app/hcor/id1586998626"
                                        target="_blank"
                                    >
                                        <img
                                            src={toAbsoluteUrl("/media/hcor/apple_badge.png")}
                                            style={{width: "100px"}}
                                        />
                                    </a>
                                </div>
                                <div className="home-imagens">
                                    <a
                                        href="https://play.google.com/store/apps/details?id=br.com.hcor"
                                        target="_blank"
                                    >
                                        <img
                                            src={toAbsoluteUrl("/media/hcor/play_store.png")}
                                            style={{width: "120px", marginLeft: "-15px"}}
                                        />
                                    </a>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=br.com.hcor"
                                        target="_blank"
                                    >
                                        <img
                                            src={toAbsoluteUrl("/media/hcor/google_badge.png")}
                                            style={{width: "100px"}}
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="home-follow">
                                <div>
                                    <h5>Siga o Hcor</h5>
                                </div>
                                <div>
                                    <a
                                        href="https://www.facebook.com/hcor.oficial/"
                                        target="_blank"
                                    >
                                        <img src={toAbsoluteUrl("/media/hcor/facebook.svg")}/>
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/hcor/"
                                        target="_blank"
                                    >
                                        <img src={toAbsoluteUrl("/media/hcor/linkedin.svg")}/>
                                    </a>
                                    <a
                                        href="https://www.instagram.com/hcoroficial/"
                                        target="_blank"
                                    >
                                        <img src={toAbsoluteUrl("/media/hcor/instagram.svg")}/>
                                    </a>
                                    <a
                                        href="https://www.youtube.com/channel/UC7tOeqLpmCejyymIyZlrWNQ"
                                        target="_blank"
                                    >
                                        <img src={toAbsoluteUrl("/media/hcor/youtube.svg")}/>
                                    </a>
                                </div>
                            </div>
                            {/*<div className="fleury-mobile"*/}
                            {/*     onClick={() => window.open('https://www.hcor.com.br/faq-hcor-e-fleury/?_thumbnail_id=80017', '_blank')}>*/}
                            {/*    <div>*/}
                            {/*        Desde 24/07/2023, o processamento dos exames laboratoriais do Hcor*/}
                            {/*        tem a responsabilidade técnica do Fleury. Para saber mais, clique aqui.*/}
                            {/*    </div>*/}
                            {/*    <img*/}
                            {/*        src={toAbsoluteUrl("/media/hcor/HcorFleury.png")}*/}
                            {/*        className="fleury-mobile-image"*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <DialogHome
                        visible={showDialogHome}
                        onHide={closeDialogHome}
                    />

                </div>
            </div>
            {/*<div className="fleury-home"*/}
            {/*     onClick={() => window.open('https://www.hcor.com.br/faq-hcor-e-fleury/?_thumbnail_id=80017', '_blank')}>*/}
            {/*    <div>*/}
            {/*        Desde 24/07/2023, o processamento dos exames laboratoriais do Hcor*/}
            {/*        tem a responsabilidade técnica do Fleury. Para saber mais, clique aqui.*/}
            {/*    </div>*/}
            {/*    <img*/}
            {/*        src={toAbsoluteUrl("/media/hcor/HcorFleury.png")}*/}
            {/*        className="fleury-image"*/}
            {/*    />*/}
            {/*</div>*/}
        </>
    );

    return layoutProps.selfLayout !== "blank" ? (
        <>
            {/*begin::Main*/}
            <HeaderMobile/>
            {/* <div className="d-flex flex-column flex-root"> */}
            {/*begin::Page*/}
            <div className="d-flex flex-row flex-column-fluid page">
                {layoutProps.asideDisplay && <Aside/>}
                {/*begin::Wrapper*/}
                <div
                    className="d-flex flex-column flex-row-fluid wrapper"
                    id="kt_wrapper"
                >
                    <Header/>
                    {/*begin::Content*/}
                    <div
                        id="kt_content"
                        className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                    >

                        {layoutProps.subheaderDisplay && <SubHeader/>}
                        {/*begin::Entry #554B91*/}
                        {!layoutProps.contentExtended && (
                            <>
                                <EmptyComponent condition={isHome} body={homeLayout}/>

                                <div className="d-flex flex-column-fluid">
                                    <div className={layoutProps.contentContainerClasses} >
                                        {children}
                                    </div>
                                </div>
                            </>
                        )}

                        {layoutProps.contentExtended && {children}}
                        {/*end::Entry*/}
                    </div>
                    {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
            </div>
            {/*end::Page*/}
            {/* </div> */}
            <QuickUser/>
            <QuickPanel/>
            {/*end::Main*/}
            <LayoutInit/>
            {/*<img className="whats-icon hide-mobile"*/}
            {/*     onClick={() => window.open('https://web.whatsapp.com/send?phone=551138893939', '_blank')}*/}
            {/*     src={toAbsoluteUrl('/media/hcor/whatsapp.svg')} />*/}

            {/*<img className="whats-icon hide-desktop"*/}
            {/*     onClick={() => window.open('whatsapp://send?phone=551138893939', '_blank')}*/}
            {/*     src={toAbsoluteUrl('/media/hcor/whatsapp.svg')} />*/}
        </>
    ) : (
        // BLANK LAYOUT
        <div className="d-flex flex-column flex-root">{children}</div>
    );
}
