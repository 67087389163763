/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import SVG from "react-inlinesvg";
import {checkIsActive, toAbsoluteUrl} from "../../../../_helpers";
import {useSelector} from "react-redux";
import {If} from "../../../../../internalComponents/If/If";

export function AsideMenuList({layoutProps}) {
    const location = useLocation();
    const state = useSelector(state => state);

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };


    return (
        <>
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <li
                    className={`menu-item ${getMenuItemActive("/home", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/home">
                        <span className="svg-icon menu-icon">
                          <i
                              className={`fas fa-home ${getMenuItemActive("/home", false)}`}
                          />
                        </span>
                        <span className="menu-text">Página inicial</span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/meus-agendamentos",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/meus-agendamentos">
                        <span className="svg-icon menu-icon">
                          <i
                              className={`fas fa-calendar-check ${getMenuItemActive(
                                  "/meus-agendamentos",
                                  false
                              )}`}
                          />
                        </span>
                        <span className="menu-text">Meus agendamentos</span>
                    </NavLink>
                </li>
                {/*<If condition={state.user.ieFuncionario === 'S'}>*/}

                {/*    <li*/}
                {/*        className={`menu-item ${getMenuItemActive("/checkin", false)}`}*/}
                {/*        aria-haspopup="true"*/}
                {/*    >*/}
                {/*        <NavLink className="menu-link" to="/checkin/choice">*/}
                {/*        <span className="svg-icon menu-icon">*/}
                {/*          <i*/}
                {/*              className={`fas fa-map-marker-alt ${getMenuItemActive(*/}
                {/*                  "/checkin",*/}
                {/*                  false*/}
                {/*              )}`}*/}
                {/*          />*/}
                {/*        </span>*/}
                {/*            <span className="menu-text">Check-in</span>*/}
                {/*        </NavLink>*/}
                {/*    </li>*/}
                {/*</If>*/}

                <li
                    className={`menu-item ${getMenuItemActive(
                        "/resultado-exame",
                        false
                    )}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/resultado-exame">
                        <span className="svg-icon menu-icon">
                          <i
                              className={`fas fa-clipboard-list ${getMenuItemActive(
                                  "/resultado-exame",
                                  false
                              )}`}
                          />
                        </span>
                        <span className="menu-text">Resultado de exames</span>
                    </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/extrato", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/extrato">
                        <span className="svg-icon menu-icon">
                          <i
                              className={`fas fa-dollar-sign ${getMenuItemActive(
                                  "/extrato",
                                  false
                              )}`}
                          />
                        </span>
                        <span className="menu-text">Extrato da conta particular</span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item`}
                    aria-haspopup="true"
                >
                    <a
                        className="menu-link"
                        href="https://doe.hcor.com.br/"
                        target="_blank"
                    >
                        <span className="svg-icon menu-icon">
                          <i
                              className="fas fa-hand-holding-heart"
                          />
                        </span>
                        <span className="menu-text">Doe aqui</span>
                    </a>
                </li>
            </ul>
            {/* end::Menu Nav */}
        </>
    );
}
