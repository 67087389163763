import {AbstractService} from "../../../shared/abstracts/abstract.service";
import {agendamentoApi, questionarioApi} from "../../../services/api";

export default class QuestionarioService extends AbstractService {


    constructor() {
        super("", "", questionarioApi);
    }

    obterQuestionario()  {
        return this.api.get(`${this.rota}/dominio?sgSigla=Q1`);
    };

}
