import {useFormik} from "formik";
import DataUtil from "../utils/DataUtil";
import AbstractController from "./abstract.controller";

export default class AbstractFormController extends AbstractController {
    constructor() {
        super();
        this.formik = undefined;
    }

    initFormik(initialValues, validate, onSubmit) {
        return useFormik({
            initialValues: initialValues,
            validate: validate,
            onSubmit: onSubmit
        });
    }

    requiredFieldValidation(
        data,
        errors,
        fieldName,
        message = "Campo obrigatório"
    ) {
        if (!data[fieldName]) {
            errors[fieldName] = message;
        }
    }

    requiredFilledListValidation(
        data,
        errors,
        fieldName,
        message = "Campo obrigatório"
    ) {
        if (!data[fieldName] || data[fieldName].length === 0) {
            errors[fieldName] = message;
        }
    }

    dateCompareValidation(
        data,
        errors,
        fromName,
        toName,
        messageTo = "Campo inválido",
        messageFrom = "Campo inválido"
    ) {
        if (!data[fromName] || !data[toName]) {
            return;
        }
        if (DataUtil.isAfter(data[fromName], data[toName])) {
            errors[fromName] = messageTo;
            errors[toName] = messageFrom;
        }
    }

    isFormFieldValid(name) {
        return this.isFormFieldValidOtherForm(name, this.formik);
    }

    isFormFieldValidOtherForm(name, formik) {
        if (formik.errors[name] === undefined) {
            return true;
        }
        return !formik.errors[name];
    }


    isFormListValid(name) {
        return this.isFormFieldValid(name) || this.formik.values[name].length !== 0;
    }

    isListFieldValid(list, touched) {
        if (!touched) {
            return true;
        }
        return !(!list || list.length === 0);
    }

    getFormFieldError(fieldName) {
        return this.formik.errors[fieldName];
    }
}
