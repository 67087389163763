import React from "react";

export default function Estabelecimento(props) {


    return (
        <>
            <h3 className="titulo-unidades">Escolha a unidade:</h3>
            <div className="agendamento-unidade-wrapper">
                <div className="agendamento-unidade-item">
                    <h4 className="titulo-unidades">Unidade Cidade Jardim</h4>
                    <div className="unidades-img jardim" id="passar_mouse"
                         onClick={() => props.controller.selectUnidade(11)}>
                        <div id="mostrar" className="unidades-img jardim2">
                        </div>
                    </div>
                    <div className="agendamento-unidade-endereco">
                        <div>
                            <i className="fas fa-info-circle"
                               style={{fontSize: 25, marginRight: '15px', color: '#69C3D7'}}/>
                        </div>
                        <div>
                            <h4>
                                Av. Cidade Jardim, 350 - 2º andar - Edifício Dacon - Jardim Paulistano - São Paulo -
                                SP<br/>
                            </h4>
                            <h5>
                                Segunda à sexta-feira - 07:00 às 21:00<br/>
                                Sábado - 07:00 às 18:00<br/>
                                Feriados - 08:00 às 14:00
                            </h5>

                        </div>
                    </div>
                </div>

                <div className="agendamento-unidade-item">
                    <h4 className="titulo-unidades">Unidades Paraíso</h4>
                    <div className="unidades-img paraiso" id="passar_mouse"
                         onClick={() => props.controller.selectUnidade(1)}>
                        <div id="mostrar" className="unidades-img paraiso2">
                        </div>
                    </div>
                    <div className="agendamento-unidade-endereco">
                        <div>
                            <i className="fas fa-info-circle"
                               style={{fontSize: 25, marginRight: '15px', color: '#69C3D7'}}/>
                        </div>
                        <div>
                            <h4>
                                Rua Desembargador Eliseu Guilherme, 130 - Paraíso - São Paulo - SP <br/>
                            </h4>
                            <h5>
                                Segunda à sexta-feira - 07:00 às 21:00<br/>
                                Sábado - 07:00 às 18:00<br/>
                                Feriados - 08:00 às 14:00
                            </h5>
                        </div>
                    </div>
                    <div className="agendamento-unidade-endereco">
                        <div>
                            <i className="fas fa-info-circle"
                               style={{fontSize: 25, marginRight: '15px', color: '#69C3D7'}}/>
                        </div>
                        <div>
                            <h4 style={{lineHeight: '35px'}}>
                                Rua Abílio Soares, 250 - Paraíso - São Paulo - SP<br/>
                                Rua Abão Dib, 22 - Paraíso - São Paulo - SP<br/>
                                Avenida Bernardino de Campos, 186 - Paraíso - São Paulo - SP<br/>
                            </h4>
                            <h5>Segunda à sexta-feira - 08:00 às 20:00 (exceto feriados)</h5>
                        </div>

                    </div>
                </div>
            </div>
            {/*<div className="checkin-card">*/}
            {/*    <div className="unidades-nomes">*/}


            {/*        <hr/>*/}

            {/*<div onClick={() => props.controller.selectUnidade(1)}>*/}
            {/*    <h4>Unidades Paraíso</h4>*/}
            {/*    <div className="unidades-img paraiso" id="passar_mouse">*/}
            {/*        <div id="mostrar" className="unidades-img paraiso2">*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*            <div className="info-unidades">*/}


            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </>

    );
}
