import React from 'react';

export function EmptyComponent(props) {
    if (props.condition) {
        return (props.body);
    }
    return (
        <></>
    );
}
