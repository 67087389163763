import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";
import {toAbsoluteUrl} from "../../../_helpers";
import {Link} from "react-router-dom";

export function QuickUserToggler() {
    const user = {
        id: 1,
        username: "admin",
        password: "demo",
        email: "admin@demo.com",
        accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
        refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
        roles: [1], // Administrator
        pic: toAbsoluteUrl("/media/users/user-not-found.jpg"),
        fullname: "Rafael do Nascimento Pollis",
        occupation: "CEO",
        companyName: "Keenthemes",
        phone: "456669067890",
        address: {
            addressLine: "L-12-20 Vertex, Cybersquare",
            city: "San Francisco",
            state: "California",
            postCode: "45000"
        },
        socialNetworks: {
            linkedIn: "https://linkedin.com/admin",
            facebook: "https://facebook.com/admin",
            twitter: "https://twitter.com/admin",
            instagram: "https://instagram.com/admin"
        }
    };
    const state = useSelector(state => state);
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            offcanvas:
                objectPath.get(uiService.config, "extras.user.layout") === "offcanvas"
        };
    }, [uiService]);

    return (
        <>
            <div
                className="topbar-item tst"
                style={{
                    marginTop: "-26px",
                    marginRight: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                <>
                    <div>
            <span
                className="font-weight-bold font-size-base d-none d-md-inline mr-1"
                style={{color: "#FFFFFF"}}
            >
              Olá,
            </span>
                        <span
                            className="font-weight-bolder font-size-base d-none d-md-inline mr-3"
                            style={{color: "#69C3D7"}}
                        >
              {state.user.username}
            </span>
                    </div>
                    <span>
            <Link
                to="/menu-configuracao"
                style={{
                    marginRight: "20px",
                    color: "white",
                    fontWeight: "1000",
                    fontSize: "1.2em"
                }}
            >
              Meu perfil
                {state.user.foto ? (
                    <img className="foto-perfil" src={state.user.foto}/>
                ) : (
                    <>
                        <i
                            className="fas fa-user"
                            style={{margin: "0 20px", color: "#69C3D7"}}
                        ></i>
                    </>
                )}
            </Link>
          </span>
                </>
            </div>

            {!layoutProps.offcanvas && <UserProfileDropdown/>}
        </>
    );
}
