import {useState} from "react";
import {ToastService} from "../../../services/utils/toast.service";
import AgendamentoService from "../services/agendamento.service";
import AbstractFormController from "../../../shared/abstracts/abstract-form.controller";
import AuthService from "../../../services/auth.service";
import moment from "moment";
import DataUtil from "../../../shared/utils/DataUtil";
import AgendamentoConstants from "../utils/agendamento.constants";

export default class GerarAgendamentoController extends AbstractFormController {
    static bullet = [true, false, false, false, false, false, false, false, false];

    constructor() {
        super();
        this.data = new Date();
        this.data.setDate(this.data.getDate() + 1);
        this.agendamentoService = new AgendamentoService();
        [this.step, this.setStep] = useState(0);
        [this.bulletActive, this.setBulletActive] = useState(
            GerarAgendamentoController.bullet
        );
        [this.especialidadeList, this.setEspecialidadeList] = useState([]);
        [this.medicoList, this.setMedicoList] = useState([]);
        [this.horarioList, this.setHorarioList] = useState([]);
        [this.convenioList, this.setConvenioList] = useState([]);
        [this.categoriaList, this.setCategoriaList] = useState([]);
        [this.planoList, this.setPlanoList] = useState([]);

        [this.tipoAgendamento, this.setTipoAgendamento] = useState("");
        [this.cdMedico, this.setCdMedico] = useState("");
        [this.cdEspecialidade, this.setCdEspecialidade] = useState("");
        [this.especialidade, this.setEspecialidade] = useState("");
        [this.dtAgendamento, this.setDtAgendamento] = useState(null);
        [this.ieParticular, this.setIeParticular] = useState('');
        [this.cdConvenio, this.setCdConvenio] = useState('');
        [this.cdPlano, this.setCdPlano] = useState('');
        [this.cdCategoria, this.setCdCategoria] = useState('');
        [this.dtValidade, this.setDtValidade] = useState('');
        [this.nrCarteirinha, this.setNrCarteirinha] = useState('');
        [this.horario, this.setHorario] = useState({});
        [this.carteirinha, this.setCarteirinha] = useState({});
        [this.agendamentoGerado, this.setAgendamentoGerado] = useState({});
        [this.unidade, this.setUnidade] = useState('');
        [this.ieClassifAgenda, this.setIeClassifAgenda] = useState('N');
        [this.valor, this.setValor] = useState(-1);
        [this.showPrimeiraConsulta, this.setShowPrimeiraConsulta] = useState(true);
        [this.diasAgendaList, this.setDiasAgendaList] = useState([]);
        [this.dsEspecialidade, this.setDsEspecialidade] = useState('');
        [this.cdAgenda, this.setCdAgenda] = useState('');
        [this.erroConsistencia, this.setErroConsistencia] = useState(false);

        [this.documento, this.setDocumento] = useState({});
        const that = this;
        this.formik = this.initFormik(
            {
                cdEspecialidade: "",
                cdMedico: ""
            },
            data => {
                return this.validarForm(data);
            },
            data => {
                const item = this.especialidadeList.filter(f => f.cdEspecialidade === that.cdEspecialidade);
                this.setCdMedico(data.cdMedico);
                this.setEspecialidade(item[0]);
                data.cdEspecialidade = that.cdEspecialidade;
                this.gerarAgendamento(data);
            }
        );


        this.convenioForm = this.initFormik(
            {
                cdConvenio: '',
                cdCategoria: '',
                cdPlano: '',
                nrCarteirinha: '',
                dtValidade: ''
            },
            data => {
                return this.validarConvenioForm(data);
            },
            data => {
                this.setCdConvenio(data.cdConvenio);
                this.setCdPlano(data.cdPlano);
                this.setCdCategoria(data.cdCategoria);
                this.setNrCarteirinha(data.nrCarteirinha);
                this.setDtValidade(data.dtValidade);
                this.next();
            }
        );
    }


    init() {
        super.init();
        this.start();
        this.agendamentoService
            .obterEspecialidade(AuthService.getUser().cdPessoaFisica)
            .then(res => {
                this.stop();
                this.setEspecialidadeList(res.data);
            })
            .catch(this.defaultErrorCatch);


        this.agendamentoService
            .obterConvenio()
            .then(res => {
                this.stop();
                this.setConvenioList([{cdConvenio: undefined, dsConvenio: 'Selecione'}, ...res.data]);
            })
            .catch(this.defaultErrorCatch);


    }


    validarPrimeiraConsulta(cdAgenda) {
        const filter = {cdAgenda: cdAgenda, cdPaciente: AuthService.getUser().cdPessoaFisica};
        this.start();
        this.agendamentoService.validarPrimeiraConsulta(filter).then(res => {
            const data = res.data.total <= 0;
            if (data) {
                this.setIeClassifAgenda('N');
            } else {
                this.setIeClassifAgenda(null);
            }
            this.stop();
            this.setShowPrimeiraConsulta(data);
        })
    }

    obterValor() {
        this.start();
        this.agendamentoService.obterValor(this.horario.nrSeqAgeint).then(res => {
            this.stop();
            this.setValor(res.data);
        }).catch(this.defaultErrorCatch);
    }

    obterDadosConvenioPessoa() {
        if (this.cdConvenio) {
            this.convenioForm.setFieldValue('cdConvenio', this.cdConvenio);
            this.convenioForm.setFieldValue('cdCategoria', this.cdCategoria);
            this.convenioForm.setFieldValue('cdPlano', this.cdPlano);
            this.convenioForm.setFieldValue('nrCarteirinha', this.nrCarteirinha);
            this.convenioForm.setFieldValue('dtValidade', this.dtValidade);
            this.convenioForm.setFieldValue('nrCarteirinha', this.nrCarteirinha);
            return;
        }
        this.start();
        const user = AuthService.getUser();
        this.agendamentoService.obterConvenioPessoa(user.cdPessoaFisica).then(res => {
            this.stop();
            if (!res.data) {
                return;
            }
            const data = res.data;
            const that = this;
            this.setCategoriaList(data.categoriaList);
            this.setPlanoList(data.planoList);
            setTimeout(() => {
                that.convenioForm.setFieldValue('cdConvenio', data.cdConvenio);
                that.convenioForm.setFieldValue('cdCategoria', data.cdCategoria);
                that.convenioForm.setFieldValue('cdPlano', data.cdPlanoConvenio);
                that.convenioForm.setFieldValue('nrCarteirinha', data.nrCarteirinha);
                if (data.dtValidadeCarteira) {
                    that.convenioForm.setFieldValue('dtValidade', DataUtil.desformataData(data.dtValidadeCarteira));
                }
                that.convenioForm.setFieldValue('nrCarteirinha', data.cdUsuarioConvenio);
            }, 500);


        }).catch(err => {
            this.stop();

        });
    }

    handleDocumentUpload(documento) {
        this.start();
        this.agendamentoService
            .enviarDocumento(documento)
            .then(() => {
                this.stop();
                this.next();
            })
            .catch(error => {
                this.stop();
                ToastService.showError("Não foi possível enviar a imagem.");
            });
    }

    getFile() {
        const dom = document.getElementById("file").files;
        const user = AuthService.getUser();
        if (dom && dom.length <= 0) {
            return;
        }
        var file = dom[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        const that = this;
        reader.onload = function () {
            const doc = {
                nome: file.name,
                type: file.type,
                nomePaciente: user.nmPessoaFisica,
                nrSeqAutorizacao: null,
                arquivo: reader.result,
                cdPessoaFisica: user.cdPessoaFisica,
                ieGerarAgendamento: true,
                nrSeqAgeint: that.horario.nrSeqAgeint
            };
            switch (that.step) {
                case AgendamentoConstants.CARTEIRINHA:
                    doc.tipoDocumento = 2;
                    that.setCarteirinha(doc);
                    break;
                case AgendamentoConstants.DOCUMENTO:
                    doc.tipoDocumento = 1;
                    that.setDocumento(doc);
                    break;

                default:
            }
        };
        reader.onerror = function (error) {
            return error(true);
        };
    }

    goTo(index) {
        const newBullet = [...GerarAgendamentoController.bullet];
        newBullet[0] = false;
        newBullet[index] = true;
        this.setBulletActive(newBullet);
        this.setStep(index);
    }

    removeFile() {
        const that = this;
        switch (that.step) {
            case AgendamentoConstants.CARTEIRINHA:
                that.setCarteirinha({});
                break;
            case AgendamentoConstants.DOCUMENTO:
                that.setDocumento({});
                break;
            default:
        }
    }

    selectHorario(item) {
        this.setHorario(item);
    }


    changeEspecialidade(cdEspecialidade) {
        this.setCdEspecialidade(cdEspecialidade);
        this.start();
        this.agendamentoService
            .obterMedicoEspecialidade(cdEspecialidade, this.unidade)
            .then(res => {
                this.stop();
                this.setMedicoList(res.data);
            })
            .catch(this.defaultErrorCatch);
    }

    changeMedico(value) {
        if (!value || !value.cdEspecialidade) {
            return;
        }
        this.validarPrimeiraConsulta(value.cdAgenda);
        this.setDsEspecialidade(value.dsEspecialidade);
        this.setCdEspecialidade(value.cdEspecialidade);
        this.setCdAgenda(value.cdAgenda);
    }

    changeConvenio(value) {
        this.convenioForm.setFieldValue('cdCategoria', undefined);
        this.convenioForm.setFieldValue('cdPlano', undefined);
        if (!value) {
            return;
        }
        this.start();
        this.agendamentoService.obterCategoriaConvenio(value).then(res => {
            this.stop();
            this.setCategoriaList(res.data);
        }).catch(err => this.stop());
        this.agendamentoService.obterPlanoConvenio(value).then(res => {
            this.stop();
            this.setPlanoList(res.data);

        }).catch(err => this.stop());
    }


    validarForm(data) {
        let errors = {};
        this.requiredFieldValidation(data, errors, "cdMedico");
        return errors;
    }

    validarConvenioForm(data) {
        let errors = {};
        this.requiredFieldValidation(data, errors, "cdConvenio");
        this.requiredFieldValidation(data, errors, "cdCategoria");
        this.requiredFieldValidation(data, errors, "cdPlano");
        this.requiredFieldValidation(data, errors, "nrCarteirinha");
        this.requiredFieldValidation(data, errors, "dtValidade");
        return errors;
    }

    selectTipoAgendamento(tipoAgendamento) {
        this.setTipoAgendamento(tipoAgendamento);
        this.next();
    }

    selectIeParticular(ieParticular) {
        this.setIeParticular(ieParticular);
        if (ieParticular === 'S') {
            this.goTo(AgendamentoConstants.MEDICO_ESPECIALIDADE);
        } else {
            this.next();
        }
    }

    selectUnidade(unidade) {
        this.setUnidade(unidade);
        this.agendamentoService
            .obterMedicoEspecialidade(null, unidade)
            .then(res => {
                this.stop();
                this.setMedicoList(res.data);
            })
            .catch(this.defaultErrorCatch);
        this.next();
    }

    gerarAgendamento(data) {
        this.start();
        const that = this;
        this.agendamentoService
            .gerarAgendamento({
                cdPessoaFisica: AuthService.getUser().cdPessoaFisica,
                tipoAgendamento: this.tipoAgendamento,
                ieParticular: this.ieParticular,
                cdMedico: data.cdMedico.cdMedico,
                cdEspecialidade: data.cdEspecialidade,
                cdEstabelecimento: this.unidade,
                ieClassifAgenda: this.ieClassifAgenda,
                cdConvenio: this.cdConvenio,
                cdPlano: this.cdPlano,
                cdCategoria: this.cdCategoria,
                dtValidade: DataUtil.formataData(this.dtValidade),
                nrCarteirinha: this.nrCarteirinha,
            })
            .then(res => {
                this.setAgendamentoGerado(res.data);
                this.setHorarioList([]);
                this.consisteAgendamento();
                this.stop();
                this.next();
            })
            .catch(err => {
                this.stop();
            });
    }

    consisteAgendamento() {
        this.start();
        this.agendamentoService.consisteAgendamento({
            cdConvenio: this.cdConvenio,
            cdPlano: this.cdPlano,
            cdCategoria: this.cdCategoria,
            cdPessoaFisica: AuthService.getUser().cdPessoaFisica,
            cdEstabelecimento: this.unidade,
            cdAgenda: this.cdAgenda
        }).then(res => {
            this.stop();
            this.setErroConsistencia(false);
        }).catch(err => {
            console.log(err);
            this.stop();
            this.setErroConsistencia(true);
        });
    }

    changeDataAgendamento(value) {
        this.setDtAgendamento(value);
        if (value) {
            this.gerarHorario(value);
        }

    }


    gerarHorario(value) {
        if (this.erroConsistencia) {
            return;
        }
        const temp = {...this.agendamentoGerado};
        temp.dtAgendamento = moment(value).format("YYYY-MM-DD");
        temp.cdAgenda = this.cdMedico.cdAgenda;
        this.start();
        this.agendamentoService.gerarHorario(temp).then(res => {
            this.stop();
            this.setHorarioList(res.data);

        }).catch(err => {
            this.stop();
            ToastService.showError('Falha ao gerar agendamento.');
        });

    }


    nextStep() {
        switch (this.step) {
            case AgendamentoConstants.TIPO_AGENDAMENTO:
                if (!this.tipoAgendamento) {
                    ToastService.showError("Selecione o tipo de agendamento.");
                    return;
                }
                this.next();
                break;
            case AgendamentoConstants.ESTABELECIMENTO:
                if (!this.unidade) {
                    ToastService.showError("Selecione o estabelecimento.");
                    return;
                }
                break;
            case AgendamentoConstants.TIPO_ATENDIMENTO:
                if (!this.ieParticular) {
                    ToastService.showError("Selecione o tipo de atendimento.");
                    break;
                }
                if (this.ieParticular === 'S') {
                    this.goTo(AgendamentoConstants.MEDICO_ESPECIALIDADE);
                    return;
                }
                this.next();
                break;
            case AgendamentoConstants.CONVENIO:
                if (!this.convenioForm.isValid) {
                    ToastService.showError("Preencha os campos obrigatórios.");
                    break;
                }
                this.convenioForm.submitForm().then(res => console.log(res));
                break;
            case AgendamentoConstants.MEDICO_ESPECIALIDADE:
                if (!this.formik.isValid || !this.ieClassifAgenda) {
                    ToastService.showError("Preencha os campos obrigatórios.");
                    break;
                }
                this.formik.submitForm().then(res => console.log(res));
                break;
            case AgendamentoConstants.DATA_AGENDAMENTO:
                if (!this.horario || !this.horario.nrSeqAgenda) {
                    ToastService.showError("Selecione um horário.");
                    break;
                }
                this.next();
                break;
            case AgendamentoConstants.CARTEIRINHA:
                if (!this.carteirinha) {
                    ToastService.showError("Selecione uma imagem da carteirinha.");
                    break;
                }
                this.handleDocumentUpload(this.carteirinha);
                break;
            case AgendamentoConstants.DOCUMENTO:
                if (!this.documento) {
                    ToastService.showError("Selecione uma imagem do documento pessoal.");
                    break;
                }
                this.handleDocumentUpload(this.documento);
                break;
        }
    }

    finalizar() {
        this.start();
        this.agendamentoService.validarHorario({
            nrSeqAgendamento: this.horario.nrSeqAgeint,
            nrSeqItem: this.horario.nrSeqAgeintItem,
            nrSeqHorario: this.horario.nrSeqAgeintLib,
            cdMedico: this.cdMedico.cdMedico,
            cdEstabelecimento: this.cdMedico.cdEstabelecimento,
            dtHorario: this.horario.dtAgenda,
            nrMinuto: this.horario.nrMinutoDuracao,
            cdAgenda: this.horario.cdAgenda
        }).then(res => {
            this.finalizarAgendamento();
        }).catch(err => {
            this.stop();
            this.goTo(5);
            ToastService.showWarn('Horário não encontra-se mais disponível, por favor selecione outro', 60_000);
        });
    }

    finalizarAgendamento() {
        this.agendamentoService
            .confirmarAgenda({
                cdAgenda: this.horario.cdAgenda,
                nrSeqAgendamento: this.horario.nrSeqAgeint,
                nrSeqItem: null,
                nrSeqHorario: this.horario.nrSeqAgeintLib,
                cdMedico: this.cdMedico.cdMedico,
                cdEstabelecimento: this.cdMedico.cdEstabelecimento,
                dtAgenda: this.horario.dtAgenda,
                nrMinutoDuracao: this.horario.nrMinutoDuracao,
                cdPaciente: AuthService.getUser().cdPessoaFisica,
                dsAgenda: this.cdMedico.dsAgenda,
                dsEspecialidade: this.especialidade.dsEspecialidade,
                nmPaciente: AuthService.getUser().nmPessoaFisica,
                nrProntuario: AuthService.getUser().nrProntuario,
                dsPlano: (this.planoList.filter(f => f.cdPlano == this.cdPlano)[0].dsPlano),
                dsConvenio: (this.convenioList.filter(f => f.cdConvenio == this.cdConvenio)[0].dsConvenio),
                dsCategoria: (this.categoriaList.filter(f => f.cdCategoria == this.cdCategoria)[0].dsCategoria),
                dsLocal: this.cdMedico.dsLocal,
                dsEmail: AuthService.getUser().dsEmail
            })
            .then(res => {
                ToastService.showSuccess("Agendamendo gerado com sucesso.");
                this.stop();
                this.history.push("/meus-agendamentos");
            })
            .catch(err => {
                this.stop();
                ToastService.showError("Falha na requisição.");
            });
    }

    next() {
        const i = this.step;
        const nextIndex = i + 1;
        const newBullets = [...GerarAgendamentoController.bullet];
        newBullets[0] = false;
        newBullets[nextIndex] = !newBullets[nextIndex];
        this.setBulletActive(newBullets);
        this.setStep(nextIndex);
    }

    previousStep() {
        const i = this.step;
        let previousIndex = i - 1;
        const newBullets = [...GerarAgendamentoController.bullet];
        if ((i === AgendamentoConstants.MEDICO_ESPECIALIDADE || i === AgendamentoConstants.DOCUMENTO) && this.ieParticular === 'S') {
            newBullets[i] = false;
            previousIndex--;
        }
        newBullets[0] = false;
        newBullets[previousIndex] = !newBullets[previousIndex];
        this.setBulletActive(newBullets);
        this.setStep(previousIndex);
    }

    obterDiasAgenda(mesAno, onChange, event) {
        if (onChange !== undefined) {

            setTimeout(() => {
                onChange(event.originalEvent, event.value);
            }, 200);
        }
        if (!this.cdMedico || !this.cdMedico.cdAgenda || !mesAno) {
            return;
        }
        this.start();
        this.agendamentoService.obterDiasAgenda({
            mes: mesAno,
            cdAgenda: this.cdMedico.cdAgenda
        }).then(res => {
            if (res.data && res.data.length > 0) {

                this.setDiasAgendaList(res.data.map(m => m.dtAgenda));
            } else {
                this.setDiasAgendaList([]);
            }
            this.stop();
        });
    }

    getAnos() {
        const maxYear = 2100;
        const currentYear = new Date().getFullYear();
        const lista = [];
        let j = 0;
        for (let i = currentYear; i <= maxYear; i++) {
            lista.push({
                label: i,
                value: i,
                index: j
            });
            j++;
        }
        return lista;
    }

    getMonths() {
        return [
            {
                label: 'Janeiro',
                value: 0,
                index: 0
            },
            {
                label: 'Fevereiro',
                value: 1,
                index: 1
            },
            {
                label: 'Março',
                value: 2,
                index: 2
            },
            {
                label: 'Abril',
                value: 3,
                index: 3
            },
            {
                label: 'Maio',
                value: 4,
                index: 4
            },
            {
                label: 'Junho',
                value: 5,
                index: 5
            },
            {
                label: 'Julho',
                value: 6,
                index: 6
            },
            {
                label: 'Agosto',
                value: 7,
                index: 7
            },
            {
                label: 'Setembro',
                value: 8,
                index: 8
            },
            {
                label: 'Outubro',
                value: 9,
                index: 9
            },
            {
                label: 'Novembro',
                value: 10,
                index: 10
            },
            {
                label: 'Dezembro',
                value: 11,
                index: 11
            },
        ]
    }
}
