import React from "react";
import {Card} from "primereact/card";
import {DataPipe, DataTempoPipe, UnidadePipe} from "../../../../vendors/internalComponents/Pipe/Pipe";
import {Button} from "primereact/button";
import {useHistory} from "react-router-dom";
import {ConditionalComponent} from "../../../../vendors/internalComponents/ConditionalComponent/ConditionalComponent";

export default function CheckinAgendadoInicio(props) {

    const history = useHistory();


    function renderItem(item, index) {
        if (item) {
            return (
                <div key={index}>
                    <div className="chekin-agendado-wrapper">
                        <div className="checkin-agendado-item">
                            <div>
                                <h3 className="checkin-agendado-title">Data</h3>
                                <label>{DataTempoPipe(item.dtAgenda.substring(0, 10))}</label>
                            </div>
                            <div>
                                <h3 className="checkin-agendado-title">Local</h3>
                                <label>{UnidadePipe(item.dsEstabelecimento)}</label>
                            </div>
                        </div>
                        <div className="checkin-agendado-item">
                            <div>
                                <h3 className="checkin-agendado-title">Status</h3>
                                <label>{item.dsStatusAgendamento}</label>
                            </div>
                            <div>
                                <h3 className="checkin-agendado-title">Tipo</h3>
                                <label>{item.dsTipoAgenda}</label>
                            </div>
                        </div>
                        <div className="checkin-agendado-item">
                            <div style={{width: '100%'}}>
                                <h3 className="checkin-agendado-title">Servico</h3>
                                <label>{item.dsProcedimento}</label>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }


    return (


        <>
            <ConditionalComponent
                condition={!!props.exame}
                positive={
                    <Card id="agendamento-card">
                        <div className="content-title">
                            <h3 className="checkin-agendado-title">Agendamentos disponiveis para check-in:</h3>
                            <label>{DataPipe(props.exame?.dtAgenda.substring(0, 10))}</label>
                        </div>

                        {props.exame?.lista.map((exam, index) => renderItem(exam, index))}

                        <div className="checkin-footer">
                            <Button
                                label={<h3>Iniciar</h3>}
                                type="button"
                                onClick={() => props.iniciarCheckin()}
                                className="btn-confirmar"
                            />
                        </div>
                    </Card>

                }
                negative={
                    <>
                        <Card id="agendamento-card">
                            <div className="checkin-card">
                                <h3>Atenção</h3>
                                <label>Você não possui agendamentos disponíveis. Deseja iniciar o check-in para exames
                                    laboratoriais?</label>
                                <div className="tipos-checkin">
                                    <Button
                                        label={<h4>Exames laboratoriais</h4>}
                                        type="button"
                                        onClick={() => history.push('/checkin/laboratorial')}
                                        className="btn-confirmar"
                                    />
                                </div>
                            </div>
                        </Card>
                    </>
                }
            />

        </>
    );
}
