import React, {useEffect} from "react";
import CheckinStepsController from "../controller/checkin-steps.controller";
import CheckinUnidade from "../components/CheckinUnidade";
import {Card} from "primereact/card";
import CheckinAtendimento from "../components/CheckinAtendimento";
import CheckinData from "../components/CheckinData";
import CheckinDocumento from "../components/CheckinDocumento";
import CheckinPedido from "../components/CheckinPedido";
import CheckinCarteirinha from "../components/CheckinCarteirinha";
import CheckinBullets from "../components/CheckinBullets";
import CheckinMediaTempo from "../components/CheckinMediaTempo";
import CheckinProntoSocorroController from "../controller/checkin-pronto-socorro.controller";
import CheckinPrevisaoChegada from "../components/CheckinPrevisaoChegada";
import CheckinTipoAtendimento from "../components/CheckinTipoAtendimento";

export default function CheckinProntoSocorro() {
    const controller = new CheckinProntoSocorroController();

    useEffect(() => {
        controller.init();
    }, []);

    const getTemplateByStep = () => {
        // if (controller.step === 0) {
        //     return <CheckinMediaTempo controller={controller}/>
        // }
        if (controller.step === 0) {
            return <CheckinPrevisaoChegada controller={controller}/>
        }
        if (controller.step === 1) {
            return <CheckinTipoAtendimento controller={controller}/>
        }
        if (controller.step === 2) {
            return <CheckinAtendimento controller={controller} documento={controller.documento}/>
        }
        if (controller.step === 3) {
            return <CheckinCarteirinha controller={controller}/>
        }
        if (controller.step === 5) {
            return <CheckinDocumento controller={controller}/>
        }
        return <></>;
    }

    return (
        <>
            <Card id="agendamento-card">
                {getTemplateByStep()}
            </Card>
            <CheckinBullets controller={controller}/>
        </>
    );
}
