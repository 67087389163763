import React, {useEffect} from "react";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {useHistory} from "react-router-dom";
import '../style.scss'
import AnalyticsUtil from "../../../shared/utils/AnalyticsUtil";

export default function CheckinChoice() {
    const history = useHistory();

    useEffect(() => {
        AnalyticsUtil.acessoAPagina('checkin');
    }, []);
    return (
        <>
            <Card id="agendamento-card">
                <div className="checkin-card">
                    <h3>Vamos iniciar seu check-in</h3>
                    <label>Deseja realizar o check-in para qual o tipo de exame?</label>
                    <div className="tipos-checkin">
                        {/*<Button*/}
                        {/*    label={<h4>Exames agendados</h4>}*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => history.push('/checkin/agendado')}*/}
                        {/*    className="btn-confirmar"*/}
                        {/*/>*/}

                        {/*<hr/>*/}

                        {/*<Button*/}
                        {/*    label={<h4>Exames laboratoriais</h4>}*/}
                        {/*    type="button"*/}
                        {/*    onClick={() => history.push('/checkin/laboratorial')}*/}
                        {/*    className="btn-confirmar"*/}
                        {/*/>*/}

                        {/*<hr/>*/}

                        <Button
                            label={<h4>Pronto socorro</h4>}
                            type="button"
                            onClick={() => history.push('/checkin/pronto-socorro')}
                            className="btn-confirmar"
                        />
                    </div>
                </div>
            </Card>
            <label className="public-icon">
                <div id="passar_mouse">
                    <i className="fas fa-info-circle" style={{fontSize: 25, marginTop: 15, color: '#2364AA'}}/>
                    <div id="mostrar" style={{fontSize: 16, marginTop: 15, color: '#2364AA'}}>
                        Para exames agendados é necessário <br/>
                        confirmar o agendamento, o check-in <br/>
                        estará disponível 72 horas antes da data do <br/>
                        exame.
                        <br/>
                        <br/>
                        Para exames laboratoriais, não é necessário <br/>
                        agendamento.
                    </div>
                </div>
            </label>

        </>
    );
}
