import React from 'react';
import {Dropdown} from "primereact/dropdown";
import Container from "../../../components/Container/Container";
import Field from "../../../components/Field/Field";
import classNames from "classnames";
import {RadioButton} from "primereact/radiobutton";
import {If} from "../../../../vendors/internalComponents/If/If";

export default function MedicoEspecialidade(props) {

    return (
        <>
            <div className="step-right-border">

            </div>
            <h3 className="titulo-primario">Selecione a especialidade ou o profissional da consulta:</h3>
            <Container style={{marginTop: '30px'}}>
                <Field valid={props.controller.isFormFieldValid('cdEspecialidade')} col={4}>
                    <h3>Especialidade</h3>
                    <Dropdown
                        emptyMessage="Nenhum registro encontrado"
                        emptyFilterMessage="Nenhum registro encontrado"
                        className={classNames({'p-invalid': !props.controller.isFormFieldValid('cdEspecialidade')})}
                        options={props.controller.especialidadeList}
                        value={props.controller.cdEspecialidade}
                        name="cdEspecialidade"
                        filter
                        placeholder="Selecione"
                        onChange={(e) => props.controller.changeEspecialidade(e.target.value)}
                        id="cdEspecialidade"
                        optionLabel="dsEspecialidade"
                        optionValue="cdEspecialidade"
                    />
                </Field>

                <Field col={4} offset={4} valid={props.controller.isFormFieldValid('cdMedico')}>
                    <h3>Profissional</h3>
                    <Dropdown
                        emptyMessage="Nenhum registro encontrado"
                        emptyFilterMessage="Nenhum registro encontrado"
                        name="cdMedico"
                        id="cdMedico"
                        filter
                        value={props.controller.formik.values.cdMedico}
                        placeholder="Selecione"
                        className={classNames({'p-invalid': !props.controller.isFormFieldValid('cdMedico')})}
                        options={props.controller.medicoList}
                        onChange={(e) => {
                            props.controller.changeMedico(e.value);
                            props.controller.formik.handleChange(e);
                        }}
                        optionLabel="dsAgenda"
                    />
                </Field>
            </Container>
            <h3 className={!props.controller.ieClassifAgenda ? 'hcor-field-invalid' : 'titulo-primario'} style={{marginTop: '30px'}}>Selecione a classificação da consulta</h3>
            <Container>
                <Field style={{marginTop: '15px'}} col={12}>
                    <If condition={props.controller.showPrimeiraConsulta}>
                        <div>
                            <RadioButton
                                value="N"
                                onChange={(e) => props.controller.setIeClassifAgenda('N')}
                                checked={props.controller.ieClassifAgenda === 'N'}
                                className="radio_button"
                            />
                            <label className="radio_button">Primeira Consulta</label>
                        </div>
                    </If>
                    <div>
                        <RadioButton
                            value="PR"
                            onChange={(e) => props.controller.setIeClassifAgenda('PR')}
                            checked={props.controller.ieClassifAgenda === 'PR'}
                            className="radio_button"
                        />
                        <label className="radio_button">Retorno</label>
                    </div>
                    <div>
                        <RadioButton
                            value="O"
                            onChange={(e) => props.controller.setIeClassifAgenda('O')}
                            checked={props.controller.ieClassifAgenda === 'O'}
                            className="radio_button"
                        />
                        <label className="radio_button">Seguimento</label>
                    </div>
                </Field>
                <Field valid={!!props.controller.ieClassifAgenda}>
                </Field>
            </Container>

        </>
    );
}
