import React from "react";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {DataPipe} from "../../../vendors/internalComponents/Pipe/Pipe";

export default function FormaEnvio(props) {

    const enviar = (forma) => {
        props.setFormaEnvio(forma);
    };

    return (
        <>
            <div className="public-login-container">
                <div className="public-input-group">
                    <h6 className="public-informe">
                        Paciente
                    </h6>
                    <h6 className="public-informe" style={{fontWeight: '900'}}>
                        {props.nome}
                    </h6>
                </div>
                <div className="public-input-group">
                    <h6 className="public-informe">
                        Data de nascimento
                    </h6>
                    <h6 className="public-informe" style={{fontWeight: '900'}}>
                        {DataPipe(props.dataNascimento)}
                    </h6>
                </div>
                <div className="public-input-group">
                    <h6 className="public-informe">
                        Como gostaria de receber o código de verificação para dar seguimento ao processo
                        de {props.isEdit ? 'recuperação' : 'cadastro'} de
                        senha?
                    </h6>
                </div>
                <div className="public-button-group" style={{marginTop: '60px'}}>
                    <Button
                        onClick={(e) => enviar('sms')}
                        type="text"
                        className="public-button public-blue-button">SMS</Button>
                </div>
                <div className="public-button-group" style={{marginTop: '20px'}}>
                    <Button
                        onClick={(e) => enviar('email')}
                        type="text"
                        className="public-button public-blue-button">E-mail</Button>
                </div>
                <div className="public-button-group" style={{marginTop: '30px',}}>
                    <Link to="/public/login">
                        <Button className="public-button public-white-button"
                                onClick={() => props.voltar(props.backId)}>Voltar</Button>
                    </Link>
                </div>
            </div>

        </>
    );
}
