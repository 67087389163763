import React from "react";
import {EmptyComponent} from "../EmptyComponent/EmptyComponent";

export default function TituloAzul(props) {
    return (
        <div style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '20px',
            backgroundColor: '#554B91',
            borderRadius: '5px',
            padding: '6px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <EmptyComponent
                condition={props.icone != undefined}
                body={

                    <i style={{fontSize: '20px', color: 'white', marginRight: '10px'}} className={props.icone}></i>
                }
            />
            <h1 style={{
                fontFamily: 'TTNormsBlack',
                fontSize: '20px',
                color: '#ffffff',
                font: 'normal',
                marginTop: '5px'
            }}>{props.titulo}</h1>
        </div>
    );
}
