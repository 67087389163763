import React from 'react';
import {Switch} from "react-router-dom";
import {ContentRoute} from "../../../vendors/_metronic/layout";
import ResultadoExame from "./pages/ResultadoExame";

export default function ResultadoExamePage() {

    return (
        <Switch>
            <ContentRoute
                from="/resultado-exame"
                exact={true}
                component={ResultadoExame}
            />
        </Switch>
    );
}
