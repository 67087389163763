import React, {useEffect} from 'react';
import Field from "../../../components/Field/Field";
import Container from "../../../components/Container/Container";
import {DataPipe, TempoPipe} from "../../../../vendors/internalComponents/Pipe/Pipe";
import {If} from "../../../../vendors/internalComponents/If/If";

export default function ConfirmarAgendamento(props) {
    const medico = props.controller.cdMedico;
    const especialidade = props.controller.especialidade;
    const item = props.controller.horario;

    useEffect(() => {
        if (props.controller.ieParticular === 'S') {
            props.controller.obterValor();
        }
    }, []);

    return (
        <>
            <Container>
                <Field col={8} className="text-left" breakLine={true}>
                    <h3 className="titulo-primario">Confirme os dados do seu agendamento</h3>
                    <div className="dados-funcionario">
                        <h3>{medico.nmMedico} - {especialidade.dsEspecialidade} - {medico.dsEstabelecimento}</h3> <br/>
                        <div className="dados-funcionario-item">
                            <div>

                                <label className="no-spaces">{DataPipe(item.dtAgenda)}</label><br />
                                <label className="no-spaces"><b>{TempoPipe(item.dtAgenda)}</b></label>
                            </div>
                            <If condition={props.controller.valor >= 0}>
                                <div>
                                    <label>Valor</label> <br/>
                                    <label className="no-spaces">R$ {props.controller.valor}</label> <br/>
                                </div>
                            </If>
                        </div>
                    </div>

                </Field>
            </Container>
        </>
    );
}
