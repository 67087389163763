import React, {useEffect} from "react";
import CheckinStepsController from "../controller/checkin-steps.controller";
import CheckinUnidade from "../components/CheckinUnidade";
import {Card} from "primereact/card";
import CheckinAtendimento from "../components/CheckinAtendimento";
import CheckinData from "../components/CheckinData";
import CheckinDocumento from "../components/CheckinDocumento";
import CheckinPedido from "../components/CheckinPedido";
import CheckinCarteirinha from "../components/CheckinCarteirinha";
import CheckinBullets from "../components/CheckinBullets";

export default function CheckinLaboratorial() {
    const controller = new CheckinStepsController(false, 2);

    useEffect(() => {
        controller.init();
    }, []);

    const getTemplateByStep = () => {
        if (controller.step === 0) {
            return <CheckinUnidade controller={controller}/>
        }
        if (controller.step === 1) {
            return <CheckinData controller={controller}/>
        }
        if (controller.step === 2) {
            return <CheckinAtendimento controller={controller}/>
        }
        if (controller.step === 3) {
            return <CheckinDocumento controller={controller}/>
        }
        if (controller.step === 4) {
            return <CheckinCarteirinha controller={controller}/>
        }
        if (controller.step === 5) {
            return <CheckinPedido controller={controller}/>
        }
        return <></>;
    }

    return (
        <>
            <Card id="agendamento-card">
                {
                    getTemplateByStep()
                }
            </Card>
            <CheckinBullets controller={controller}/>
        </>
    );
}
