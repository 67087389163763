import React from "react";
import "../public-layout.scss";
import {toAbsoluteUrl} from "../../../vendors/_metronic/_helpers";

export default function PublicLayout (props) {
    return (
        <>
            <div className="public-container">

                <div className="public-landscape-container">
                    <div className="public-titulo">
                        <div>
                            <img style={{width: '500px'}} src={toAbsoluteUrl('/media/hcor/logo_principal.png')} />
                        </div>
                    </div>
                </div>
                <div className="public-children-container">
                    {props.children}
                </div>
            </div>
        </>
    )
}

