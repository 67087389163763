import React, {useState} from "react";
import {Route, Switch} from "react-router-dom";
import {Layout} from "../vendors/_metronic/layout";
import Home from "./modules/Home";
import ConfirmationDialog from "../vendors/internalComponents/ConfirmationDialog/ConfirmationDialog";
import ConfirmationUtil from "./shared/utils/ConfirmationUtil";
import MenuConfiguracao from "./modules/MenuConfiguracao";
import AlterarSenha from "./modules/AlterarSenha";
import ExtratoDeConta from "./modules/ExtratoDeConta";
import MeusAgendamentos from "./modules/MeusAgendamentos";
import Checkin from "./modules/Checkin/Checkin";
import DocumentoAgendamento from "./modules/MeusAgendamentos/pages/DocumentoAgendamento";
import GerarAgendamento from "./modules/MeusAgendamentos/pages/GerarAgendamento";
import QuestionarioPage from "./modules/Questionario/QuestionarioPage";
import AuthService from "./services/auth.service";
import ResultadoExamePage from "./modules/ResultadoExame/ResultadoExamePage";

export default function BasePage() {
    const [visible, setVisible] = useState(false);
    ConfirmationUtil.init(setVisible);

    if (!AuthService.getUser()) {
        return (
            <></>
        );
    }

    return (
        <Layout>
            <ConfirmationDialog
                reject={ConfirmationUtil.reject}
                visible={visible}
                accept={ConfirmationUtil.accept}
            />
            <Switch>
                <Route exact path="/home" component={Home}/>
                <Route exact path="/extrato" component={ExtratoDeConta}/>
                <Route exact path="/menu-configuracao" component={MenuConfiguracao}/>
                <Route exact path="/alterar-senha" component={AlterarSenha}/>
                <Route exact path="/meus-agendamentos" component={MeusAgendamentos}/>
                <Route exact path="/meus-agendamentos/gerar" component={GerarAgendamento}/>
                <Route exact path="/meus-agendamentos/documentos/:nr_seq_autorizacao" component={DocumentoAgendamento}/>
                <Route path="/checkin" component={Checkin}/>
                <Route path="/questionario" component={QuestionarioPage}/>
                <Route exact path="/resultado-exame" component={ResultadoExamePage}/>
            </Switch>
        </Layout>
    );
}
