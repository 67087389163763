import DataUtil from "../../../app/shared/utils/DataUtil";
import React, {useState} from "react";

export function UnidadePipe(unidade) {
    if (!unidade) {
        return '';
    }
    if (unidade.toLowerCase() === 'hcor') {
        return 'Paraíso';
    }
    return 'Cidade Jardim';
}


export function DataPipe(data) {
    const dataRetorno = DataUtil.formataData2(data);
    return (
        <label>{dataRetorno}</label>
    );
}

export function DataPipeResumo(data) {
    const dataRetorno = DataUtil.formataData3(data);
    return (
        <>{dataRetorno}</>
    );
}

export function DataTempoPipe(data) {
    const dataRetorno = DataUtil.formataDataTempo(data);
    return (
        <>{dataRetorno}</>
    );
}


export function TempoPipe(data) {
    const dataRetorno = DataUtil.formataTempo(data);
    return (
        <>{dataRetorno}</>
    );
}


export function CnpjPipe(cnpj) {
    if (!cnpj || cnpj.length < 14) {
        return cnpj + '';
    }
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function CpfPipe(cpf) {
    if (!cpf) {
        return '';
    }
    if (!cpf || cpf.length < 11) {
        return cpf + '';
    }
    return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function GeneroPipe(genero) {
    if (!genero) {
        return genero + '';
    }
    switch (genero) {
        case 'M':
            return 'Masculino';
        case 'F':
            return 'Feminino';
        case 'O':
            return 'Outros';
        default:
            return '';
    }
}

export function SimNaoPipe(opc) {
    if (!opc) {
        return '';
    }
    if (opc == 'S') {
        return 'Sim';
    }
    return opc == 'N' ? 'Não' : '';
}

export function NivelPipe(opc) {
    if (!opc) {
        return '';
    }
    if (opc == 'A') {
        return 'Alto(a)';
    }
    return opc == 'M' ? 'Médio(a)' : 'Baixo(a)';
}

export function TelefonePipe(telefone) {
    if (!telefone || telefone.length < 10) {
        return '';
    }
    if (telefone.length == 10) {
        return telefone.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
    }
    return telefone.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4")
}

export function LinkPipe(link) {
    if (!link) {
        return '';
    }
    return (
        <a href="link" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (link.indexOf('http://') < 0 || link.indexOf('https://')) {
                window.open('http://'.concat(link), '_blank');
            } else {
                window.open(link, '_blank');
            }
        }}>{link}</a>
    )
}

export function CompressInformation(props) {
    const [expanded, setExpanded] = useState(false);

    function changeState(e) {
        e.preventDefault();
        e.stopPropagation();
        console.log('aqui');
        setExpanded(!expanded);
    }

    return (
        <>
            {props.info.length < props.maxLength ?
                <>{props.info}</> :
                <>
                    {expanded ?
                        <a href="#" onClick={(e) => changeState(e)}>{props.info}</a> :
                        <a href="#"
                           onClick={(e) => changeState(e)}>{props.info.substring(0, props.maxLength).concat('...')}</a>
                    }
                </>
            }
        </>

    );
}
