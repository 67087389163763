export default class ConfirmationUtil {
    static _setVisible;

    static _accept;

    static _reject;

    // constructor() {

    // }

    static confirm(accept, reject) {
        ConfirmationUtil._accept = accept;
        ConfirmationUtil._reject = reject;
        ConfirmationUtil._show();
    }

    static accept() {
        ConfirmationUtil._accept();
        ConfirmationUtil._hide();
    }

    static reject() {
        if (ConfirmationUtil._reject) ConfirmationUtil._reject();
        ConfirmationUtil._hide();
    }

    static _show() {
        ConfirmationUtil._setVisible(true);
    }

    static _hide() {
        ConfirmationUtil._setVisible(false);
    }

    static init(setVisible) {
        ConfirmationUtil._setVisible = setVisible;
    }
}
