import React from "react";
import {If} from "../../../../vendors/internalComponents/If/If";
import {Button} from "primereact/button";
export default function Pergunta(props) {

    function clickOpcao(m) {
        if (m.perguntaList && m.perguntaList.length > 0) {
            props.next(m);
        }
    };

    const templateOpcao = (m) => {
        return (
            <div>
                <Button
                    onClick={() => clickOpcao(m)}
                    label={m.dsDescricao}
                />
            </div>
        );
    };

    return (
        <>
            {props.model.dsDescricao}
                {props.model.opcaoList && props.model.opcaoList.map(m => templateOpcao(m))}
        </>
    );
}
