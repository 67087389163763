import React, {useEffect} from 'react';
import ResultadoExameController from "../controller/resultado-exame.controller";
import {Card} from "primereact/card";
import '../assets/exame-page.scss'
import ExameFilter from "../components/ExameFilter";
import ExameItem from "../components/ExameItem";
import {If} from "../../../../vendors/internalComponents/If/If";

export default function ResultadoExame() {
    const controller = new ResultadoExameController();
    const filter = controller.filter;

    useEffect(() => {
        controller.init();
    }, []);

    return (
        <>
            <Card className="no-spaces">
                <ExameFilter filter={filter} controller={controller}/>
                <If condition={!controller.lista || controller.lista.length <= 0}>
                    <h4 style={{textAlign: 'center', marginTop: '50px'}}>Nenhum resultado encontrado.</h4>
                </If>
                {controller.lista.map((m, index) => <ExameItem key={'exame-item-n1-' + index} item={m} index={index}
                                                               controller={controller}/>)}
            </Card>
        </>
    );
}
