import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    username: ""
};

function changeUser(state = initialState, action) {
    switch (action.type) {
        case "change":
            return {...action};
        default:
            return state;
    }
}

export const reducer = persistReducer(
    {storage, key: "user", whitelist: ["user"]},
    changeUser
);
