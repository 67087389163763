import React, {useState} from 'react';
import {DataPipe, DataTempoPipe, TempoPipe} from "../../../../vendors/internalComponents/Pipe/Pipe";
import {If} from "../../../../vendors/internalComponents/If/If";

export default function Horario(props) {
    const [indexSelected, setIndexSelected] = useState(-1);
    const itemTemplate = (item, index) => {
        return (
            <div
                className={`agendamento-horario-item ${indexSelected === index ? 'selected' : ''}`}
                onClick={() => {
                    if (indexSelected === index) {
                        setIndexSelected(-1);
                        props.controller.selectHorario(undefined);
                        return;
                    }
                    setIndexSelected(index)
                    props.controller.selectHorario(item);
                }} key={index}>
                <label className="no-spaces">{DataPipe(item.dtAgenda)}</label> <br/>
                <label className="no-spaces"><b>{TempoPipe(item.dtAgenda)}</b></label>
            </div>
        );
    }
    return (
        <>
            <If condition={props.controller.horarioList && props.controller.horarioList.length > 0}>
                <h3 className="titulo-primario" style={{marginLeft: '30px'}}>Horários disponíveis:</h3>
                <div className="agendamento-horario-wrapper">
                    {props.controller.horarioList.map((m, index) => itemTemplate(m, index))}
                </div>
            </If>
            <If condition={(!props.controller.horarioList || props.controller.horarioList.length <= 0) && !props.controller.dtAgendamento}>
                <h3 style={{marginTop: '60px'}}>Selecione o dia para validarmos os horários</h3>
            </If>
            <If condition={(!props.controller.horarioList || props.controller.horarioList.length <= 0) && !!props.controller.dtAgendamento}>
                <h3 style={{marginTop: '60px', color: '#ff0042'}}>Nenhum horário disponível, tente outra data.</h3>
            </If>
        </>
    );
}
