import React, {useEffect, useState} from "react";
import {EmptyComponent} from "../../../vendors/internalComponents/EmptyComponent/EmptyComponent";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {CadastroService} from "../../services/common/cadastro.service";
import FormaEnvio from "../Components/FormaEnvio";
import ValidaToken from "../Components/ValidaToken";
import Senha from "../Components/Senha";
import {BlockuiService} from "../../services/blockui.service";
import {ToastService} from "../../services/utils/toast.service";
import MessageUtil from "../../shared/utils/MessageUtil";
import {useDispatch} from "react-redux";

export default function Recover() {
    const [username, setUsername] = useState('');
    const [dtNascimento, setDtNascimento] = useState('');
    const [estado, setEstado] = useState(0);
    const [medico, setMedico] = useState({});
    const [formaEnvio, setFormaEnvio] = useState('');
    const [dataExpiracao, setDataExpiracao] = useState('');
    const [token, setToken] = useState({});
    const cadastroService = new CadastroService();

    const dispatch = useDispatch();
    let validar = () => {

        cadastroService.validarCpf(username).then(res => {
            dispatch(BlockuiService.stop);
            if (!res.data) {
                ToastService.showError(MessageUtil.USUARIO_NAO_ENCONTRADO);
                return;
            }
            const temp = {...res.data};
            temp.nrCpf = !!temp.nrDocumento ? temp.nrDocumento : temp.nrCartaoEstrangeiro;
            setEstado(1);
            setMedico(temp);
        }, err => {
            ToastService.showError(MessageUtil.USUARIO_NAO_ENCONTRADO);
            dispatch(BlockuiService.stop);

        });

    };

    let voltar = (id) => {
        setEstado(id);
    }

    let tokenValidado = () => {
        setEstado(3);
    }

    useEffect(() => {
        if (formaEnvio && estado != 2) {
            dispatch(BlockuiService.start);
            cadastroService.sendToken({formaEnvio: formaEnvio, cdPessoaFisica: medico.cdPessoaFisica}).then(res => {
                ToastService.showSuccess(MessageUtil.TOKEN_ENVIADO_SUCESSO);
                setEstado(2);
                let date = new Date();
                date = new Date(date.getTime() + 2 * 60000)
                setDataExpiracao(date);
                setToken(res.data);
                dispatch(BlockuiService.stop);
            }, err => {
                ToastService.showError(MessageUtil.FALHA_AO_ENVIAR_TOKEN);
                dispatch(BlockuiService.stop);
            });
        }
    }, [formaEnvio]);
    return (
        <div className="public-content-container">
            <div className="hcor-logo">
                <img width="300px" src="/media/hcor/logo-hcor.png"/>
            </div>
            <EmptyComponent condition={estado == 0} body={
                <div className="public-login-container">
                    <div className="public-input-group">
                        <h6 className="public-informe">
                            Prezado paciente, informe seu CPF para identificarmos seu cadastro
                            no Hcor
                        </h6>
                    </div>
                    <div className="public-input-group">
                        <h6 className="public-informe">
                            Caso seja estrangeiro, informe seu Registro Nacional de Estrangeiro cadastrado
                        </h6>
                    </div>
                    <div className="public-input-group" style={{marginTop: '50px'}}>
                        <InputText maxLength={100}
                                   value={username} onChange={(e) => setUsername(e.target.value)}
                                   placeholder="CPF"
                                   className="public-input"/>
                    </div>
                    <div className="public-button-group" style={{marginTop: '60px'}}>
                        <Button
                            onClick={(e) => validar()}
                            type="text"
                            className="public-button public-blue-button">Validar</Button>
                    </div>
                    <div className="public-button-group" style={{marginTop: '30px',}}>
                        <Link to="/public/login">
                            <Button className="public-button" style={{
                                background: 'white',
                                border: '1px solid #A5A5A5',
                                color: '#A5A5A5'
                            }}>Voltar</Button>
                        </Link>
                    </div>
                </div>
            }/>
            <EmptyComponent condition={estado == 1} body={
                <FormaEnvio
                    isEdit={true}
                    setFormaEnvio={setFormaEnvio}
                    backId={0}
                    voltar={voltar}
                    nome={medico.nmPessoaFisica}
                    dataNascimento={medico.dtNascimento}/>
            }/>
            <EmptyComponent condition={estado == 2} body={

                <ValidaToken
                    validado={tokenValidado}
                    backId={1}
                    pessoa={medico}
                    voltar={voltar}
                    setToken={setToken}
                    setDataExpiracao={setDataExpiracao}
                    cdPessoaFisica={medico.cdPessoaFisica}
                    token={token}
                    setFormaEnvio={setFormaEnvio}
                    formaEnvio={formaEnvio}
                    dataExpiracao={dataExpiracao}
                />
            }/>

            <EmptyComponent condition={estado == 3} body={

                <Senha isEdit={true} medico={medico} voltar={voltar} backId={2}/>
            }/>
        </div>
    );
}
