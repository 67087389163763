import {useHistory} from "react-router-dom";
import {useSubheader} from "../../../vendors/_metronic/layout";
import {useDispatch} from "react-redux";
import {BlockuiService} from "../../services/blockui.service";
import {ToastService} from "../../services/utils/toast.service";

export default class AbstractController {
    constructor() {
        this.useSubheader = useSubheader();
        this.history = useHistory();
        this.dispatch = useDispatch();
    }

    init() {
    }

    setTitle(title) {
        this.useSubheader.setTitle(title);
    }

    start() {
        this.dispatch(BlockuiService.start);
    }

    stop() {
        this.dispatch(BlockuiService.stop);
    }

    defaultErrorCatch(err, context) {
        ToastService.showError("Falha na requisição.");
    }
}
