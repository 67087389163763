import React from "react";
import {Button} from "primereact/button";

export default function CheckinPrevisaoChegada(props) {


    return (
        <>
            <div className="checkin-card">
                <h3>Previsão de chegada no hcor</h3>
                <div className="checkin-media-wrapper">
                    <div className="checkin-info-dia">
                        <Button label="30 min" className="checkin-button checkin-button-danger"
                                onClick={() => props.controller.selectDsHorario('30 min')}/>
                        <Button label="Até 1 hora" className="checkin-button checkin-button-warning"
                                onClick={() => props.controller.selectDsHorario('Até 1 hora')}/>
                        <Button label="Mais de 1 hora" className="checkin-button checkin-button-success"
                                onClick={() => props.controller.selectDsHorario('Mais de 1 hora')}/>
                    </div>
                </div>
            </div>
        </>
    );


}
