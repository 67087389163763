import React from 'react';
import {Button} from "primereact/button";
import {RadioButton} from "primereact/radiobutton";

export default function TipoAgendamento(props) {

    const consultaAtivo = props.controller.tipoAgendamento === 'C' ? 'ativo' : ''
    return (
        <>
            <h3 className="titulo-primario">Escolha o tipo de agendamento:</h3>
            <br/>
            <div className="step-wrapper">
                <div>
                    <Button onClick={() => props.controller.selectTipoAgendamento('C')}
                            type="button"
                            className="btn-blue-icon">
                        <h3 className={`titulo-primario ${consultaAtivo}`}>Consulta médica</h3>
                    </Button>
                </div>
            </div>
        </>
    );
}
