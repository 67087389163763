import React from 'react';
import {Switch} from "react-router-dom";
import {ContentRoute} from "../../../vendors/_metronic/layout";
import Questionario from "./pages/Questionario";

export default function QuestionarioPage() {

    return (
        <Switch>
            <ContentRoute
                from="/questionario"
                exact={true}
                component={Questionario}
            />
        </Switch>
    );
}
