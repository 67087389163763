export default class ValidacaoUtil {
    static cnpjValido(value) {
        if (!value) return false;

        const validTypes =
            typeof value === "string" ||
            Number.isInteger(value) ||
            Array.isArray(value);

        if (!validTypes) return false;

        const match = value.toString().match(/\d/g);
        const numbers = Array.isArray(match) ? match.map(Number) : [];

        if (numbers.length !== 14) return false;

        const items = [...new Set(numbers)];
        if (items.length === 1) return false;

        const calc = x => {
            const slice = numbers.slice(0, x);
            let factor = x - 7;
            let sum = 0;

            for (let i = x; i >= 1; i--) {
                const n = slice[x - i];
                sum += n * factor--;
                if (factor < 2) factor = 9;
            }

            const result = 11 - (sum % 11);

            return result > 9 ? 0 : result;
        };

        const digits = numbers.slice(12);

        const digit0 = calc(12);
        if (digit0 !== digits[0]) return false;

        const digit1 = calc(13);
        return digit1 === digits[1];
    }

    static validaCpf(cpf) {
        cpf = cpf.replace(/\D/g, "");
        if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
        var result = true;
        [9, 10].forEach(function (j) {
            var soma = 0,
                r;
            cpf
                .split(/(?=)/)
                .splice(0, j)
                .forEach(function (e, i) {
                    soma += parseInt(e) * (j + 2 - (i + 1));
                });
            r = soma % 11;
            r = r < 2 ? 0 : 11 - r;
            if (r != cpf.substring(j, j + 1)) result = false;
        });
        return result;
    }

    static emailValido(email) {
        const regexEmail = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;
        if (regexEmail.test(email)) {
            return true;
        }
        return false;
    }

    static rgValido(rg) {
        if (!rg) {
            return false;
        }
        return /\d/.test(rg);
    }
}
