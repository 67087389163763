export default class DataUtil {

    static desformataData(data) {
        if (!data) {
            return;
        }
        let date = new Date();
        if (typeof data == "object") {
            return data;
        }
        date.setFullYear(data.substring(0, 4));
        date.setMonth(data.substring(5, 7) - 1);
        date.setDate(data.substring(8, 10));
        return date;
    }

    //yyyy-mm-ddTHH:mm:ss
    static desformataDataTempo(data) {
        if (!data) {
            return;
        }
        let date = new Date();
        if (typeof data == "object") {
            return data;
        }
        date.setFullYear(data.substring(0, 4));
        date.setMonth(data.substring(5, 7) - 1);
        date.setDate(data.substring(8, 10));
        date.setHours(data.substring(11, 13));
        date.setMinutes(data.substring(14, 16));
        return date;
    }

    static formataData(data) {
        if (!data) {
            return "";
        }
        const tempData = this.desformataData(data);
        const dia = String(tempData.getDate()).padStart(2, "0");
        const mes = String(tempData.getMonth() + 1).padStart(2, "0");
        const ano = tempData.getFullYear();
        return `${ano}-${mes}-${dia}`;
    }

    // ano-mess
    static formataDataAnoMes(data) {
        if (!data) {
            return "";
        }
        const tempData = this.desformataData(data);
        const dia = String(tempData.getDate()).padStart(2, "0");
        const mes = String(tempData.getMonth() + 1).padStart(2, "0");
        const ano = tempData.getFullYear();
        return `${ano}-${mes}`;
    }


    //02 de Janeiro de 2022
    static formataDataPorExtenso(data) {
        if (!data) {
            return "";
        }
        const tempData = this.desformataData(data);
        const dia = String(tempData.getDate()).padStart(2, "0");
        const mes = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ][tempData.getMonth()];
        const ano = tempData.getFullYear();
        return `${dia} de ${mes} de ${ano}`;
    }

    static formataLocalDateTime(data) {
        if (!data) {
            return "";
        }
        const tempData = this.desformataData(data);
        const dia = String(tempData.getDate()).padStart(2, "0");
        const mes = String(tempData.getMonth() + 1).padStart(2, "0");
        const ano = tempData.getFullYear();
        const hours = String(tempData.getHours()).padStart(2, "0");
        const minutes = String(tempData.getMinutes()).padStart(2, "0");
        return `${ano}-${mes}-${dia}T${hours}:${minutes}`;
    }

    static formataData2(data) {
        if (!data) {
            return "";
        }

        const tempData = this.desformataData(data);
        const dia = String(tempData.getDate()).padStart(2, "0");
        const mes = String(tempData.getUTCMonth() ).padStart(2, "0");
        const ano = tempData.getFullYear();
        return `${data.substring(8, 10)}/${data.substring(5, 7)}/${data.substring(0, 4)}`;
    }

    static formataData3(data) {
        if (!data) {
            return "";
        }

        const meses = [
            "janeiro", "fevereiro", "março", "abril", "maio", "junho",
            "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
        ];

        const tempData = new this.desformataData(data);
        const dia = String(tempData.getDate()).padStart(2, "0");
        const mesExtenso = meses[tempData.getMonth()];
        const ano = tempData.getFullYear();

        return `${dia} de ${mesExtenso} de ${ano}`;
    }

    static formataDataTempo(data) {
        if (!data) {
            return "";
        }
        const tempData = this.desformataDataTempo(data);
        const dia = String(tempData.getDate()).padStart(2, "0");
        const mes = String(tempData.getMonth() + 1).padStart(2, "0");
        const ano = tempData.getFullYear();
        const hours = String(tempData.getHours()).padStart(2, "0");
        const minutes = String(tempData.getMinutes()).padStart(2, "0");
        return `${dia}/${mes}/${ano} ${hours}:${minutes}`;
    }

    static formataTempo(data) {
        if (!data) {
            return "";
        }
        const tempData = this.desformataDataTempo(data);
        const hours = String(tempData.getHours()).padStart(2, "0");
        const minutes = String(tempData.getMinutes()).padStart(2, "0");
        return `${hours}:${minutes}`;
    }
}
