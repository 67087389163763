import React, {useEffect} from "react";
import AuthService from "../../services/auth.service";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {BlockuiService} from "../../services/blockui.service";

export default function Logout() {
    const authService = new AuthService();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const user = AuthService.getUser();
        AuthService.limpar();
        if (!user) {
            history.push('/public/login');
            return;
        }
        const authInfo = AuthService.getAuthInfo();
        dispatch(BlockuiService.start);
        authService.logout(authInfo).then(res => {
            history.push('/public/login');
            dispatch(BlockuiService.stop);
        }, err => {
            history.push('/public/login');
            dispatch(BlockuiService.stop);

        });


    }, []);


    return (
        <></>
    );
}
