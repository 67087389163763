import React, {useEffect} from "react";
import '../style.scss'
import {Button} from "primereact/button";

export default function CheckinTipoAtendimento(props) {
    useEffect(() => {
    }, []);

    return (
        <>
            <div className="checkin-card">
                <h3>Indique qual o tipo de atendimento você necessita:</h3>
                <div className="checkin-ie-tipoAtendimento">
                    <Button
                        label="Dor no peito / Infarto"
                        type="button"
                        onClick={() => {
                            props.controller.selectIeTipoAtendimento(11)
                        }}
                        className="button-ie-tipo-atendimento"
                    />
                    <Button
                        label="AVC / Derrame"
                        type="button"
                        onClick={() => {
                            props.controller.selectIeTipoAtendimento(13)
                        }}
                        className="button-ie-tipo-atendimento"
                    />
                    <Button
                        label="Prioritário > 80"
                        type="button"
                        onClick={() => {
                            props.controller.selectIeTipoAtendimento(33)
                        }}
                        className="button-ie-tipo-atendimento"
                    />
                    <Button
                        label="Prioritário"
                        type="button"
                        onClick={() => {
                            props.controller.selectIeTipoAtendimento(34)
                        }}
                        className="button-ie-tipo-atendimento"
                    />
                    <Button
                        label="Clínica médica"
                        type="button"
                        onClick={() => {
                            props.controller.selectIeTipoAtendimento(38)
                        }}
                        className="button-ie-tipo-atendimento"
                    />
                    <Button
                        label="Ortopedia"
                        type="button"
                        onClick={() => {
                            props.controller.selectIeTipoAtendimento(39)
                        }}
                        className="button-ie-tipo-atendimento"
                    />
                </div>
            </div>
        </>
    );
}
