import React from "react";
import {DataPipe} from "../../../vendors/internalComponents/Pipe/Pipe";
import {ToastService} from "../../services/utils/toast.service";

export default function ValidaData(props) {

    const onClick = (row) => {
        if (row.real) {
            props.setEstado(1);
            return;
        }
        ToastService.showError('Data selecionada inválida');
    }

    const dataTemplate = (row, key) => {
        return (
            <div key={key} onClick={() => onClick(row)}>
                <label>{DataPipe(row.dtNascimento)}</label>
            </div>
        );
    }

    return (
        <>
            <div className="public-login-container">
                <div className="public-input-group">
                    <h6 className="public-informe">
                        Escolha abaixo sua data de nascimento
                    </h6>
                </div>
                <div className="dt-nascimento-wrapper">
                    {
                        props.lista &&
                        props.lista.length > 0 &&
                        props.lista.map((m, i) => dataTemplate(m, i))
                    }
                </div>
            </div>

        </>
    );
}
