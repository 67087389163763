export default class MessageUtil {
    static CAMPO_OBRIGATORIO = "Preencha os campos obrigatórios";

    static OPERACAO_REALIZADA_SUCESSO = "Operação realizada com sucesso.";

    static FALHA_REQUISICAO = "Falha na requisição.";

    static NENHUM_ANEXO_SELECIONADO = "Nenhum arquivo anexado.";

    static CPF_INVALIDO = "CPF inválido.";

    static I_M_NOT_TEAPOT = "I' AM NOT A TEAPOT";

    static ITEM_ADICIONADO = "Item adicionado com sucesso.";

    static ITEM_REMOVIDO = "Item removido com sucesso.";

    static ITEM_ALTERADO = "Item alterado com sucesso.";

    static EMAIL_INVALIDO = "E-mail inválido.";

    static LOGADO_SUCESSO = "Seja bem-vindo!";

    static CAMPO_LOGIN_OBRIGATORIO =
        "Preencha o campo e-mail/cpf para prosseguir com esta ação";

    static CAMPO_SENHA_OBRIGATORIO =
        "Preencha o campo senha para prosseguir com esta ação";

    static CAMPO_DATA_NASCIMENTO_OBRIGATORIO =
        "Preencha o campo data de nascimento para prosseguir com esta ação";

    static USUARIO_NAO_ENCONTRADO = "Usuário não encontrado";

    static USUARIO_JA_CADASTRADO = "Usuário já cadastrado";

    static FALHA_AO_ENVIAR_TOKEN = "Falha ao enviar token";

    static TOKEN_ENVIADO_SUCESSO = "Token enviado com sucesso";

    static CONFIRMAR_AGENDAMENTO_SUCESSO = "Agendamento confirmado";

    static CONFIRMAR_AGENDAMENTO_FALHA =
        "Não foi possível executar o agendamento";

    static CONFIRMAR_TODOS_AGENDAMENTO_SUCESSO = "Agendamentos confirmados";

    static CONFIRMAR_TODOS_AGENDAMENTO_FALHA =
        "Não foi possível executar os agendamentos";

    static REVERTER_AGENDAMENTO_SUCESSO = "Agendamento desconfirmado";

    static REVERTER_AGENDAMENTO_FALHA = "Não foi possível desfazer o agendamento";

    static REVERTER_TODOS_AGENDAMENTO_SUCESSO = "Agendamentos desconfirmados";

    static REVERTER_TODOS_AGENDAMENTO_FALHA =
        "Não foi possível desfazer os agendamentos";

    static CANCELAR_AGENDAMENTO_SUCESSO = "Agendamento cancelado";

    static CANCELAR_AGENDAMENTO_FALHA = "Não foi possível cancelar o agendamento";

    static CANCELAR_TODOS_AGENDAMENTO_SUCESSO = "Agendamentos cancelados";

    static CANCELAR_TODOS_AGENDAMENTO_FALHA =
        "Não foi possível cancelar os agendamentos";

    static CHECKIN_REALIZADO_SUCESSO = "Check-in realizado com sucesso";

    static FALHA_CHECKIN = "Não foi possível finalizar o Check-in";

    static DOCUMENTO_ENVIO_SUCESSO = "Documento enviado com sucesso";

    static CARTEIRINHA_ENVIO_SUCESSO = "Carteirinha enviada com sucesso";

    static PEDIDO_ENVIO_SUCESSO = "Pedido médico enviado com sucesso";
}
