/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, {useEffect} from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {Routes} from "./Routes";
import {I18nProvider} from "../vendors/_metronic/i18n";
import {LayoutSplashScreen} from "../vendors/_metronic/layout";
import {addLocale, locale} from "primereact/api";
import Environment from "../environments/environment";
import ReactGA from "react-ga4";

export default function App({store, persistor, basename}) {
    addLocale("pt-br", {
        firstDayOfWeek: 0,
        dayNames: [
            "Domingo",
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sábado"
        ],
        dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ],
        monthNamesShort: [
            "jan",
            "fev",
            "mar",
            "abr",
            "mai",
            "jun",
            "jul",
            "ago",
            "set",
            "out",
            "nov",
            "dez"
        ],
        today: "Hoje",
        clear: "Limpar"
    });

    useEffect(() => {
        if (Environment.env === "prd") {
            ReactGA.initialize(Environment.tag);
        }
    }, []);

    locale("pt-br");
    return (
        /* Provide Redux store */

        <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen/>}>
                {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                <React.Suspense fallback={<LayoutSplashScreen/>}>
                    {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                    <BrowserRouter basename={basename}>
                        {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                        {/*<MaterialThemeProvider>*/}
                        {/* Provide `react-intl` context synchronized with Redux state.  */}
                        <I18nProvider>
                            {/* Render routes with provided `Layout`. */}
                            <Routes/>
                        </I18nProvider>
                        {/*</MaterialThemeProvider>*/}
                    </BrowserRouter>
                </React.Suspense>
            </PersistGate>
        </Provider>
    );
}
