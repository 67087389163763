import React from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";

export default function ConfirmationDialog(props) {
    function renderFooter() {
        return (
            <div>
                <Button
                    label="Não"
                    type="button"
                    icon="pi pi-times"
                    onClick={() => props.reject()}
                    className="p-button-text"
                />
                <Button
                    type="button"
                    label="Sim"
                    icon="pi pi-check"
                    onClick={() => props.accept()}
                />
            </div>
        );
    }

    return (
        <Dialog
            header="Confirmação"
            visible={props.visible}
            modal
            baseZIndex={6000}
            closable={false}
            style={{width: "350px"}}
            footer={renderFooter()}
            onHide={() => props.reject()}
        >
            <div className="confirmation-content">
                <i
                    className="pi pi-exclamation-triangle p-mr-3"
                    style={{fontSize: "2rem"}}
                />
                <span>{props.text ? props.text : 'Confirma essa operação?'}</span>
            </div>
        </Dialog>
    );
}
