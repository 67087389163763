import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {ToastService} from "../../services/utils/toast.service";
import {CadastroService} from "../../services/common/cadastro.service";
import {useDispatch} from "react-redux";
import {BlockuiService} from "../../services/blockui.service";
import MessageUtil from "../../shared/utils/MessageUtil";
import Countdown from "react-countdown";
import {EmptyComponent} from "../../../vendors/internalComponents/EmptyComponent/EmptyComponent";
import {ConditionalComponent} from "../../../vendors/internalComponents/ConditionalComponent/ConditionalComponent";

export default function ValidaToken(props) {
    const [codigo, setCodigo] = useState('');
    const cadastroService = new CadastroService();
    const [expirado, setExpirado] = useState(false);
    const dispatch = useDispatch();
    let validar = () => {
        if (expirado) {
            ToastService.showWarn('Token expirado');
            return;
        }

        const tokenObj = {...props.token};
        tokenObj.cdToken = codigo;
        dispatch(BlockuiService.start);
        cadastroService.validar(tokenObj).then(res => {
            dispatch(BlockuiService.stop);
            ToastService.showSuccess('Token validado com sucesso');
            props.validado();
        }, err => {
            ToastService.showError('Token inválido');
            dispatch(BlockuiService.stop);
        });
    };


    useEffect(() => {
        setExpirado(true);
        setTimeout(() => {
            setExpirado(false);
        }, 500);
    }, [props.dataExpiracao]);

    let reenvia = (formaEnvio) => {
        dispatch(BlockuiService.start);
        cadastroService.sendToken({formaEnvio: formaEnvio, cdPessoaFisica: props.cdPessoaFisica}).then(res => {
            ToastService.showSuccess(MessageUtil.TOKEN_ENVIADO_SUCESSO);
            let date = new Date();
            date = new Date(date.getTime() + 2 * 60000).getTime()
            dispatch(BlockuiService.stop);
            props.setDataExpiracao(date);
            props.setToken(res.data);
            dispatch(BlockuiService.stop);
            props.setFormaEnvio(formaEnvio);
            setExpirado(true);
            setTimeout(() => {
                setExpirado(false);
            }, 500);
        }, err => {
            setExpirado(true);
            ToastService.showError(MessageUtil.FALHA_AO_ENVIAR_TOKEN);
            dispatch(BlockuiService.stop);
        });
    }

    const renderer = ({hours, minutes, seconds, completed}) => {
        if (completed) {
            return <h6 className="public-informe">Token Expirado</h6>
        } else {
            // Render a countdown
            return (
                <h6 className="public-informe">
                    Código expira em {(minutes + '').padStart(2, '0')}:{(seconds + '').padStart(2, '0')}
                </h6>
            );
        }
    };


    return (
        <>
            <div className="public-login-container">
                <div className="public-input-group">
                    <h6 className="public-informe">
                        Enviamos o código para
                        o {props.formaEnvio == 'sms' ? 'celular: ' + props?.pessoa?.nrTelefoneCelular : 'e-mail: ' + props?.pessoa?.dsEmail}
                    </h6>
                </div>
                <div className="public-input-group" style={{marginTop: '50px'}}>
                    <label className="public-icon">
                        <i className="fas fa-key "></i>
                    </label>
                    <InputText maxLength={100}
                               value={codigo} onChange={(e) => setCodigo(e.target.value)}
                               className="public-input"/>
                </div>
                <div className="public-input-group">
                    {
                        !expirado ?
                            <Countdown renderer={renderer}
                                       autoStart={true}
                                       onComplete={() => {
                                           ToastService.showWarn('Token expirado');
                                       }}
                                       zeroPadTime={2} date={props.dataExpiracao}/>
                            :
                            <h6 className="public-informe">Token Expirado</h6>
                    }


                    {/*<h6 className="public-informe">*/}
                    {/*    Código expira em {expiraMin}:{expiraSeg}*/}
                    {/*</h6>*/}
                </div>
                <div className="public-button-group" style={{marginTop: '30px'}}>
                    <Button
                        onClick={(e) => validar()}
                        type="text"
                        disabled={expirado}
                        className="public-button public-blue-button">Validar</Button>
                </div>

                <div className="public-input-group" style={{marginTop: '30px'}}>
                    <h6 className="public-informe">
                        Não recebeu o código?
                    </h6>
                </div>
                <div className="public-button-group" style={{marginTop: '20px'}}>
                    <Button
                        onClick={(e) => reenvia(props.formaEnvio == 'sms' ? 'sms' : 'email')}
                        type="text"
                        className="public-button public-white-button">Reenviar Código</Button>
                </div>
                <div className="public-button-group" style={{marginTop: '20px'}}>
                    <Button
                        onClick={(e) => reenvia(props.formaEnvio == 'sms' ? 'email' : 'sms')}
                        type="text"
                        className="public-button public-white-button">Enviar
                        por {props.formaEnvio == 'sms' ? 'E-mail' : 'SMS'}</Button>
                </div>
                <div className="public-button-group" style={{marginTop: '30px',}}>
                    <Link to="/public/login">
                        <Button className="public-button public-blue-button">Voltar</Button>
                    </Link>
                </div>
            </div>

        </>
    );
}
