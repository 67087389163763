import React from 'react';
import {Dropdown} from "primereact/dropdown";

export default function MonthTemplate(e) {
    return (
        <>
            <Dropdown appendTo='self' value={e.value} options={e.options} onChange={(event) => {
                e.onChange(event.originalEvent, event.value);
            }} style={{lineHeight: 1}}/>
        </>
    );
}

export function YearTemplate(e) {
    return <Dropdown appendTo='self' value={e.value} options={e.options} onChange={(event) => {
        e.onChange(event.originalEvent, event.value);
    }} className="p-ml-2" style={{lineHeight: 1}}/>;
}
