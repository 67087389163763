import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

import './extratoDeConta.scss';

import TituloAzul from "../../../vendors/internalComponents/TituloAzul/TituloAzul";
import {Card} from "primereact/card";

import AuthService from "../../services/auth.service";
import ExtratoDeContaService from "./extratoDeConta.service";
import {BlockuiService} from "../../services/blockui.service";

import DataUtil from "../../shared/utils/DataUtil";
import {DataPipe, TempoPipe, UnidadePipe} from "../../../vendors/internalComponents/Pipe/Pipe";
import {EmptyComponent} from "../../../vendors/internalComponents/EmptyComponent/EmptyComponent";
import AnalyticsUtil from "../../shared/utils/AnalyticsUtil";

export default function ExtratoDeConta() {
    let user = AuthService.getUser();
    const [resultados, setResultados] = useState([]);
    const [resultado, setResultado] = useState();
    const [extrato, setExtrato] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const extratoDeContaService = new ExtratoDeContaService();

    useEffect(() => {
        AnalyticsUtil.acessoAPagina('extrato_conta');
        if (user.cdPessoaFisica) {
            extratoDeContaService.dispatch(BlockuiService.start);
            extratoDeContaService.getResultados(user.cdPessoaFisica).then(res => {
                extratoDeContaService.dispatch(BlockuiService.stop);
                setResultados(res.data);
            });
        }
    }, []);

    function renderResultado(resultado) {
        if (resultado) {
            return (
                <tr>
                    <td>{DataUtil.formataDataTempo(resultado.dtEntrada)}</td>
                    <td>{resultado.nrAtendimento}</td>
                    <td>R$ {resultado.valorTotal}</td>
                    {/* <td>R$ {resultado.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td> */}
                    <td onClick={() => {
                        setResultado(resultado)
                        getDetalhes(resultado)
                        setShowDialog(true)
                    }}>
                        <i className="fas fa-eye extrato-exibir"/>
                    </td>
                </tr>
            )
        }
    }

    function getDetalhes(resultado) {
        extratoDeContaService.detalhar(resultado.nrAtendimento).then(res => {
            setExtrato(res.data);
        });
    }

    function renderConsulta(consulta) {
        return (
            <tbody>
            <tr>
                <td>{consulta?.dsItem}</td>
                <td>{consulta?.qtItem}</td>
            </tr>
            </tbody>
        );
    }

    function renderMedicamento(medicamento) {
        return (
            <tbody>
            <tr>
                <td>{medicamento?.dsItem}</td>
                <td>{medicamento?.qtItem}</td>
            </tr>
            </tbody>
        );
    }

    function renderProcedimento(procedimento) {
        return (
            <tbody>
            <tr>
                <td>{procedimento?.dsItem}</td>
                <td>{procedimento?.qtItem}</td>
            </tr>
            </tbody>
        );
    }

    return <>
        <div className="extrato-wrapper">
            <div className="extrato-item">
                <Card>
                    <table className="content-table desktop-table">
                        <thead>
                        <tr>
                            <th>Data</th>
                            <th>Atendimento</th>
                            <th>Valor</th>
                            <th>Ações</th>
                        </tr>
                        </thead>
                        <tbody>
                        {resultados.map(resultado => renderResultado(resultado))}
                        </tbody>
                    </table>


                    <table className="content-table mobile-table">
                        <tbody>
                        {resultados.map((resultado, index) => <tr key={index}>
                            <td>
                                <span className="column-mobile">
                                    <b>Data: </b>
                                    {DataUtil.formataDataTempo(resultado.dtEntrada)}
                                </span>
                                <span className="column-mobile">
                                    <b>Atendimento: </b>
                                    {resultado.nrAtendimento}
                                </span>
                                <span className="column-mobile">
                                    <b>Valor: </b>
                                    R$ {resultado.valorTotal}
                                </span>
                                <span className="column-mobile">
                                    <b>Ações: </b>
                                  <i className="fas fa-eye extrato-exibir" onClick={() => {
                                      setResultado(resultado)
                                      getDetalhes(resultado)
                                      setShowDialog(true)
                                  }}/>
                                </span>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>

                </Card>
            </div>
        </div>

        <Dialog onHide={() => setShowDialog(false)}
                blockScroll={true}
                header="Detalhes" closable={false}
                draggable={false} visible={showDialog} className="modal-termo" footer={
            <>
                <Button
                    type="button"
                    label="Fechar"
                    onClick={() => setShowDialog(false)}
                />
            </>
        }>
            <div className="detalhes-extrato-wrapper">
                <div className="detalhes-extrato-dados">
                    <Card id="extrato-card">
                        <div className="extrato-dados-wrapper">
                            <div className="extrato-dados-item">
                                <div className="extrato-dados-subitem">
                                    <h3
                                        className="extrato-title">Data de entrada</h3>
                                    <h4 className="extrato-subtitle">{DataUtil.formataDataTempo(resultado?.dtEntrada)}</h4>
                                </div>

                                <div className="extrato-dados-subitem">
                                    <h3
                                        className="extrato-title">Data de saída</h3>
                                    <h4 className="extrato-subtitle">{DataUtil.formataDataTempo(resultado?.dtAlta)}</h4>
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop: '5%'}}/>
                        <TituloAzul titulo="Consultas"/>
                        <DataTable value={extrato && extrato.consulta && extrato.consulta}
                                   emptyMessage="Não há registro de consultas">
                            <Column field="dsItem" header="Consulta"/>
                            <Column field="qtItem" header="Quantidade"/>
                        </DataTable>

                        <div style={{marginTop: '5%'}}/>
                        <TituloAzul titulo="Medicamentos"/>
                        <DataTable value={extrato && extrato.medicamento && extrato.medicamento}
                                   emptyMessage="Não há registro de medicamentos">
                            <Column field="dsItem" header="Medicamento"/>
                            <Column field="qtItem" header="Quantidade"/>
                        </DataTable>

                        <div style={{marginTop: '5%'}}/>
                        <TituloAzul titulo="Procedimentos"/>
                        <DataTable value={extrato && extrato.procedimentos && extrato.procedimentos}
                                   emptyMessage="Não há registro de procedimentos">
                            <Column field="dsItem" header="Procedimento"/>
                            <Column field="qtItem" header="Quantidade"/>
                        </DataTable>

                    </Card>
                </div>
            </div>
        </Dialog>
    </>;
}
