import React, {useEffect, useState} from "react";
import {Calendar} from "primereact/calendar";
import Container from "../../../components/Container/Container";
import Field from "../../../components/Field/Field";
import Horario from "./Horario";
import {UnidadePipe} from "../../../../vendors/internalComponents/Pipe/Pipe";
import DataUtil from "../../../shared/utils/DataUtil";
import {Dropdown} from "primereact/dropdown";
import {ConditionalComponent} from "../../../../vendors/internalComponents/ConditionalComponent/ConditionalComponent";
import {If} from "../../../../vendors/internalComponents/If/If";

export default function DataAgendamento(props) {
    const medico = props.controller.cdMedico;
    const especialidade = props.controller.especialidade;
    const [month, setMonth] = useState((new Date()).getMonth() + 1);
    const [year, setYear] = useState((new Date()).getFullYear());
    const anos = props.controller.getAnos();
    const meses = props.controller.getMonths();

    useEffect(() => {
        props.controller.obterDiasAgenda(DataUtil.formataDataAnoMes(new Date()));
        if (props.controller.dtAgendamento) {
            props.controller.gerarHorario(props.controller.dtAgendamento);
        }
    }, []);

    const formataMes = (mes) => {
        let formatado = mes + 1;
        return (formatado + '').padStart(2, '0');
    }

    const monthNavigatorTemplate = (e) => {
        return <Dropdown appendTo="self" value={e.value} options={e.options} onChange={(event) => {
            setMonth(event.value + 1);
            props.controller.obterDiasAgenda(`${year}-${formataMes(event.value)}`, e.onChange, event);
        }} style={{lineHeight: 1}}/>;
    }

    const yearNavigatorTemplate = (e) => {
        return <Dropdown appendTo="self" value={e.value} options={anos} onChange={(event) => {
            setYear(event.value);
            props.controller.obterDiasAgenda(`${year}-${formataMes(month - 1)}`, e.onChange, event);
        }} className="p-ml-2" style={{lineHeight: 1}}/>;
    }
    const formataDia = (dia) => {
        return (dia + '').padStart(2, '0');
    }
    const dateTemplate = (date) => {
        const controller = props.controller;
        const data = `${date.year}-${formataMes(date.month)}-${formataDia(date.day)}`;
        return (
            <>
                <ConditionalComponent
                    condition={!controller.erroConsistencia && (controller.diasAgendaList.indexOf(data) >= 0)}
                    positive={<label><strong>{date.day}</strong></label>}
                    negative={<label className="p-disabled">{date.day}</label>}
                />
                <If condition={!controller.erroConsistencia && controller.diasAgendaList.indexOf(data) >= 0}>
                    <label style={{
                        background: 'green',
                        width: '5px',
                        height: '5px',
                        borderRadius: '5px',
                        marginRight: '2px',
                        marginLeft: '2px'
                    }}></label>
                </If>
            </>
        );
    }


    return (
        <>
            <Container>
                <Field col={12} lg={8} breakLine={true}>
                    <h3 className="titulo-primario">
                        Verifique os dias e horários disponíveis para o seu agendamento:
                    </h3>
                </Field>
                <Field col={12} xl={6} className="text-left" breakLine={true}>
                    <div className="dados-funcionario">
                        <h3>{medico.nmMedico} - {especialidade.dsEspecialidade} - {medico.cdEstabelecimento === 1 ? 'Unidade Paraíso' : 'Unidade Cidade Jardim'}</h3>
                    </div>
                </Field>
                <Field id="agendamento-calendario" className="agendamento-calendario-wrapper" md={12} sm={12} lg={12}
                       xl={6}>
                    <Calendar
                        monthNavigatorTemplate={monthNavigatorTemplate}
                        yearNavigatorTemplate={yearNavigatorTemplate}
                        monthNavigator
                        dateTemplate={dateTemplate}
                        disabled={props.controller.erroConsistencia}
                        yearNavigator
                        yearRange="2000:2100"
                        showOtherMonths={true}
                        onChange={(e) => props.controller.changeDataAgendamento(e.value)}
                        value={props.controller.dtAgendamento}
                        minDate={props.controller.data}
                        inline/>
                </Field>
                <If condition={!props.controller.erroConsistencia}>

                    <Field md={12} sm={12} lg={12} xl={6} className="d-flex p-flex-column justify-content-center ">
                        <Horario controller={props.controller}/>
                    </Field>
                </If>
                <If condition={props.controller.erroConsistencia}>
                    <Field md={12} sm={12} lg={12} xl={6} className="d-flex p-flex-column justify-content-center text-justify">
                        <label style={{color: 'var(--danger)'}}>
                            Para os dados de convênio selecionados não é permitido agendamento, por favor entre em
                            contato com a central de agendamento através do número (11) 3889-3939.
                            Caso seja funcionário por favor entrar em contato com o Cuidar hcor.
                        </label>
                    </Field>
                </If>
            </Container>
        </>
    );
}
