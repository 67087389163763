import {AbstractService} from "../../shared/abstracts/abstract.service";
import {extratoApi} from "../../services/api";

export default class ExtratoDeContaService extends AbstractService {
    constructor() {
        super("", "", extratoApi);
    }

    getResultados(cdPessoa) {
        return this.api.get(`${this.rota}/conta-particular/${cdPessoa}`);
    }

    detalhar(nrAtendimento) {
        return this.api.get(`${this.rota}/detalhe-atendimento/${nrAtendimento}`);
    }
}
