/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {Suspense, useEffect, useState} from "react";
import BlockUi from "react-block-ui";
import {useDispatch, useSelector} from "react-redux";
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation
} from "react-router-dom";
import BasePage from "./BasePage";
import PublicPage from "./public/PublicPage";
import {LayoutSplashScreen} from "../vendors/_metronic/layout";
import {Toast} from "primereact/toast";
import {ToastService} from "./services/utils/toast.service";
import AuthService from "./services/auth.service";
import {UserService} from "./services/user.service";
import {BlockuiService} from "./services/blockui.service";
import {CadastroService} from "./services/common/cadastro.service";
import {TokenService} from "./services/token.service";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import ReactGA from "react-ga4";
import Environment from "../environments/environment";

export function Routes() {
    let user = AuthService.getUser();
    const state = useSelector(state => state);
    let location = useLocation();
    const authService = new AuthService();
    const history = useHistory();
    const dispatch = useDispatch();
    const usuarioService = new CadastroService();
    const [showDialog, setShowDialog] = useState(false);
    const [permSmsEmail, setPermSmsEmail] = useState(true);
    const [termo, setTermo] = useState();

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (window.location.href.indexOf("login") < 0) {
            setTimeout(() => {
                if (
                    AuthService.getUser() &&
                    window.location.href.indexOf("public") < 0
                ) {
                    const user = AuthService.getUser();
                    authService.check().then(res => {
                        if (!user.nrSeqFoto) {
                            dispatch(
                                UserService.changeUser({...user, username: user.nmPessoaFisica})
                            );
                        } else {
                            usuarioService.obterFoto(user.nrSeqFoto).then(res => {
                                usuarioService.dispatch(BlockuiService.stop);
                                dispatch(
                                    UserService.changeUser({
                                        ...user,
                                        username: user.nmPessoaFisica,
                                        foto: res.data
                                    })
                                );
                            });
                        }
                    });
                    usuarioService.obterTermos(user.idUsuario).then(res => {
                        if (
                            res.data &&
                            res.data.length > 0 &&
                            !res.data[0].dtConsentimento
                        ) {
                            setShowDialog(true);
                            const conteudo = document.getElementById("conteudo");
                            conteudo.innerHTML = res.data[0].dsTermo;
                            setTermo(res.data[0]);
                        }
                    });
                } else if (window.location.href.indexOf("public") < 0) {
                    history.push("/public/logout");
                }
            }, 1000);
        }
    }, [location]);

    function renderFooter() {
        return <div></div>;
    }

    function changePermissao() {
        const userT = AuthService.getUser();
        const novoEstado = userT.permSmsEmail == "S" ? "N" : "S";
        const notificacao = {
            cdPessoaFisica: userT.cdPessoaFisica,
            situacao: novoEstado
        };
        usuarioService.dispatch(BlockuiService.start);
        usuarioService.permitirNotificacao(notificacao).then(res => {
            usuarioService.dispatch(BlockuiService.stop);
            setPermSmsEmail(novoEstado == "S");
            TokenService.saveUser({...user, permSmsEmail: novoEstado});
        });
    }

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Toast
                baseZIndex={6000}
                ref={el => ToastService.init(el)}
                style={{paddingTop: 40}}
            />
            <Switch>
                <Route exact path="/public/*" component={PublicPage}/>
                <Route exact path="/*" component={BasePage}/>
                <Redirect from="*" to="/public/login"/>
            </Switch>
            <BlockUi
                tag="div"
                blocking={state.blockui.blocked}
                message={state.blockui.message}
            />
            <Dialog
                header="Você possui um termo pendente"
                draggable={false}
                blockScroll={true}
                closable={false}
                visible={showDialog}
                className="modal-termo"
                footer={
                    <div className="footer-termo">
                        <div className="div-termo">
                            <Checkbox
                                className="p-button-text"
                                onChange={() => changePermissao()}
                                checked={permSmsEmail}
                            />
                            <h6>
                                Receber notificações de nossos serviços e comunicações via
                                e-mail/SMS
                            </h6>
                        </div>
                        <>
                            <Button
                                label="Sair"
                                type="button"
                                icon="pi pi-times"
                                onClick={() => {
                                    setShowDialog(false);
                                    history.push("/public/logout");
                                }}
                                className="p-button-text"
                            />
                            <Button
                                type="button"
                                label="Assinar"
                                icon="pi pi-check"
                                onClick={() => {
                                    const user = AuthService.getUser();
                                    usuarioService.dispatch(BlockuiService.start);
                                    usuarioService
                                        .assinarTermo(user.idUsuario, termo.nrSeqTermo)
                                        .then(res => {
                                            setShowDialog(false);
                                            usuarioService.dispatch(BlockuiService.stop);
                                            const userT = AuthService.getUser();
                                            const novoEstado = permSmsEmail ? "S" : "N";
                                            const notificacao = {
                                                cdPessoaFisica: userT.cdPessoaFisica,
                                                situacao: novoEstado
                                            };
                                            usuarioService
                                                .permitirNotificacao(notificacao)
                                                .then(res => {
                                                    setPermSmsEmail(permSmsEmail);
                                                    TokenService.saveUser({
                                                        ...user,
                                                        permSmsEmail: novoEstado
                                                    });
                                                });
                                        });
                                }}
                            />
                        </>
                    </div>
                }
            >
                <div id="conteudo"></div>
            </Dialog>
        </Suspense>
    );
}
