import React, {useEffect, useState} from 'react';
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {InputMask} from "primereact/inputmask";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {ToastService} from "../../services/utils/toast.service";
import ValidacaoUtil from "../../shared/utils/ValidacaoUtil";
import {CadastroService} from "../../services/common/cadastro.service";
import DataUtil from "../../shared/utils/DataUtil";
import {RadioButton} from "primereact/radiobutton";

export default function NovoCadastro(props) {

    const [nrCpf, setNrCpf] = useState(props.nrCpf);
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [dtNascimento, setDtNascimento] = useState(props.dtNascimento);
    const [nrDddCelular, setNrDddCelular] = useState('');
    const [nrTelefoneCelular, setNrTelefoneCelular] = useState('');
    const [dsEmail, setDsEmail] = useState('');
    const [ieSexo, setIeSexo] = useState('');
    const cadastroService = new CadastroService();


    function cadastrar() {
        if (!validaCampos()) {
            return;
        }
        cadastroService.start();
        cadastroService.cadastrarPessoa({
            nmPessoaFisica: nome + ' ' + sobrenome,
            nrCpf: nrCpf.replace(/\D+/g, ''),
            dtNascimento: DataUtil.formataData(dtNascimento),
            nrDddCelular: nrDddCelular.replace(/\D+/g, ''),
            nrTelefoneCelular: nrTelefoneCelular.replace(/\D+/g, ''),
            dsEmail: dsEmail,
            ieSexo: ieSexo
        }).then(res => {
            props.doValidation(nrCpf, DataUtil.formataData(dtNascimento));
        }).catch(err => {
            cadastroService.stop();
            ToastService.showError('Falha ao cadastrar usuário.');
        });

    }

    function validaCampos() {
        if (!nome || !sobrenome || !nrCpf || !nrDddCelular || !nrTelefoneCelular ||
            !ieSexo || nrTelefoneCelular.length < 9 || !dtNascimento) {
            ToastService.showError('Preencha os campos obrigatórios');
            return false;
        }
        console.log(nrCpf);
        console.log(ValidacaoUtil.validaCpf(nrCpf));
        if (!ValidacaoUtil.validaCpf(nrCpf)) {
            ToastService.showError('CPF incorreto ou inválido.');
            return false;
        }
        if (!ValidacaoUtil.emailValido(dsEmail)) {
            ToastService.showError('E-mail inválido');
            return false;
        }
        return true;
    }


    return (
        <>
            <div className="public-login-container">
                <div className="public-input-group">
                    <h6 className="public-informe">
                        Novo cadastro
                    </h6>
                </div>
                <div className="public-input-group" style={{marginTop: '10px'}}>
                    <InputText maxLength={200}
                               value={nome} onChange={(e) => setNome(e.target.value)}
                               placeholder="Nome"
                               className="public-input"/>
                </div>
                <div className="public-input-group">
                    <InputText maxLength={200}
                               value={sobrenome} onChange={(e) => setSobrenome(e.target.value)}
                               placeholder="Sobrenome"
                               className="public-input"/>
                </div>
                <div className="public-input-group">
                    <div className="sexo-wrapper">

                        <label className="no-spaces">Sexo: </label>
                        <span>
                            <RadioButton inputId="masculino"
                                         onChange={() => setIeSexo('M')}
                                         checked={ieSexo === 'M'}
                                         className="no-spaces" name="masculino" value="M"/>
                            <label htmlFor="masculino" className="no-spaces">Masculino</label>
                        </span>
                        <span>
                            <RadioButton inputId="feminino"
                                         onChange={() => setIeSexo('F')}
                                         checked={ieSexo === 'F'}
                                         name="feminino" value="F"/>
                            <label htmlFor="feminino" className="no-spaces">Feminino</label>
                        </span>
                    </div>
                </div>
                <div className="public-input-group">
                    <InputMask maxLength={100}
                               autoClear={false}
                               unmask={true}
                               mask="999.999.999-99"
                               value={nrCpf} onChange={(e) => setNrCpf(e.target.value)}
                               placeholder="CPF"
                               className="public-input"/>
                </div>
                <div className="public-input-group">
                    <Calendar
                        value={dtNascimento} onChange={(e) => setDtNascimento(e.target.value)}
                        maxDate={new Date()}
                        placeholder="Data de nascimento"
                        mask="99/99/9999"
                        id="public-data-nascimento"
                        className="public-input"
                        dateFormat="dd/mm/yy"
                        monthNavigator yearNavigator yearRange="1900:2100"
                    />
                </div>
                <div className="public-input-group public-telefone-group">
                    <InputMask maxLength={100}
                               mask="(99)"
                               autoClear={false}
                               unmask={true}
                               value={nrDddCelular} onChange={(e) => setNrDddCelular(e.target.value)}
                               placeholder="DDD"
                               className="public-input public-ddd"/>

                    <InputMask maxLength={100}
                               autoClear={false}
                               unmask={true}
                               mask="99999-9999"
                               value={nrTelefoneCelular} onChange={(e) => setNrTelefoneCelular(e.target.value)}
                               placeholder="Celular"
                               className="public-input public-nr-telefone"/>
                </div>
                <div className="public-input-group">
                    <InputText maxLength={100}

                               value={dsEmail} onChange={(e) => setDsEmail(e.target.value)}
                               placeholder="E-mail"
                               className="public-input"/>
                </div>
                <div className="public-button-group" style={{marginTop: '60px'}}>
                    <Button
                        onClick={(e) => cadastrar(e)}
                        type="text"
                        className="public-button public-blue-button">Cadastrar</Button>
                </div>
                <div className="public-button-group" style={{marginTop: '30px', marginBottom: '50px'}}>
                    <Button className="public-button"
                            style={{
                                background: 'white',
                                border: '1px solid #A5A5A5',
                                color: '#A5A5A5'
                            }}
                            onClick={() => props.voltar()}
                    >
                        Voltar
                    </Button>
                </div>
            </div>
        </>
    );
}
