import React, {useEffect} from "react";
import {Button} from "primereact/button";
import '../style.scss';

export default function CheckinBullets(props) {
    const message = `Tire uma única foto do documento aberto (frente e verso)`


    return (
        <>

            {
                (props.controller.step !== 10) &&

                <div>
                    <div className="container-bullet">
                        <Button
                            disabled={props.controller.step === 0}
                            label={<label className="label-anterior">Anterior</label>}
                            type="button"
                            onClick={() => props.controller.previousStep()}
                            className="button-bullet"
                        />
                        <div className="div-bullets">
                            {
                                props.controller.bulletActive.map((_, index) => (
                                    <div key={index}
                                         className={props.controller.bulletActive[index] ? "bullet-active" : "bullet"}/>
                                ))
                            }
                        </div>
                        <Button
                            label={(props.controller.bulletActive.length - 1) !== props.controller.step ?
                                <label className="label-proximo">Próximo</label> :
                                <label className="label-proximo">Finalizar</label>}
                            type="button"
                            onClick={(props.controller.bulletActive.length - 1) !== props.controller.step ? () => props.controller.nextStep() : () => props.controller.finalizar()}
                            className="button-bullet"
                        />
                    </div>
                    {(props.controller.step === 3) &&
                        <div>
                            <label className="public-icon">
                                <div id="passar_mouse">
                                    <i className="fas fa-info-circle"
                                       style={{fontSize: 25, marginTop: 15, color: '#2364AA'}}/>
                                    <div id="mostrar" style={{fontSize: 16, marginTop: 15, color: '#2364AA'}}>
                                        {message}
                                    </div>
                                </div>
                            </label>
                        </div>
                    }
                </div>

            }

        </>
    )
}
