import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {toAbsoluteUrl} from "../../../_helpers";

export function Brand() {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            brandClasses: uiService.getClasses("brand", true),
            asideSelfMinimizeToggle: objectPath.get(
                uiService.config,
                "aside.self.minimize.toggle"
            ),
            headerLogo: uiService.getLogo(),
            headerStickyLogo: uiService.getStickyLogo()
        };
    }, [uiService]);

    const logoStyle = {
        width: "115px",
        marginTop: "20px",
        marginLeft: "-150px",
        zIndex: "0"
    };

    const brandStyle = {
        height: "100px",
        marginTop: "-20px"
    };

    return (
        <>
            <div
                className={`brand flex-column-auto ${layoutProps.brandClasses}`}
                style={brandStyle}
                id="kt_brand"
            >
                {layoutProps.asideSelfMinimizeToggle && (
                    <>
                        <button
                            className="brand-toggle btn btn-sm px-0"
                            id="kt_aside_toggle"
                            style={{zIndex: "2000"}}
                        >
              <span className="svg-icon svg-icon-xl" style={{zIndex: "2000"}}>
                <SVG
                    src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/MenuAberto.svg"
                    )}
                />
              </span>
                        </button>
                    </>
                )}

                <Link
                    to=""
                    onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    className="brand-logo"
                >
                    <img alt="logo" style={logoStyle} src={layoutProps.headerLogo}/>
                </Link>
            </div>
        </>
    );
}
