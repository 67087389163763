import React, {useEffect} from "react";
import QuestionarioController from "../controller/questionario.controller";
import Secao from "../components/Secao";

export default function Questionario() {
    const controller = new QuestionarioController();
    useEffect(() => {
        controller.init();
    }, []);

    return (
        <>
            {
                controller.secaoList.map(m => <Secao key={m} model={m} />)
            }
        </>
    );
}
