export class ToastService {
    static toast = undefined;

    static timeout = 5000;

    static init(toast) {
        this.toast = toast;
    }

    static showSuccess(message, timeout = ToastService.timeout) {
        this._show("success", message, timeout);
    }

    static showInfo(message, timeout = ToastService.timeout) {
        this._show("info", message, timeout);
    }

    static showWarn(message, timeout = ToastService.timeout) {
        this._show("warn", message, timeout);
    }

    static showError(message, timeout = ToastService.timeout) {
        this._show("error", message, timeout);
    }

    static _show(_severity, _message, timeout = ToastService.timeout) {
        this.toast.show({
            severity: _severity,
            detail: _message,
            life: timeout
        });
    }
}
