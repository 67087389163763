import React from "react";
import {ConditionalComponent} from "../../../vendors/internalComponents/ConditionalComponent/ConditionalComponent";

export default function Criterio(props) {
    return (
        <>
            <ConditionalComponent
                condition={props.condition}
                positive={
                    <i className="fas fa-check" style={{color: '#50AA73', marginRight: '10px'}}>
                    </i>
                }
                negative={
                    <i className="fas fa-times" style={{color: '#D25F78', marginRight: '10px'}}>
                    </i>
                }
            />
        </>
    );
}
