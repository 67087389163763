import React, {useEffect} from "react";
import '../style.scss'
import {Button} from "primereact/button";

export default function CheckinAtendimento(props) {
    useEffect(() => {
        props.controller.setTipoAtendimento('');
    }, []);

    return (
        <>
            <div className="checkin-card">
                <h3>Selecione o tipo de atendimento:</h3>
                <div className="tipos-checkin">
                    <Button
                        label={<h4>Convênio</h4>}
                        type="button"
                        onClick={() => {
                            props.controller.selectTipoAtendimento('C')
                        }}
                        className="btn-confirmar"
                    />

                    <hr/>

                    <Button
                        label={<h4>Particular</h4>}
                        type="button"
                        onClick={() => {
                            props.controller.selectTipoAtendimento('P')
                        }}
                        className="btn-confirmar"
                    />
                </div>
            </div>
        </>
    );
}
