import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    blocked: false
};

function changeBlockui(state = initialState, action) {
    switch (action.type) {
        case "start":
            return {blocked: true};
        case "stop":
            return {blocked: false};
        case "start-message":
            return {blocked: true, message: action.message};
        default:
            return state;
    }
}

export const reducer = persistReducer(
    {storage, key: "v709-demo1-auth", whitelist: ["blockui"]},
    changeBlockui
);
