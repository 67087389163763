import AbstractFormController from "../../../shared/abstracts/abstract-form.controller";
import QuestionarioService from "../service/questionario.service";
import {useState} from "react";

export default class QuestionarioController  extends AbstractFormController {


    constructor() {
        super();
        this.service = new QuestionarioService();

        [this.questionario, this.setQuestionario] = useState({});
        [this.secaoList, this.setSecaoList] = useState([]);
    }

    init() {
        super.init();
        this.obterQuestionario();
    }

    obterQuestionario() {
        this.start();
        this.service.obterQuestionario().then(res => {
            this.stop();
            this.setQuestionario(res.data);
            this.setSecaoList(res.data.secaoList);
        }).catch(this.defaultErrorCatch);
    }


}
