import AbstractController from "../../../shared/abstracts/abstract.controller";
import {useState} from "react";
import {BlockuiService} from "../../../services/blockui.service";
import {ToastService} from "../../../services/utils/toast.service";
import moment from "moment";
import AgendamentoService from "../../MeusAgendamentos/services/agendamento.service";
import AuthService from "../../../services/auth.service";
import CheckInService from "../services/checkIn.service";
import {useHistory} from "react-router-dom";
import DataUtil from "../../../shared/utils/DataUtil";

export default class CheckinStepsController extends AbstractController {
    static bulletParticular = [true, false, false, false, false];
    static bulletConvenio = [true, false, false, false, false, false];

    constructor(agendado = false, ieTipoCheckin) {
        super();
        this.agendado = agendado;

        [this.step, this.setStep] = useState(-1);
        [this.unidade, this.setUnidade] = useState(0);
        [this.tipoAtendimento, this.setTipoAtendimento] = useState("");

        [this.exame, this.setExame] = useState(null);
        [this.ieTipoCheckin, this.setIeTipoCheckin] = useState(ieTipoCheckin);

        [this.isParticular, this.setIsParticular] = useState(false);
        [this.isConvenio, this.setIsConvenio] = useState(false);

        [this.checkinDocumento, this.setCheckinDocumento] = useState("");
        [this.documento, this.setDocumento] = useState("");
        [this.possuiDocumento, this.setPossuiDocumento] = useState(false);

        [this.checkinConvenio, this.setCheckinConvenio] = useState("");
        [this.carteirinha, this.setCarteirinha] = useState("");
        [this.possuiCarteirinha, this.setPossuiCarteirinha] = useState(false);

        [this.pedido, this.setPedido] = useState("");
        [this.pedidos, this.setPedidos] = useState([]);
        [this.possuiPedido, this.setPossuiPedido] = useState(false);

        [this.dataSelect, this.setDataSelect] = useState();

        [this.minDate, this.setMinDate] = useState();
        [this.maxDate, this.setMaxDate] = useState();
        [this.bulletActive, this.setBulletActive] = useState(
            CheckinStepsController.bulletConvenio
        );

        this.agendamentoService = new AgendamentoService();
        this.checkinService = new CheckInService();
        this.user = AuthService.getUser();
        this.history = useHistory();

        this.hoje = new Date();
        this.dataFormatada = moment(this.hoje).format("YYYY-MM-DD");
        this.horaFormatada = moment(this.hoje).format("HH:mm");
        this.sysdateUm = moment(this.hoje)
            .add(24, "hour")
            .format("YYYY-MM-DD");
        this.sysdateDois = moment(this.hoje)
            .add(48, "hour")
            .format("YYYY-MM-DD");
        this.sysdateTres = moment(this.hoje)
            .add(72, "hour")
            .format("YYYY-MM-DD");
        this.sysdateQuatro = moment(this.hoje)
            .add(72, "hour")
            .format("YYYY-MM-DD");
    }

    selectUnidade(unidade) {
        this.setUnidade(unidade);
        this.next();
    }

    selectTipoAtendimento(tipoAtendimento) {
        this.setTipoAtendimento(tipoAtendimento);
        if (tipoAtendimento === "P") {
            this.setBulletActive([false, false, true, false, false]);
            this.setStep(3);
        } else {
            this.next(this.step);
        }
    }

    next() {
        const i = this.step;
        const nextIndex = i + 1;
        const newBullets = [...this.bulletActive];
        newBullets[i] = !newBullets[i];
        newBullets[nextIndex] = !newBullets[nextIndex];
        this.setBulletActive(newBullets);
        this.setStep(nextIndex);
        this.agendamentoService.dispatch(BlockuiService.stop);
    }

    previousStep() {
        const i = this.step;

        let previusIndex = i - 1;
        if (this.step === 5 && this.tipoAtendimento === "P") {
            previusIndex = i - 2;
        }

        const newBullets = [...this.bulletActive];
        newBullets[i] = !newBullets[i];
        newBullets[previusIndex] = !newBullets[previusIndex];
        this.setBulletActive(newBullets);
        this.setStep(previusIndex);
    }

    init(checkin = null) {
        super.init();
        if (!this.agendado) {
            this.agendamentoService.dispatch(BlockuiService.start);
            this.checkinService
                .consultaCheckin({
                    cdPessoaFisica: this.user?.cdPessoaFisica,
                    dtCheckin: DataUtil.formataData(new Date())
                })
                .then(
                    resp => {
                        this.agendamentoService.dispatch(BlockuiService.stop);
                        const dados = resp.data;
                        if (!dados || !dados.cdEstabelecimento) {
                            this.setStep(0);
                            return;
                        }

                        if (dados.cdEstabelecimento) {
                            this.setUnidade(dados.cdEstabelecimento);
                        }
                        if (dados.ieParticular) {
                            this.setTipoAtendimento(dados.ieParticular === "S" ? "P" : "C");
                        }
                        if (dados.dtCheckin) {
                            this.setDataSelect(DataUtil.desformataDataTempo(dados.dtCheckin));
                        }

                        if (dados.rg) {
                            this.setCheckinDocumento(dados.rg);
                            this.setDocumento(dados.rg.fileImage);
                            this.setPossuiDocumento(true);

                            if (dados.ieParticular === "S") {
                                this.goTo(4, dados);
                            } else {
                                this.setBulletActive([false, false, false, false, true]);
                                this.goTo(5, dados);
                            }
                        }
                        if (dados.convenio) {
                            this.setCheckinConvenio(dados.convenio);
                            this.setCarteirinha(dados.convenio.fileImage);
                            this.setPossuiCarteirinha(true);
                            this.goTo(5, dados);
                        }
                        if (dados.pedidoMedico) {
                            this.setPossuiPedido(true);
                            this.goTo(5, dados);
                        }
                    },
                    error => {
                        this.agendamentoService.dispatch(BlockuiService.stop);
                    }
                );
        } else {
            this.setExame(checkin);
            this.goTo(2, {ieParticular: "N"});
        }

        this.getDataCheckinPorData();
    }

    goTo(index, dados) {
        const newBullet =
            dados.ieParticular === "S"
                ? [false, false, false, false, false]
                : [false, false, false, false, false, false];
        newBullet[index] = true;
        this.setBulletActive(newBullet);
        this.setStep(index);
    }

    removeFile() {
        const that = this;
        switch (that.step) {
            case 3:
                that.setDocumento("");
                break;
                break;
            case 4:
                that.setCarteirinha("");
                break;
            default:
        }
    }

    getFile() {
        const dom = document.getElementById("file").files;
        if (dom && dom.length <= 0) {
            return;
        }
        var file = dom[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        const that = this;
        reader.onload = function () {
            switch (that.step) {
                case 3:
                    that.setDocumento(reader.result);
                    break;
                case 4:
                    that.setCarteirinha(reader.result);
                    break;
                case 5:
                    that.setPedidos([...that.pedidos, that.montarPedido(reader.result)]);
                    break;

                default:
            }
        };
        reader.onerror = function (error) {
            return error(true);
        };
    }

    montarPedido(fileImage) {
        const ieParticular = this.tipoAtendimento === "P" ? "S" : "N";
        let data, unidade;
        if (this.exame) {
            data = this.exame.dtCheckin;
            unidade = this.exame.cdEstabelecimento;
        } else {
            data = this.dataSelect;
            unidade = this.unidade;
        }
        return {
            cdPessoaFisica: this.user?.cdPessoaFisica,
            ieTipoDocumento: 99,
            dtCheckin: `${moment(data).format("YYYY-MM-DD")}T07:00`,
            cdEstabelecimento: unidade,
            ieParticular: ieParticular,
            fileImage: fileImage,
            ieTipoCheckin: this.ieTipoCheckin
        };
    }

    removerPedido(pedido, index) {
        if (pedido.id) {
        } else {
            const pedidos = [...this.pedidos];
            pedidos.splice(index, 1);
            this.setPedidos(pedidos);
        }
    }

    nextStep() {
        const i = this.step;
        this.agendamentoService.dispatch(BlockuiService.start);
        switch (i) {
            case 0:
                if (this.unidade !== 1 && this.unidade !== 11) {
                    this.agendamentoService.dispatch(BlockuiService.stop);
                    ToastService.showError(
                        "É preciso selecionar uma unidade para continuar."
                    );
                    return;
                }
                this.next(i);
                break;
            case 1:
                if (!this.dataSelect) {
                    this.agendamentoService.dispatch(BlockuiService.stop);
                    ToastService.showError(
                        "É preciso selecionar o tipo de atendimento para continuar."
                    );
                    return;
                }
                this.next(i);
                break;
            case 2:
                if (!this.tipoAtendimento) {
                    this.agendamentoService.dispatch(BlockuiService.stop);
                    ToastService.showError(
                        "É preciso selecionar o tipo de atendimento para continuar."
                    );
                    return;
                }
                this.next(i);
                break;
            case 3:
                if (this.documento == "") {
                    this.agendamentoService.dispatch(BlockuiService.stop);
                    ToastService.showError(
                        "É preciso fazer upload de uma foto de um documento para continuar."
                    );
                    return;
                }
                if (this.possuiDocumento) {
                    this.handleDocumentUpload(
                        1,
                        this.checkinDocumento,
                        this.documento,
                        true
                    );
                } else {
                    this.handleDocumentUpload(1, undefined, this.documento, false);
                }
                break;
            case 4:
                if (this.carteirinha == "") {
                    this.agendamentoService.dispatch(BlockuiService.stop);
                    ToastService.showError(
                        "É preciso fazer upload de uma foto de uma carteirinha para continuar."
                    );
                    return;
                }
                if (this.possuiCarteirinha) {
                    this.handleDocumentUpload(
                        5,
                        this.checkinConvenio,
                        this.carteirinha,
                        true
                    );
                } else {
                    this.handleDocumentUpload(5, undefined, this.carteirinha, false);
                }
                break;
            case 5:
                this.finalizar();
                break;
            default:
                this.next(i);
        }
    }

    handleDocumentUpload(ieTipoDocumento, documento, fileImage, isUpdate) {
        const ieParticular = this.tipoAtendimento === "P" ? "S" : "N";
        let request;
        if (isUpdate) {
            request = this.checkinService.atualizaDocumentos({
                id: documento.id,
                cdPessoaFisica: documento.cdPessoaFisica,
                ieTipoDocumento: documento.ieTipoDocumento,
                dtCheckin: documento.dtCheckin,
                dtCriacao: documento.dtCriacao,
                dtAtualizacao: this.documento.dtAtualizacao,
                cdEstabelecimento: this.unidade,
                ieParticular: ieParticular,
                fileImage: fileImage,
                ieTipoCheckin: this.ieTipoCheckin
            });
        } else {
            let data, unidade;
            if (this.exame) {
                data = this.exame.dtCheckin;
                unidade = this.exame.cdEstabelecimento;
            } else {
                data = `${moment(this.dataSelect).format("YYYY-MM-DD")}T07:00`;
                unidade = this.unidade;
            }
            request = this.checkinService.documentos({
                cdPessoaFisica: this.user?.cdPessoaFisica,
                ieTipoDocumento: ieTipoDocumento,
                dtCheckin: data,
                cdEstabelecimento: unidade,
                ieParticular: ieParticular,
                fileImage: fileImage,
                ieTipoCheckin: this.ieTipoCheckin
            });
        }
        request
            .then(() => {
                this.next(this.step);
            })
            .catch(error => {
                this.agendamentoService.dispatch(BlockuiService.stop);
                ToastService.showError("Não foi possível enviar a imagem.");
            });
    }

    getDataCheckinPorData = () => {
        this.hoje = new Date();
        const horaFormatada = moment(this.hoje).format("HH:mm");

        if (parseInt(horaFormatada) > 12) {
            this.setMinDate(new Date(this.sysdateTres));
            this.setMaxDate(
                new Date(
                    moment(this.hoje)
                        .add(5 * 24, "hour")
                        .format("YYYY-MM-DD")
                )
            );
        } else {
            this.setMinDate(new Date(this.sysdateDois));
            this.setMaxDate(
                new Date(
                    moment(this.hoje)
                        .add(4 * 24, "hour")
                        .format("YYYY-MM-DD")
                )
            );
        }
    };

    finalizar() {
        if (this.pedidos.length === 0) {
            ToastService.showError(
                "É preciso fazer upload de um pedido médico para continuar."
            );
            return;
        }
        this.checkinService.dispatch(BlockuiService.start);
        this.checkinService
            .atualizarPedidoMedico(this.pedidos)
            .then(res => {
                this.finalizaCheckin();
            })
            .catch(err => {
                this.checkinService.dispatch(BlockuiService.stop);
            });
    }

    finalizaCheckin() {
        let checkin;
        if (!this.exame) {
            checkin = {
                cdPessoaFisica: this.user?.cdPessoaFisica,
                dtCheckin: `${moment(this.dataSelect).format("YYYY-MM-DD")}T07:00`,
                ieParticular: this.isParticular ? "S" : "N",
                cdEstabelecimento: this.unidade,
                ieTipoCheckin: this.ieTipoCheckin
            };
        } else {
            checkin = {
                cdPessoaFisica: this.user?.cdPessoaFisica,
                dtCheckin: `${moment(this.exame.dtCheckin).format("YYYY-MM-DD")}T07:00`,
                ieParticular: this.isParticular ? "S" : "N",
                cdEstabelecimento: this.exame.cdEstabelecimento,
                ieTipoCheckin: this.ieTipoCheckin
            };
        }
        this.checkinService.confirmaCheckin(checkin).then(
            () => {
                this.agendamentoService.dispatch(BlockuiService.stop);
                ToastService.showSuccess("Check-in realizado com sucesso.");
                this.history.push("/checkin/finalizado");
            },
            error => {
                this.agendamentoService.dispatch(BlockuiService.stop);
                ToastService.showError("Não foi possível finalizar o Check-in.");
            }
        );
    }
}
