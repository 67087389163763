import React, {useEffect, useState} from 'react'
import {Dropdown} from "primereact/dropdown";
import {EmptyComponent} from "../../../../vendors/internalComponents/EmptyComponent/EmptyComponent";
import {ToastService} from "../../../services/utils/toast.service";
import AgendamentoService from "../services/agendamento.service";
import AuthService from "../../../services/auth.service";
import {toAbsoluteUrl} from "../../../../vendors/_metronic/_helpers";

export default function DocumentoAgendamentoUpload(props) {

    const [cdTipoDocumento, setCdTipoDocumento] = useState(-1);
    const [titulo, setTitulo] = useState('do documento pessoal');
    const [listaFiltrada, setListaFiltrada] = useState([]);

    const agendamentoService = new AgendamentoService();
    const tipoDocumentoList = agendamentoService.getTipoDocumentoList();
    const user = AuthService.getUser();

    useEffect(() => {
        filtraLista(-1, props.lista);
    }, []);


    function dropdownTemplate(item) {
        return (
            <>
                <div className="documento-agendamento-drop p-d-flex p-flex-row p-align-center p-justify-between">
                    <label>
                        {item.label}
                    </label>
                    {
                        tipoAdicionado(item) &&
                        <span>
                            <i className="fas fa-check"/>
                        </span>
                    }
                </div>
            </>
        );
    }

    function getFile() {
        const dom = document.getElementById('file').files;
        if (dom && dom.length <= 0) {
            return;
        }
        var file = dom[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const novaLista = [...props.lista];
            novaLista.push({
                tipoDocumento: cdTipoDocumento,
                nome: file.name,
                type: file.type,
                nomePaciente: user.nmPessoaFisica,
                nrSeqAutorizacao: props.nrSeqAutorizacao,
                arquivo: reader.result,
                cdPessoaFisica: user.cdPessoaFisica
            })
            setCdTipoDocumento(-1);
            filtraLista(-1, novaLista);
            props.setLista(novaLista);
            reindexTipo(novaLista);
        };
        reader.onerror = function (error) {
            return error(true);
        };
    }

    function reindexTipo(novaLista) {
        if (!novaLista || novaLista.length === 0) {
            props.setTiposAdicionados([]);
            return;
        }
        const listaTipo = novaLista.map(m => m.tipoDocumento);
        props.setTiposAdicionados([...new Set(listaTipo)]);
    }

    function tipoAdicionado(item) {
        return props.tiposAdicionados.indexOf(item.value) >= 0;
    }


    function selectFileClick() {
        setTimeout(function () {
            ToastService.showWarn('Dê preferência para arquivos no formato PDF.');
        }, 500);
    }

    const pedidoImagem = (pedido, index) =>
        <div className="configuracao-alterar-foto-img" key={index}>
            <i className="fas fa-times-circle" onClick={() => removerDocumento(pedido, index)}
               style={{fontSize: '25px', color: '#d25f78'}}/>
            {
                pedido.type !== 'application/pdf' ?
                    <img src={pedido.arquivo} className="foto-documento-agendamento" alt=""/> :
                    <img src={toAbsoluteUrl('/media/hcor/pdf-icon.png')} className="foto-documento-agendamento"/>
            }

        </div>
    ;


    function removerDocumento(pedido, index) {
        const docs = [...props.lista];
        docs.splice(index, 1);
        props.setLista(docs);
        filtraLista(cdTipoDocumento, docs);
        reindexTipo(docs);
    }

    function filtraLista(cdTipoDocumento, lista) {
        const filtered = [...lista].filter(f => {
            if (cdTipoDocumento === -1) {
                return true;
            }
            return f.tipoDocumento === cdTipoDocumento;
        });
        setListaFiltrada(filtered);
    }


    return (
        <>

            <>
                <div className="wrapper-card">
                    <div className="p-fluid p-grid">
                        <div className="p-md-3 p-col-12">
                            <label>Selecionar tipo de documento</label>
                            <Dropdown
                                itemTemplate={dropdownTemplate}
                                value={cdTipoDocumento}
                                onChange={(e) => {
                                    setTitulo(agendamentoService.getTituloByIndex(e.target.value))
                                    setCdTipoDocumento(e.target.value);
                                    filtraLista(e.target.value, props.lista);
                                }}
                                options={tipoDocumentoList}
                            />
                        </div>
                    </div>
                    {
                        cdTipoDocumento !== -1 &&
                        <div className="configuracao-alterar-foto">
                            <EmptyComponent
                                condition={props.lista.length === 0}
                                body={
                                    <>
                                        <div className="configuracao-alterar-foto-icon">
                                            <label id="file-label" htmlFor="file"
                                                   onClick={(event) => selectFileClick()}>
                                                <i className="fas fa-camera camera-icon"></i>
                                            </label>
                                        </div>
                                    </>
                                }/>
                            <div className="configuracao-foto-recomendacao"
                                 style={{marginLeft: (props.lista.length === 0 ? '50px' : '0')}}>
                                <h2 className="configuracao-recomendacao-title">Selecionar arquivo {titulo}</h2>
                            </div>
                            <input type="file"
                                   onChange={event => {
                                       getFile()
                                   }}
                                   name="file" id="file" style={{visibility: 'hidden'}}/>
                        </div>
                    }


                </div>

                <div className="checkin-pedidos-wrapper">
                    {listaFiltrada.map((m, index) => pedidoImagem(m, index))}
                </div>

                <div>
                    <EmptyComponent
                        condition={props.lista.length > 0 && cdTipoDocumento !== -1}
                        body={
                            <>
                                <div className="documento-agendamento-adicionar">

                                    <label htmlFor="file">
                                        <i className="fas fa-plus"/>
                                    </label>
                                </div>
                            </>
                        }
                    />

                </div>

            </>

        </>
    );
}
