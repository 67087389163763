import axios from "axios";
import Environment from "../../environments/environment";
import {Interceptors} from "./interceptors";

const authApi = axios.create({
    baseURL: Environment.getUrl()
});

export const cadastroApi = axios.create({
    baseURL: Environment.getCadastroUrl()
});

export const agendamentoApi = axios.create({
    baseURL: Environment.getAgendamento()
});

export const extratoApi = axios.create({
    baseURL: Environment.getExtrato()
});

export const resultadoApi = axios.create({
    baseURL: Environment.getResultado()
});

export const checkInApi = axios.create({
    baseURL: Environment.getCheckIn()
});

export const mapaTempoPsApi = axios.create({
    baseURL: Environment.getMapaTempoPsUrl()
});


export const questionarioApi = axios.create({
    baseURL: Environment.getQuestionarioUrl()
});


questionarioApi.interceptors.request.use(request =>
    Interceptors.requestInterceptor(request)
);
questionarioApi.interceptors.response.use(
    response => Interceptors.responseInterceptorSuccess(response),
    err => Interceptors.responseInterceptorError(err, questionarioApi)
);

checkInApi.interceptors.request.use(request =>
    Interceptors.requestInterceptor(request)
);
checkInApi.interceptors.response.use(
    response => Interceptors.responseInterceptorSuccess(response),
    err => Interceptors.responseInterceptorError(err, checkInApi)
);

resultadoApi.interceptors.request.use(request =>
    Interceptors.requestInterceptor(request)
);
resultadoApi.interceptors.response.use(
    response => Interceptors.responseInterceptorSuccess(response),
    err => Interceptors.responseInterceptorError(err, resultadoApi)
);

agendamentoApi.interceptors.request.use(request =>
    Interceptors.requestInterceptor(request)
);
agendamentoApi.interceptors.response.use(
    response => Interceptors.responseInterceptorSuccess(response),
    err => Interceptors.responseInterceptorError(err, agendamentoApi)
);

authApi.interceptors.request.use(request =>
    Interceptors.requestInterceptor(request)
);
authApi.interceptors.response.use(
    response => Interceptors.responseInterceptorSuccess(response),
    err => Interceptors.responseInterceptorError(err, authApi)
);

cadastroApi.interceptors.request.use(request =>
    Interceptors.requestInterceptor(request)
);
cadastroApi.interceptors.response.use(
    response => Interceptors.responseInterceptorSuccess(response),
    err => Interceptors.responseInterceptorError(err, cadastroApi)
);

extratoApi.interceptors.request.use(request =>
    Interceptors.requestInterceptor(request)
);
extratoApi.interceptors.response.use(
    response => Interceptors.responseInterceptorSuccess(response),
    err => Interceptors.responseInterceptorError(err, extratoApi)
);

export default authApi;
