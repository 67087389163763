import {AbstractService} from "../../shared/abstracts/abstract.service";
import {cadastroApi, fidelizacaoApi} from "../api";
import Environment from "../../../environments/environment";

export class CadastroService extends AbstractService {
    constructor() {
        super("", "", cadastroApi);
    }

    getByUsernameAndDtNascimento(username, dtNascimento) {
        return this.api.post(`${this.rota}/gestao/validar-acesso`, {
            username,
            dtNascimento,
            appCode: Environment.getAppCode()
        });
    }

    sendToken(envio) {
        return this.api.post(`${this.rota}/token/gerar`, envio);
    }

    validar(token) {
        return this.api.post(`${this.rota}/token/validar`, token);
    }

    validarCpf(cpf) {
        return this.api.get(`${this.rota}/gestao/validar-cpf/${cpf}`);
    }

    cadastrar(objeto) {
        return this.api.post(`${this.rota}/gestao/`, objeto);
    }

    cadastrarPessoa(objeto) {
        return this.api.post(`${this.rota}/gestao/pessoa`, objeto);
    }

    atualizarSenha(objeto) {
        return this.api.put(`${this.rota}/gestao/`, objeto);
    }

    salvarFoto(objeto) {
        return this.api.post(`${this.rota}/gestao/foto`, objeto);
    }

    obterFoto(nrSeqFoto) {
        return this.api.get(`${this.rota}/gestao/foto/${nrSeqFoto}`);
    }

    obterTermos(id) {
        return this.api.get(`${this.rota}/termo/consultar/${id}`);
    }

    assinarTermo(id, idTermo) {
        return this.api.put(`${this.rota}/termo/${id}/sign/${idTermo}`);
    }

    permitirNotificacao(notificacao) {
        return this.api.put(
            `${this.rota}/gestao/permitir-notificacao`,
            notificacao
        );
    }
}
