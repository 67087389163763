import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {BlockuiService} from "../../services/blockui.service";
import ListaService from "../../services/common/lista.service";
import {ToastService} from "../../services/utils/toast.service";
import MessageUtil from "../utils/MessageUtil";

export class AbstractService {
    constructor(rota, rota_retorno, api) {
        this.rota = rota;
        this.rota_retorno = rota_retorno;
        this.listaService = new ListaService();
        this.api = api;
        this.history = useHistory();
        this.dispatch = useDispatch();
    }

    serialize(obj) {
        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p) && (!!obj[p] || obj[p] === 0) && typeof obj[p] !== 'function') {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    async findAll() {
        const response = await this.api.get(this.rota + "/");
        return this.listaService.dominioListParaSelectItemList(response.data);
    }

    async create(entity) {
        const response = await this.api.post(this.rota + "/", entity);
        return response;
    }

    async update(entity) {
        const response = await this.api.put(this.rota + "/", entity);
        return response;
    }

    list(entity) {
        return this.api.post(this.rota + "/consultar", entity);
    }

    delete(id) {
        return this.api.delete(this.rota + "/" + id);
    }

    findOne(id) {
        return this.api.get(this.rota + "/" + id);
    }

    save(entity, form) {
        if (!this.isValid(entity, form)) {
            return;
        }
        let response = null;
        if (entity.id) {
            response = this.update(entity);
        } else {
            response = this.create(entity);
        }
        if (this.rota_retorno) {
            this._saveResolved(response);
            return;
        }
        return response;
    }

    isValid(entity, form) {
        if (!form) {
            return this.othersValidations(entity);
        }
        return (
            this.validaCamposObrigatorios(form) && this.othersValidations(entity)
        );
    }

    othersValidations(entity) {
        return true;
    }

    _saveResolved(response) {
        this.dispatch(BlockuiService.start);
        response
            .then(item => {
                this.dispatch(BlockuiService.stop);

                ToastService.showSuccess(MessageUtil.OPERACAO_REALIZADA_SUCESSO);
                this.history.push(this.rota_retorno);
            })
            .catch(err => {
                this.dispatch(BlockuiService.stop);
                if (err && err.response && err.response.status == 422) {
                    ToastService.showError(err.response.data);
                } else {
                    ToastService.showError(MessageUtil.FALHA_REQUISICAO);
                }
            });
    }

    validaCamposObrigatorios(form) {
        if (!form.checkValidity()) {
            ToastService.showError(MessageUtil.CAMPO_OBRIGATORIO);
            return false;
        }
        return true;
    }

    checkDropdownValues(itens) {
        let bol = true;
        if (itens && itens.length > 0) {
            itens.forEach(item => {
                if (bol && (!item || !item.id)) {
                    bol = false;
                }
            });
        }
        return bol;
    }

    start() {
        this.dispatch(BlockuiService.start);
    }

    stop() {
        this.dispatch(BlockuiService.stop);
    }
}
