import authApi from "./api";
import Environment from "../../environments/environment";
import {TokenService} from "./token.service";
import {UserService} from "./user.service";
import {BlockuiService} from "./blockui.service";
import {ToastService} from "./utils/toast.service";
import MessageUtil from "../shared/utils/MessageUtil";
import {AbstractService} from "../shared/abstracts/abstract.service";

export default class AuthService extends AbstractService {
    constructor() {
        super("", "", authApi);
    }

    login(usuario) {
        return this.api.post(`${Environment.url}/auth/login`, usuario);
    }

    logout() {
        return this.api.post(`${Environment.url}/user/logout`);
    }

    saveUser(usuario) {
        TokenService.saveAuthInfo(usuario);
    }

    userInfo(idPessoaFisica) {
        return this.api.get(`${Environment.url}/user/check`);
    }

    check() {
        return this.api.get(`${Environment.url}/user/check`);
    }

    static getAccessToken() {
        return TokenService.getAccessToken();
    }

    static getRefreshToken() {
        return TokenService.getRefreshToken();
    }

    static getAuthInfo() {
        return TokenService.getAuthInfo();
    }

    static getUser() {
        return TokenService.getUser();
    }

    static limpar() {
        TokenService.limpar();
    }

    static salvarInfoFoto(user) {
        TokenService.saveUser(user);
    }

    doLogin(username, password) {
        this.start();
        this.login({username: username, dsSenha: password, appCode: Environment.getAppCode()}).then(res => {
            this.saveUser(res.data);
            if (res.data && res.data.user) {
                this.dispatch(UserService.changeUser(res.data.user.nmPessoaFisica));
            }
            this.stop();
            this.history.push('/home');
            ToastService.showSuccess(MessageUtil.LOGADO_SUCESSO);
        }, err => {
            if (err) {

                ToastService.showError(err.data);
            }
            this.dispatch(BlockuiService.stop);
        });

    }

}
