import {TokenService} from "./token.service";

export class ConfigService {
    static getHeaders() {
        return {
            headers: {
                Authorization: TokenService.getAccessToken()
            }
        };
    }
}
