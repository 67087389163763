export default class AgendamentoConstants {

    static TIPO_AGENDAMENTO = 0;
    static ESTABELECIMENTO = 1;
    static TIPO_ATENDIMENTO = 2;
    static CONVENIO = 3;
    static MEDICO_ESPECIALIDADE = 4;
    static DATA_AGENDAMENTO = 5;
    static CARTEIRINHA = 6;
    static DOCUMENTO = 7;
    static CONFIRMAR = 8;
}
