import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import {toAbsoluteUrl} from "../../_helpers";

const DialogHome = ({ visible, onHide }) => {
    return (
        <Dialog
            draggable={false}
            visible={visible}
            onHide={onHide}
            // header="Oi!"
            style={{ width: "600px" }}
        >
            <div className="home-dialog">
            <img
                src={toAbsoluteUrl("/media/hcor/BannerFleury.jpg")}
                style={{width: "550px", marginLeft: "10px", cursor: "pointer"}}
                onClick={() => window.open('https://www.hcor.com.br/faq-hcor-e-fleury/?_thumbnail_id=80017', '_blank')}

            />
            </div>
            <div className="home-dialog-mobile">
                <img
                    src={toAbsoluteUrl("/media/hcor/BannerFleury.jpg")}
                    style={{width: "100%", height: "100%", cursor: "pointer"}}
                    onClick={() => window.open('https://www.hcor.com.br/faq-hcor-e-fleury/?_thumbnail_id=80017', '_blank')}

                />
            </div>
            {/*<Button label="Fechar" onClick={onHide} />*/}
        </Dialog>
    );
};

export default DialogHome;
