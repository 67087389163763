import React from "react";
import {Button} from "primereact/button";
import classNames from "classnames";

export default function TipoAtendimento(props) {

    return (
        <>
            <h3 className="titulo-primario">Selecione o tipo de pagamento:</h3>
            <div className="step-wrapper">
                <div>
                    <Button
                        className="btn-blue-icon"
                        type="button"
                        onClick={() => {
                            props.controller.selectIeParticular('N')
                        }}>
                        <h3 className={'titulo-primario ' + classNames({'ativo': props.controller.ieParticular === 'N'})}>Convênio</h3>
                    </Button>
                </div>
                {/*<div className="step-right-border">*/}

                {/*</div>*/}
                {/*<div>*/}
                {/*    <Button*/}
                {/*        type="button"*/}
                {/*        onClick={() => {*/}
                {/*            props.controller.selectIeParticular('S')*/}
                {/*        }}*/}
                {/*        className="btn-blue-icon"*/}
                {/*    >*/}
                {/*        <h3 className={'titulo-primario ' + classNames({'ativo': props.controller.ieParticular === 'S'})}>Particular</h3>*/}
                {/*    </Button>*/}
                {/*</div>*/}

            </div>

        </>
    );
}
