import React, {useState} from "react";
import {DataPipeResumo, TempoPipe} from "../../../../vendors/internalComponents/Pipe/Pipe";
import {Button} from "primereact/button";
import {If} from "../../../../vendors/internalComponents/If/If";
import Abstract from "../../../components/Abstract/Abstract";

export default function ExameItem({item, controller, index}) {
    const [show, setShow] = useState(index == 0);
    const ieAnterior = item.ieAnterior == 'S';

    const itemTemplate = () => {
        return (
            <div className="item-desktop">

                <div className="data-span">
                    <b>{DataPipeResumo(item.dtExame)} </b>
                </div>

                <div className="exame-item-wrapper">
                    <div className="exame-item-data">
                        <h3>{DataPipeResumo(item.dtExame)}</h3>
                    </div>
                    <div className="exame-item-generico">
                        <label><b>Atendimento: </b> <Abstract content={item.nrAtendimento} length={10}/> </label>
                    </div>
                    <div className="exame-item-generico">
                        <label><b>Convênio: </b> {item.dsConvenioAgg} </label>
                    </div>
                    <div className="exame-item-generico">
                        <label><b>Unidade: </b> {item.dsEstabelecimentoAgg} </label>
                    </div>
                    <div className="exame-item-generico">
                        <label><b>Amostras: </b> <Abstract content={item.dsAmostra} length={10}/> </label>
                    </div>
                    <div
                        className="exame-item-generico-ocultar"
                        onClick={() => setShow(!show)}
                        style={{textAlign: 'right', cursor: 'pointer'}}>
                        <label
                            onClick={() => setShow(!show)}
                            style={{textAlign: 'right', cursor: 'pointer'}}>
                            <b onClick={() => setShow(!show)}
                               style={{textAlign: 'right', cursor: 'pointer'}}> {show ? 'ocultar' : 'detalhar'} </b>
                        </label>
                    </div>
                </div>
                {/*{!show && <hr style={{ borderTop: '1px solid #ddd', width: '100%', margin: '5px 0' }} />}*/}
            </div>
        );
    }

    const mobileTemplate = () => {
        return (
            <>
                <table className="content-table mobile-table">
                    <tbody>
                    <tr>
                        <td>
                                <span className="column-mobile-item"
                                      style={{color: "var(--azul-hcor)", fontSize: "1.2em", marginLeft: "10px"}}>
                                    <b>{DataPipeResumo(item.dtExame)} </b>
                                </span>
                            <span className="column-mobile-item">
                                    <b style={{color: "var(--azul-hcor)", marginLeft: "10px"}}>Atendimento: </b>
                                    <Abstract content={item.nrAtendimento} length={10}/>
                                </span>
                            <span className="column-mobile-item">
                                    <b style={{color: "var(--azul-hcor)", marginLeft: "10px"}}>Convênio: </b>
                                {item.dsConvenioAgg}
                                </span>
                            <span className="column-mobile-item">
                                    <b style={{color: "var(--azul-hcor)", marginLeft: "10px"}}>Unidade: </b>
                                {item.dsEstabelecimentoAgg}
                                </span>
                            <span className="column-mobile-item">
                                    <b style={{color: "var(--azul-hcor)", marginLeft: "10px"}}>Amostra: </b>
                                    <Abstract content={item.dsAmostra} length={10}/>
                                </span>

                            <span className="exame-item-generico-mobile"
                                  onClick={() => setShow(!show)}
                                  style={{textAlign: 'right', cursor: 'pointer', marginLeft: "10px"}}>
                        <label
                            onClick={() => setShow(!show)}
                            style={{textAlign: 'right', cursor: 'pointer'}}>
                            <b onClick={() => setShow(!show)}
                               style={{textAlign: 'right', cursor: 'pointer'}}> {show ? 'ocultar' : 'detalhar'} </b>
                        </label>
                                </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </>
        )
    }

    const acoes = (row) => {
        return (
            <>
                <div className="button-acoes">
                    <Button
                        style={{
                            marginBottom: "3px",
                        }}
                        icon="pi pi-download"
                        label="Baixar laudo"
                        className="p-button-rounded btn-laudo"
                        onClick={() => {
                            controller.obterLaudo(row, item)
                        }}
                    />
                    <If condition={controller.filter.ieTipoExame === 'L' && !ieAnterior}>
                        <Button
                            style={{marginBottom: "3px"}}
                            icon="pi pi-chart-bar"
                            label="Evolutivo"
                            className="p-button-rounded btn-evolutivo"
                            onClick={() => controller.obterLaudoEvolutivo(row)}
                        />
                    </If>
                    <If condition={controller.filter.ieTipoExame === 'I'}>
                        <Button
                            style={{marginBottom: "3px"}}
                            icon="pi pi-chart-bar"
                            label="Imagem"
                            className="p-button-rounded btn-imagem"
                            onClick={() => controller.obterExameImagem(row)}
                        />
                    </If>
                </div>
            </>
        );
    }

    const table = () => {

        return (
            <>
                <table className="item-desktop" style={{width: "100%", borderCollapse: "collapse"}}>
                    <thead>
                    <tr>
                        <th style={{
                            width: '10%',
                            background: '#f1f2f1',
                            paddingLeft: '20px',
                            borderBottom: '1px solid #ddd'
                        }}>
                            <h3 className='exame-title'>
                                Pedido
                            </h3>
                        </th>
                        <th style={{width: '10%', background: '#f1f2f1'}}>
                            <h3 className='exame-title'>
                                Horário
                            </h3>
                        </th>
                        <th style={{width: '35%', background: '#f1f2f1', borderBottom: '1px solid #ddd'}}>
                            <h3 className='exame-title'>
                                Exames
                            </h3>
                        </th>
                        <th style={{width: '15%', background: '#f1f2f1', borderBottom: '1px solid #ddd'}}>
                            <h3 className='exame-title'>
                                Médico
                            </h3>
                        </th>
                        <th style={{width: '10%', background: '#f1f2f1', borderBottom: '1px solid #ddd'}}>
                            <h3 className='exame-title'>
                                Status
                            </h3>
                        </th>
                        <th style={{width: '20%', background: '#f1f2f1', borderBottom: '1px solid #ddd'}}>
                            <h3 className='exame-title'>
                                Ação
                            </h3>
                        </th>
                    </tr>
                    </thead>


                    <tbody>
                    {item.lista.map((m, index) =>
                        <tr key={'exame-linha-n2-desktop-' + index}>
                            <td className='resultado-table' style={{paddingLeft: '18px'}}>{m.nrPrescricao}</td>
                            <td className='resultado-table'>{TempoPipe(m.dtPrescricao)}</td>
                            <td className='resultado-table'><Abstract content={m.dsExameCompleto} length={50}/></td>
                            <td className='resultado-table'>{m.dsMedico}</td>
                            <td className='resultado-table'>{m.dsStatus}</td>
                            <td className='resultado-table'>{acoes(m)}</td>


                        </tr>
                    )}
                    </tbody>

                </table>

            </>
        );
    };

    const tableMobile = () => {

        return (
            <>
                <table className="content-table mobile-table">
                    <tbody>
                    {item.lista.map((m, index) =>
                        <tr key={'resultado-exame-linha-responsivo-' + index}>
                            <td>
                            <span className="column-mobile-item">
                                    <b style={{color: "#525273"}}>Pedido </b>
                                {m.nrPrescricao}
                            </span>
                                <span className="column-mobile-item">
                                    <b style={{color: "#525273"}}>Horário </b>
                                    {TempoPipe(m.dtPrescricao)}
                            </span>
                                <span className="column-mobile-item">
                                    <b style={{color: "#525273"}}>Exames </b>
                                    <Abstract content={m.dsExameCompleto} length={130}/>
                            </span>
                                <span className="column-mobile-item">
                                    <b style={{color: "#525273"}}>Médico </b>
                                    {m.dsMedico}
                            </span>
                                <span className="column-mobile-item">
                                    <b style={{color: "#525273"}}>Status </b>
                                    {m.dsStatus}
                            </span>
                                <span className="column-mobile-item">
                                    <b style={{color: "#525273"}}>Ação </b>
                                    {acoes(m)}
                            </span>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </>
        );
    };


    return (
        <>
            <div className="item-redondo">
            {itemTemplate()}
            {mobileTemplate()}
            <If condition={show}>
                {table()}
                {tableMobile()}
            </If>
            </div>
        </>
    );
}
