import ReactGA from "react-ga4";
import Environment from "../../../environments/environment";

export default class AnalyticsUtil {
    static acessoAPagina(page) {
        if (Environment.env === "prd") {
            ReactGA.event({
                category: "pageview",
                action: "acesso_" + page
            });
        }
    }
}
