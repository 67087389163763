import React from 'react';
import {EmptyComponent} from "../../../vendors/internalComponents/EmptyComponent/EmptyComponent";
import './field.scss'

export default function Field(props) {
    const sm = props.sm ? props.sm : '12';
    const md = props.md ? props.md : '6';
    const lg = props.lg ? props.lg : props.col;
    const xl = props.xl ? props.xl : props.col;
    const offset = props.offset ? `p-lg-offset-${props.offset} p-lg-offset-${props.offset}` : '';
    const className = `form-layout p-col-12 p-sm-${sm} p-md-${md} p-lg-${lg} p-xl-${xl} ${
        props.className ? props.className : ''
    } ${offset}`;
    const labelClassName = `hcor-field ${props.valid === true ? '' : 'hcor-field-invalid'}`


    return (
        <>
            <div style={props.style} id={props.id} className={className}>
                <EmptyComponent condition={props.label}
                                body={
                                    <label className={labelClassName}>{props.label}</label>
                                }
                />
                {props.children}
                <EmptyComponent condition={props.valid === false}
                                body={
                                    <label
                                        style={{marginTop: '5px'}}
                                        className="hcor-field-invalid">
                                        {props.invalidLabel ? props.invalidLabel : 'Campo obrigatório.'}
                                    </label>
                                }
                />
            </div>
            <EmptyComponent
                condition={props.breakLine === true}
                body={<div style={{padding: '0!important', width: '100%'}}></div>}
            />
        </>
    );
}
