import React, {useEffect} from "react";
import {ConditionalComponent} from "../../../../vendors/internalComponents/ConditionalComponent/ConditionalComponent";

import '../style.scss'

export default function CheckinDocumento(props) {
    useEffect(() => {
    }, [props.documento]);
    return (
        <>
            <div className="checkin-card">
                <h3>Upload de documento pessoal com foto:</h3>

                <div className="configuracao-alterar-foto">
                    <ConditionalComponent
                        condition={!props.controller.documento}
                        positive={
                            <div className="configuracao-alterar-foto-icon">
                                <label htmlFor="file">
                                    <i className="fas fa-camera camera-icon"></i>
                                </label>
                            </div>
                        }
                        negative={
                            <div className="configuracao-alterar-foto-img">
                                {
                                    props.controller.documento &&
                                    props.controller.documento.fileImage &&
                                    <img src={props.controller.documento.fileImage} className="configuracao-foto" alt=""/>
                                }
                                <i className="fas fa-times-circle" onClick={() => props.controller.removeFile()}
                                   style={{fontSize: '25px', color: '#d25f78', marginRight: '20px'}}/>
                            </div>
                        }
                    />
                    <div className="configuracao-foto-recomendacao">
                        {
                            !props.controller.documento  ?
                                <h2 className="configuracao-recomendacao-title">Selecionar imagem do documento</h2>
                                :
                                <h2 className="configuracao-recomendacao-title">Imagem do documento selecionada</h2>
                        }
                    </div>
                </div>
                <input type="file"
                       onChange={event => {
                           props.controller.getFile();
                       }}
                       name="file" id="file" style={{visibility: 'hidden'}}/>
            </div>
        </>
    );
}
