import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import * as blockui from "../vendors/blockui/blockuiRedux";
import * as user from "../vendors/user/userRedux";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    blockui: blockui.reducer,
    user: user.reducer
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
