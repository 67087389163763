import {Calendar} from "primereact/calendar";
import {SelectButton} from "primereact/selectbutton";
import {InputText} from "primereact/inputtext";
import React, {useEffect, useState} from "react";
import DataUtil from "../../../shared/utils/DataUtil";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";

export default function ExameFilter({filter, controller}) {
    const [useDropdown, setUseDropdown] = useState(false);

    useEffect(() => {
    }, [filter.ieTipoExame]);

    useEffect(() => {
        handleScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleResize = () => {
        handleScreenWidth(window.innerWidth);
    };

    const handleScreenWidth = (width) => {
        if (width < 992) {
            setUseDropdown(true);
        } else {
            setUseDropdown(false);
        }
    };

    const handleExibirTudo = () => {
        const updatedFilter = {
            ...filter,
            dtExame: null,
        };
        controller.search(updatedFilter);
    };

    return (
        <>
            <div className="exame-header-wrapper">
                <div>
                    <div><h4>A partir de</h4></div>
                    <div className="exame-header-data">
                    <Calendar
                        className="calendario-separator"
                        autoFocus
                        yearNavigator
                        monthNavigator
                        placeholder="dd/mm/aaaa"
                        mask="99/99/9999"
                        yearRange="1900:2100"
                        id="buttonbar"
                        value={filter.value}
                        showButtonBar={true}
                        onChange={(e) => {
                            filter.setDtExame(e.value);
                            if (DataUtil.formataData(e.value) !== DataUtil.formataData(filter.dtExame)) {
                                controller.search({...filter, dtExame: e.value});
                            }
                        }}
                        dateFormat="dd/mm/yy"
                        showIcon={true}
                    />
                    <Button
                        style={{marginLeft: '10px', marginTop: '4px', height: '32px'}}
                        label="Exibir tudo"
                        onClick={handleExibirTudo}
                    />
                    </div>
                </div>


                <div className="exame-header-tipo-exame">
                    <div>
                        <h4>Tipo de exame</h4>
                    </div>
                    {useDropdown ? (
                        <Dropdown
                            className="custom-dropdown"
                            optionLabel="nome"
                            optionValue="id"
                            options={controller.tipoList}
                            value={filter.ieTipoExame}
                            onChange={(e) => {
                                if (!e.value) {
                                    return;
                                }
                                filter.setIeTipoExame(e.value);
                                controller.search({...filter, ieTipoExame: e.value});
                            }}
                            style={{
                                backgroundColor: "#6cc4d4",
                                display: "flex",
                                color: "white"
                            }}
                        />
                    ) : (
                        <SelectButton
                            optionLabel="nome"
                            optionValue="id"
                            options={controller.tipoList}
                            value={filter.ieTipoExame}
                            onChange={(e) => {
                                if (!e.value) {
                                    return;
                                }
                                filter.setIeTipoExame(e.value);
                                controller.search({...filter, ieTipoExame: e.value});
                            }}

                        />
                    )}
                </div>

                <div className="exame-header-descricao">
                    <InputText
                        value={filter.dsExame}
                        placeholder="Buscar por exame"
                        onChange={(e) => {
                            filter.setDsExame(e.target.value);
                            if (e.target.value.length <= 3 && e.target.value.length > 0) {
                                return
                            }
                            controller.search({...filter, dsExame: e.target.value});

                        }}
                    />
                </div>

            </div>

        </>
    );
}
