/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_helpers";
import AuthService from "../../../../../../app/services/auth.service";

export function QuickUser() {
    return (
        <div
            id="kt_quick_user"
            className="offcanvas offcanvas-right offcanvas p-10"
        >
            <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 className="font-weight-bold m-0">
                    Perfil de Usuário
                    <small className="text-muted font-size-sm ml-2">12 mensagens</small>
                </h3>
                <a
                    href="#"
                    className="btn btn-xs btn-icon btn-light btn-hover-primary"
                    id="kt_quick_user_close"
                >
                    <i className="ki ki-close icon-xs text-muted"/>
                </a>
            </div>

            <div className="offcanvas-content pr-5 mr-n5">
                <div className="d-flex align-items-center mt-5">
                    <div className="symbol symbol-100 mr-5">
                        <div
                            className="symbol-label"
                            style={{
                                backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/users/procura-se.jpeg"
                                )})`
                            }}
                        />
                        <i className="symbol-badge bg-success"/>
                    </div>
                    <div className="d-flex flex-column">
                        <a
                            href="#"
                            className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                        >
                            Pablo
                        </a>
                        <div className="text-muted mt-1">Desenvolvedor</div>
                        <div className="navi mt-2">
                            <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    pablo.muro@not-found.com
                  </span>
                </span>
                            </a>
                        </div>
                        <button
                            className="btn btn-light-primary btn-bold"
                            onClick={() => AuthService.logout()}
                        >
                            Logout
                        </button>
                    </div>
                </div>

                <div className="separator separator-dashed mt-8 mb-5"/>

                <div className="navi navi-spacer-x-0 p-0">
                    <a href="/user/profile" className="navi-item">
                        <div className="navi-link">
                            <div className="symbol symbol-40 bg-light mr-3">
                                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/General/Notification2.svg"
                        )}
                    ></SVG>
                  </span>
                                </div>
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">Meu perfil</div>
                                <div className="text-muted">Dados de agente</div>
                            </div>
                        </div>
                    </a>

                    <a href="/user/profile" className="navi-item">
                        <div className="navi-link">
                            <div className="symbol symbol-40 bg-light mr-3">
                                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Mail-opened.svg"
                        )}
                    ></SVG>
                  </span>
                                </div>
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">Minhas mensagens</div>
                                <div className="text-muted">Caixa de entrada</div>
                            </div>
                        </div>
                    </a>

                    <a href="/user/profile" className="navi-item">
                        <div className="navi-link">
                            <div className="symbol symbol-40 bg-light mr-3">
                                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/Files/Selected-file.svg"
                        )}
                    ></SVG>
                  </span>
                                </div>
                            </div>
                            <div className="navi-text">
                                <div className="font-weight-bold">Meus projetos</div>
                                <div className="text-muted">Consultar meus projetos ativos</div>
                            </div>
                        </div>
                    </a>
                </div>

                <div className="separator separator-dashed my-7"></div>

                <div>
                    <h5 className="mb-5">Notificações Recentes</h5>

                    <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-danger mr-5">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>
                        <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a
                                href="#"
                                className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                            >
                                Seu projeto foi recusado
                            </a>
                            <span className="text-muted font-size-sm">2 dias atrás</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center bg-light-info rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-info mr-5">
              <SVG
                  src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Mail-notification.svg"
                  )}
                  className="svg-icon svg-icon-lg"
              ></SVG>
            </span>
                        <div className="d-flex flex-column flex-grow-1 mr-2">
                            <a
                                href="#"
                                className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                            >
                                Mensagens de Eduardo
                            </a>
                            <span className="text-muted font-size-sm">4 dias atrás</span>
                        </div>
                        <span className="font-weight-bolder text-info py-1 font-size-lg">
              50+
            </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
