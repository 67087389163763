import React, {useEffect, useState} from "react";
import './menu-configuracao.scss';
import AuthService from "../../services/auth.service";
import TituloAzul from "../../../vendors/internalComponents/TituloAzul/TituloAzul";
import {Card} from "primereact/card";
import {CpfPipe, DataPipe, TelefonePipe} from "../../../vendors/internalComponents/Pipe/Pipe";
import {CadastroService} from "../../services/common/cadastro.service";
import {ToastService} from "../../services/utils/toast.service";
import MessageUtil from "../../shared/utils/MessageUtil";
import {BlockuiService} from "../../services/blockui.service";
import {ConditionalComponent} from "../../../vendors/internalComponents/ConditionalComponent/ConditionalComponent";
import {Button} from "primereact/button";
import {useHistory} from "react-router-dom";
import {UserService} from "../../services/user.service";
import {Dialog} from "primereact/dialog";
import {TokenService} from "../../services/token.service";
import {Checkbox} from 'primereact/checkbox';
import AnalyticsUtil from "../../shared/utils/AnalyticsUtil";

export default function MenuConfiguracao() {
    let user = AuthService.getUser();
    const usuarioService = new CadastroService();
    const authService = new AuthService();
    const [foto, setFoto] = useState('');
    const history = useHistory();
    const [nrSeqFoto, setNrSeqFot] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [termo, setTermo] = useState();
    const [permSmsEmail, setPermSmsEmail] = useState(false);

    useEffect(() => {
        AnalyticsUtil.acessoAPagina('menu_configuracao');
        if (user.nrSeqFoto) {
            usuarioService.dispatch(BlockuiService.start);
            usuarioService.obterFoto(user.nrSeqFoto).then(res => {
                usuarioService.dispatch(BlockuiService.stop);
                setFoto(res.data);
            });
        }
        setPermSmsEmail(user.iePermSmsEmail == 'S');
    }, []);

    function getFile() {
        const dom = document.getElementById('file').files;
        if (dom && dom.length <= 0) {
            return;
        }
        var file = dom[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            usuarioService.dispatch(BlockuiService.start);
            const usuario = AuthService.getUser();
            usuarioService.salvarFoto({
                idUsuario: usuario.idUsuario,
                file: reader.result,
                nrSeqFoto: usuario.nrSeqFoto
            }).then(res => {
                setNrSeqFot(res.data.nrSeqFoto);
                setFoto(res.data.file);
                usuarioService.dispatch(BlockuiService.stop);
                usuarioService.dispatch(UserService.changeUser({username: user.nmPessoaFisica, foto: res.data.file}));

                AuthService.salvarInfoFoto({...usuario, nrSeqFoto: res.data.nrSeqFoto});
                ToastService.showSuccess(MessageUtil.OPERACAO_REALIZADA_SUCESSO);
            }, err => {
                ToastService.showError(MessageUtil.FALHA_REQUISICAO);
                usuarioService.dispatch(BlockuiService.stop);
            });
        };

        reader.onerror = function (error) {
            return error(true);
        };
    }

    function changePermissao() {
        const userT = AuthService.getUser();
        const novoEstado = userT.permSmsEmail == 'S' ? 'N' : 'S';
        const notificacao = {cdPessoaFisica: userT.cdPessoaFisica, situacao: novoEstado};
        usuarioService.dispatch(BlockuiService.start);
        usuarioService.permitirNotificacao(notificacao).then(res => {
            usuarioService.dispatch(BlockuiService.stop);
            setPermSmsEmail(novoEstado == 'S');
            TokenService.saveUser({...user, permSmsEmail: novoEstado});
            ToastService.showSuccess(MessageUtil.OPERACAO_REALIZADA_SUCESSO);
        });
    }


    return (
        <>
            <div className="configuracao-wrapper">
                <div className="configuracao-dados">
                    <Card id="configuracao-card">
                        <div className="configuracao-nome-pf">
                            <h3>
                                {user.nmPessoaFisica}
                            </h3>
                        </div>
                        <div className="configuracao-dados-wrapper">
                            <div className="configuracao-dados-item">
                                <div className="configuracao-dados-subitem">
                                    <h3
                                        className="configuracao-title">CPF</h3>
                                    <h4 className="configuracao-subtitle">{CpfPipe(user.nrDocumento)}</h4>
                                </div>
                                <div className="configuracao-dados-subitem">
                                    <h3
                                        className="configuracao-title">Data de nascimento</h3>
                                    <h4 className="configuracao-subtitle">{DataPipe(user.dtNascimento)}</h4>
                                </div>
                            </div>
                            <div className="configuracao-dados-item">
                                <div className="configuracao-dados-subitem">
                                    <h3
                                        className="configuracao-title">E-mail</h3>
                                    <h4 className="configuracao-subtitle">{user.dsEmail}</h4>
                                </div>
                                <div className="configuracao-dados-subitem">
                                    <h3
                                        className="configuracao-title">Nº de celular</h3>
                                    <h4 className="configuracao-subtitle">{TelefonePipe(user.nrTelefoneCelular)}</h4>
                                </div>
                            </div>
                            <div className="configuracao-dados-item">

                                <div className="configuracao-dados-subitem">
                                    <h3
                                        className="configuracao-title">Endereço</h3>
                                    <h4 className="configuracao-subtitle">{user.dsEndereco}</h4>
                                </div>
                            </div>
                            <div className="permitir-sms-email">
                                <Checkbox inputId="binary" onChange={() => changePermissao()} checked={permSmsEmail}/>

                                <h6>
                                    Receber notificações de nossos serviços e comunicações via e-mail/SMS
                                </h6>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="configuracao-acoes">
                    <Card id="configuracao-card">
                        <div className="configuracao-alterar-foto">
                            <ConditionalComponent
                                condition={foto == ''}
                                positive={
                                    <div className="configuracao-alterar-foto-icon">
                                        <label htmlFor="file">
                                            <i className="fas fa-camera camera-icon"></i>
                                        </label>
                                        <label htmlFor="file" className="editar-wrapper">
                                            <i className="fas fa-pencil-alt editar-icon"></i>

                                        </label>
                                    </div>
                                }
                                negative={
                                    <div className="configuracao-alterar-foto-img">
                                        <img src={foto} className="configuracao-foto"/>
                                        <label htmlFor="file" className="editar-wrapper-img">
                                            <i className="fas fa-pencil-alt editar-icon"></i>
                                        </label>
                                    </div>
                                }
                            />
                            <div className="configuracao-foto-recomendacao">
                                <h2 className="configuracao-recomendacao-title">Alterar imagem</h2>
                                <h3 className="configuracao-recomendacao-subtitle">(Recomendado 400x400px)</h3>
                            </div>
                        </div>
                    </Card>
                    <div className="menu-configuracao-btn">
                        <Button
                            label="Alterar senha"
                            type="button"
                            onClick={() => history.push('/alterar-senha')}
                            className="btn-menu-configuracao"
                        />
                    </div>
                    <div className="menu-configuracao-btn">
                        <Button
                            label="Termos de uso"
                            type="button"
                            onClick={() => {
                                usuarioService.obterTermos(user.idUsuario).then(res => {
                                    if (res.data && res.data.length > 0) {
                                        setShowDialog(true);
                                        const conteudo = document.getElementById('conteudo');
                                        conteudo.innerHTML = res.data[0].dsTermo;
                                        setTermo(res.data[0]);
                                    }
                                });
                            }}
                            className="btn-menu-configuracao"
                        />
                    </div>
                    <div className="menu-configuracao-btn">
                        <Button
                            label="Sair"
                            type="button"
                            onClick={() => history.push('/public/logout')}
                            className="btn-menu-configuracao-sair"
                        />
                    </div>
                </div>
            </div>
            <input type="file"
                   onChange={event => {
                       getFile();
                   }}
                   name="file" id="file" style={{visibility: 'hidden'}}/>

            <Dialog onHide={() => setShowDialog(false)}
                    blockScroll={true}
                    header="Termo de Uso" closable={false}
                    draggable={false} visible={showDialog} className="modal-termo" footer={
                <>
                    <Button
                        type="button"
                        label="Voltar"
                        onClick={() => setShowDialog(false)}
                    />
                </>
            }>
                <div id="conteudo"></div>
            </Dialog>
        </>
    );
}

