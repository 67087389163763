import {resultadoApi} from "../../../services/api";
import {AbstractService} from "../../../shared/abstracts/abstract.service";

export default class ResultadoExameService extends AbstractService {
    constructor() {
        super("", "", resultadoApi);
    }

    getTipos() {
        return [
            {
                nome: "Laboratorial",
                id: "L"
            },
            {
                nome: "Imagem",
                id: "I"
            },
            {
                nome: "Biópsia",
                id: "B"
            },
            {
                nome: "Checkup",
                id: "C"
            },
            {
                nome: "Holter",
                id: "H"
            }
        ];
    }


    getDataList() {
        return [
            {
                nome: "Antigos",
                id: "A"
            },
            {
                nome: "Recentes",
                id: "R"
            }
        ];
    }

    obterResultado(filtro) {
        return this.api.get(
            `${this.rota}/consultar/${filtro.tipo}/pessoa/${filtro.cdPessoaFisica}`
        );
    }

    obterResultadoAnterior(filtro, tipoExame) {
        return this.api.get(
            `${this.rota}/anteriores/${filtro}?tipoExame=${tipoExame}`
        );
    }

    obterLaudoAnterior(filtro) {
        return this.api.post(`${this.rota}/pdf/caminho`, filtro);
    }

    obterPdfLaboratorial(nrPrescricao) {
        return this.api.get(`${this.rota}/pdf/laboratorial/${nrPrescricao}`);
    }

    obterPdfImagem(nrProntuario, nrAcessoDicom) {
        debugger;
        return this.api.get(
            `${this.rota}/pdf/imagem/${nrProntuario}/dicom/${nrAcessoDicom}`
        );
    }

    obterExameImagem(row) {
        return this.api.post(`${this.rota}/laudo-imagem`, {
            nrProntuario: row.nrProntuario,
            numExame: row.numExame
        });
    }

    obterLaudoEvolutivo(row) {
        return this.api.post(`${this.rota}/laudo-evolutivo`, row);
    }

    search(filter) {
        const serialized = this.serialize(filter);
        return this.api.get(`${this.rota}/search?${serialized}`);
    }
}
