import React from "react";
import {ConditionalComponent} from "../../../../vendors/internalComponents/ConditionalComponent/ConditionalComponent";


export default function AgendamentoDocumento(props) {
    return (
        <>
            <div className="checkin-card">
                <h3>Upload de documento pessoal com foto:</h3>

                <div className="configuracao-alterar-foto">
                    <ConditionalComponent
                        condition={!props.controller.documento.arquivo}
                        positive={
                            <div className="configuracao-alterar-foto-icon">
                                <label htmlFor="file">
                                    <i className="fas fa-camera camera-icon"></i>
                                </label>
                            </div>
                        }
                        negative={
                            <div className="configuracao-alterar-foto-img">
                                <img src={props.controller.documento.arquivo} className="configuracao-foto" alt=""/>
                                <i className="fas fa-times-circle" onClick={() => props.controller.removeFile()}
                                   style={{fontSize: '25px', color: '#d25f78', marginRight: '20px'}}/>
                            </div>
                        }
                    />
                    <div className="configuracao-foto-recomendacao">
                        {
                            props.controller.documento.arquivo == '' ?
                                <h2 className="configuracao-recomendacao-title">Selecionar imagem do documento</h2>
                                :
                                <h2 className="configuracao-recomendacao-title">Imagem do documento selecionada</h2>
                        }
                    </div>
                </div>
                <input type="file"
                       onChange={event => {
                           props.controller.getFile();
                       }}
                       name="file" id="file" style={{visibility: 'hidden'}}/>
            </div>
        </>
    );
}
