export class TokenService {
    static saveAuthInfo(user) {
        sessionStorage.setItem("access_token", user.accessToken);
        sessionStorage.setItem("refresh_token", user.refreshToken);
        sessionStorage.setItem("user", JSON.stringify(user.user));
        sessionStorage.setItem("auth_info", JSON.stringify(user));
    }

    static saveUser(user) {
        sessionStorage.setItem("user", JSON.stringify(user));
    }

    static getAccessToken() {
        return sessionStorage.getItem("access_token");
    }

    static getAuthInfo() {
        const authInfo = sessionStorage.getItem("auth_info");
        if (!authInfo) {
            return "";
        }
        return JSON.parse(authInfo);
    }

    static getRefreshToken() {
        return sessionStorage.getItem("refresh_token");
    }

    static getUser() {
        const user = sessionStorage.getItem("user");
        if (!user) {
            return "";
        }
        return JSON.parse(user);
    }

    static limpar() {
        sessionStorage.setItem("access_token", "");
        sessionStorage.setItem("refresh_token", "");
        sessionStorage.setItem("user", "");
        sessionStorage.setItem("auth_info", "");
    }
}
