import React, {useEffect} from "react";

export default function CheckinUnidade(props) {

    useEffect(() => {
        props.controller.setUnidade(0);
    });

    return (
        <>
            <div className="checkin-card">
                <h3>Selecione a unidade:</h3>
                <div className="tipos-checkin-unidade">
                    <div onClick={() => props.controller.selectUnidade(11)}>
                        <h4>Cidade Jardim</h4>
                        <div className="unidades-img jardim">

                        </div>
                        <div className="nome-unidade">
                            Av. Cidade Jardim, 350 - 2 andar - Edifício Dacon - Jardim Paulistano - São Paulo -&nbsp; SP
                        </div>
                    </div>

                    <hr/>

                    <div onClick={() => props.controller.selectUnidade(1)}>
                        <h4>Paraíso</h4>
                        <div className="unidades-img paraiso">

                        </div>
                        <div className="nome-unidade">
                            Rua Desembargador Eliseu Guilherme, 130 - Paraíso - São Paulo - SP
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
