import React, {useEffect} from 'react';
import Container from "../../../components/Container/Container";
import Field from "../../../components/Field/Field";
import {InputText} from 'primereact/inputtext';
import {Dropdown} from "primereact/dropdown";
import classNames from "classnames";
import MonthTemplate, {YearTemplate} from "../../../components/Calendar/CalendarTemplate";
import {Calendar} from "primereact/calendar";


export default function Convenio(props) {

    useEffect(() => {
        props.controller.obterDadosConvenioPessoa();
    }, []);

    return (
        <>
            <Container>
                <Field col={12} breakLine={true}>
                    <h3 className="titulo-primario">Confirme as informações de convênio:</h3>
                </Field>
                <Field col={4} label="Convênio"
                       valid={props.controller.isFormFieldValidOtherForm('cdConvenio', props.controller.convenioForm)}>
                    <Dropdown
                        id="cdConvenio"
                        name="cdConvenio"
                        value={props.controller.convenioForm.values.cdConvenio}
                        emptyMessage="Nenhum registro encontrado"
                        emptyFilterMessage="Nenhum registro encontrado"
                        className={classNames({'p-invalid': !props.controller.isFormFieldValid('cdConvenio')})}
                        options={props.controller.convenioList}
                        filter
                        placeholder="Selecione"
                        onChange={(e) => {
                            props.controller.changeConvenio(e.target.value);
                            props.controller.convenioForm.handleChange(e);
                        }}
                        optionLabel="dsConvenio"
                        optionValue="cdConvenio"
                    />
                </Field>
                <Field col={4} label="Categoria"
                       valid={props.controller.isFormFieldValidOtherForm('cdCategoria', props.controller.convenioForm)}>
                    <Dropdown
                        id="cdCategoria"
                        name="cdCategoria"
                        emptyMessage="Nenhum registro encontrado"
                        emptyFilterMessage="Nenhum registro encontrado"
                        className={classNames({'p-invalid': !props.controller.isFormFieldValid('cdCategoria')})}
                        options={props.controller.categoriaList}
                        value={props.controller.convenioForm.values.cdCategoria}
                        disabled={!props.controller.convenioForm.values.cdConvenio}
                        filter
                        placeholder="Selecione"
                        onChange={(e) => {
                            props.controller.convenioForm.setFieldValue('cdPlano', null);

                            props.controller.convenioForm.handleChange(e);
                        }}
                        optionLabel="dsCategoria"
                        optionValue="cdCategoria"
                    />
                </Field>
                <Field col={4} label="Plano"
                       valid={props.controller.isFormFieldValidOtherForm('cdPlano', props.controller.convenioForm)}>
                    <Dropdown
                        id="cdPlano"
                        name="cdPlano"
                        value={props.controller.convenioForm.values.cdPlano}
                        emptyMessage="Nenhum registro encontrado"
                        emptyFilterMessage="Nenhum registro encontrado"
                        className={classNames({'p-invalid': !props.controller.isFormFieldValid('cdPlano')})}
                        options={props.controller.planoList &&
                            props.controller.planoList.filter(f => !props.controller.convenioForm.values.cdCategoria ||
                                (props.controller.convenioForm.values.cdCategoria == f.cdCategoria))
                        }
                        disabled={!props.controller.convenioForm.values.cdCategoria}
                        filter
                        placeholder="Selecione"
                        onChange={(e) => {
                            props.controller.convenioForm.handleChange(e);
                        }}
                        optionLabel="dsPlano"
                        optionValue="cdPlano"
                    />
                </Field>
                <Field col={4} label="Número da carteirinha"
                       valid={props.controller.isFormFieldValidOtherForm('nrCarteirinha', props.controller.convenioForm)}>
                    <InputText
                        id="nrCarteirinha"
                        name="nrCarteirinha"
                        maxLength={100}
                        value={props.controller.convenioForm.values.nrCarteirinha}
                        onChange={(e) => {
                            props.controller.convenioForm.handleChange(e);
                        }}
                    />
                </Field>
                <Field col={4} label="Validade"
                       valid={props.controller.isFormFieldValidOtherForm('dtValidade', props.controller.convenioForm)}>
                    <Calendar
                        id="dtValidade"
                        name="dtValidade"
                        value={props.controller.convenioForm.values.dtValidade}
                        onChange={(e) => {
                            props.controller.convenioForm.handleChange(e);
                        }}
                        autoFocus
                        yearNavigator
                        monthNavigator
                        monthNavigatorTemplate={MonthTemplate}
                        yearNavigatorTemplate={YearTemplate}
                        minDate={new Date()}
                        autoZIndex={true}
                        placeholder="dd/mm/aaaa"
                        mask="99/99/9999"
                        baseZIndex={6000}
                        yearRange="2000:2100"
                        dateFormat="dd/mm/yy"
                    />
                </Field>
            </Container>
        </>

    );
}
