import React, {useEffect, useState} from 'react';
import Pergunta from "./Pergunta";
import {Button} from "primereact/button";
import {ToastService} from "../../../services/utils/toast.service";

export default function Secao(props) {

    const perguntaList = props.model.perguntaList;

    const [indexGlobal, setIndexGlobal] = useState(0);
    const [isExcecao, setIsExcecao] = useState(false);
    const [listaAtual, setListaAtual] = useState([]);
    const [perguntaAtual, setPerguntaAtual] = useState({});


    useEffect(() => {
        setPerguntaAtual(perguntaList[0]);
        setListaAtual(perguntaList);
    }, [props.model]);

    function next(m) {
        const _isExcecao = !!m && !!m.perguntaList && m.perguntaList.length > 0;
        let _listaAtual = listaAtual;
        let indexAtual = _listaAtual.indexOf(perguntaAtual);
        let proximoIndex = indexAtual + 1;
        if (_isExcecao) {
            proximoIndex = 0;
            _listaAtual = m.perguntaList;
            setIsExcecao(true);
        } else {
            setIndexGlobal(proximoIndex);
        }
        if (proximoIndex >= _listaAtual.length) {
            if (isExcecao) {
                setListaAtual(perguntaList);
                if (proximoIndex >= perguntaList.length) {
                    ToastService.showError('Fim do questionário');
                    return;
                }
                setPerguntaAtual(perguntaList[indexGlobal]);
                setIsExcecao(false);
                return;
            }
            ToastService.showError('Fim do questionário');
            return;
        }
        setPerguntaAtual(_listaAtual[proximoIndex]);
        setListaAtual(_listaAtual);
    }


    function previous() {
        const indexAtual = listaAtual.indexOf(perguntaAtual);
        const indexAnterior = indexAtual - 1;
        if (indexAnterior < 0) {
            ToastService.showError('Impossível voltar mais.');
            return;
        }
        setPerguntaAtual(listaAtual[indexAnterior]);
        setIndexGlobal(indexAnterior);
    }

    return (
        <>
            <h3>{props.model.nmSecao}</h3>
            <Pergunta next={(m) => next(m)} model={perguntaAtual}/>
            <br/>
            <Button
                label="Anterior"
                onClick={() => previous()}
            />
            <Button
                label="Próximo"
                onClick={() => next()}
            />


        </>
    );
}
