import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Link, useHistory} from "react-router-dom";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {ToastService} from "../../services/utils/toast.service";
import MessageUtil from "../../shared/utils/MessageUtil";
import AuthService from "../../services/auth.service";
import {useDispatch} from "react-redux";
import {BlockuiService} from "../../services/blockui.service";

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const authService = new AuthService();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const user = AuthService.getUser();
        if (user) {
            authService.check().then(res => {
                history.push('/private/home');
            });
        }
    }, []);


    let login = (e) => {
        let valido = true;
        if (!username) {
            ToastService.showError(MessageUtil.CAMPO_LOGIN_OBRIGATORIO);
            valido = false;
        }
        if (!password) {
            setTimeout(() => {
                ToastService.showError(MessageUtil.CAMPO_SENHA_OBRIGATORIO);
                valido = false;
            }, 200);
        }
        if (valido) {
            dispatch(BlockuiService.start);
            authService.doLogin(username, password);
        }
    };


    return (
        <>
            <form onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>

                <div className="public-content-container">
                    <div className="hcor-logo">

                        <img width="300px" src="/media/hcor/logo-hcor.png"/>

                    </div>


                    <div className="public-login-container">

                        <div className="public-input-group">
                            <label className="public-icon">

                                <i className="fas fa-user "></i>
                            </label>
                            <InputText maxLength={100}
                                       value={username}
                                       onChange={(e) => setUsername(e.target.value)}
                                       placeholder="CPF ou E-mail"
                                       className="public-input"/>
                        </div>
                        <div className="public-input-group">
                            <label className="public-icon">
                                <i className="fas fa-lock "></i>
                            </label>
                            <Password placeholder="Senha"
                                      value={password}
                                      onChange={(e) => setPassword(e.target.value)}
                                      maxLength={50}
                                      className="public-input"
                                      feedback={false}
                                      toggleMask={true}/>
                        </div>

                        <div className="public-input-group public-esqueci">
                            <Link to="/public/recover">
                                Esqueci minha senha
                            </Link>
                        </div>

                        <div className="public-button-group">
                            <Button
                                type="submit"
                                onClick={(e) =>
                                    login(e)
                                }
                                className="public-button public-blue-button">Entrar</Button>
                        </div>

                        <div className="public-input-group"
                             style={{fontFamily: 'TTNormsMedium, sans-serif', textAlign: "center", color: "grey"}}>
                            <h4>Não possui acesso?</h4>
                        </div>
                        <div className="public-button-group">
                            <Link to="/public/cadastro">
                                <Button className="public-button public-azul-button">Criar senha de acesso</Button>
                            </Link>
                        </div>

                        <div className="public-login-contato-group">
                            <div>
                                <a href="https://www.hcor.com.br/imprensa/noticias/informacoes-gerais-para-o-atendimento-no-portal-do-paciente/"
                                   target="_blank">
                                    <Button style={{background: '#d47c54'}} className="btn-rounded-icon">
                                        <i className="fas fa-question"/>
                                    </Button>
                                </a>
                                <label>dúvidas frequentes</label>
                            </div>
                            <div>
                                <Link to="/public/fale-conosco">
                                    <Button className="btn-rounded-icon"
                                            style={{background: '#153554'}}
                                    >
                                        <i className="fas fa-phone"/>
                                    </Button>
                                </Link>
                                <label>fale conosco</label>
                            </div>
                            <div style={{marginRight: '0'}}>
                                <a href="https://portalmedico.hcor.com.br"
                                   target="_blank">
                                    <Button className="btn-rounded-icon"
                                            style={{background: 'var(--azul-hcor)'}}>
                                        <i className="fas fa-user-md"/>
                                    </Button>
                                </a>
                                <label>Portal Médico</label>
                            </div>
                        </div>

                        <div className="public-input-group">

                        </div>


                        <div className="public-button-group public-talk">

                        </div>
                    </div>
                </div>
            </form>
        </>
    );

}
