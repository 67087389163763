import {AbstractService} from "../../../shared/abstracts/abstract.service";
import {agendamentoApi} from "../../../services/api";
import DataUtil from "../../../shared/utils/DataUtil";
import AuthService from "../../../services/auth.service";

export default class AgendamentoService extends AbstractService {
    constructor() {
        super("", "", agendamentoApi);
    }

    consultaAgendamentoDisponivel = cdPessoaFisica => {
        return this.api.get(`${this.rota}/checkin/disponivel/${cdPessoaFisica}`);
    };

    obterAgendamentos(filtro) {
        return this.api.post(`${this.rota}/consultar`, filtro);
    }

    confirmarAgendamento(agendamento) {
        return this.api.post(`${this.rota}/confirmar`, agendamento);
    }

    reverterAgendamento(agendamento) {
        return this.api.post(`${this.rota}/reverter`, agendamento);
    }

    cancelarAgendamento(agendamento) {
        return this.api.post(`${this.rota}/cancelar`, agendamento);
    }

    obterEspecialidade(cdPaciente) {
        return this.api.get(`/especialidade?cdPaciente=${cdPaciente}`);
    }

    obterMedicoEspecialidade(cdEspecialidade, cdEstabelecimento) {
        let query = '?cdPaciente=' + AuthService.getUser().cdPessoaFisica;
        if (cdEspecialidade) {
            query += '&cdEspecialidade=' + cdEspecialidade;
        }
        if (cdEstabelecimento) {
            query += '&cdEstabelecimento=' + cdEstabelecimento;
        }
        return this.api.get(`/medico-especialidade/search${query}`);
    }

    obterConvenio() {
        return this.api.get(`/convenio`);
    }

    obterCategoriaConvenio(value) {
        return this.api.get(`/convenio/categoria/${value}`);
    }

    obterPlanoConvenio(value) {
        return this.api.get(`/convenio/plano/${value}`);
    }

    obterConvenioPessoa(value) {
        return this.api.get(`/convenio/pessoa/${value}`);
    }


    gerarAgendamento(gerar) {
        return this.api.post(`/agenda/gerar`, gerar);
    }

    gerarHorario(gerar) {
        return this.api.post(`/agenda/gerar/horario`, gerar);
    }

    enviarDocumentos(lista) {
        return this.api.post(`${this.rota}/documento/lista`, lista);
    }

    enviarDocumento(lista) {
        return this.api.post(`${this.rota}/documento`, lista);
    }

    confirmarAgenda(agenda) {
        return this.api.post("/agenda/confirmar", agenda);
    }

    obterValor(nrSeqAgeint) {
        return this.api.get(`/agenda/valor/${nrSeqAgeint}`);
    }

    validarPrimeiraConsulta(filter) {
        const serialized = this.serialize(filter);
        return this.api.get(`/agenda/primeira-consulta?${serialized}`);
    }

    obterDiasAgenda(filter) {
        const serialized = this.serialize(filter);
        return this.api.get(`/agenda/dias/mes?${serialized}`);
    }

    consisteAgendamento(body) {
        return this.api.post(`/agenda/consistir`, body);
    }

    validarHorario(body) {
        return this.api.post(`/agenda/validar-horario`, body);
    }

    getPeriodos() {
        return [
            {
                nome: "Anteriores",
                id: 0
            },
            {
                nome: "Atuais",
                id: 1
            },
            {
                nome: "Ambos",
                id: 2
            }
        ];
    }

    obterDatas() {
        const hoje = new Date();
        const ontem = new Date();
        ontem.setDate(ontem.getDate() - 1);
        const dia0 = new Date();
        dia0.setFullYear(1900, 0, 1);
        const d90 = new Date();
        d90.setDate(d90.getDate() + 90);

        return [
            {
                dataInicio: DataUtil.formataData(dia0),
                dataFim: DataUtil.formataData(ontem)
            },
            {
                dataInicio: DataUtil.formataData(hoje),
                dataFim: DataUtil.formataData(d90)
            },
            {
                dataInicio: DataUtil.formataData(dia0),
                dataFim: DataUtil.formataData(d90)
            }
        ];
    }

    getTipoDocumentoList() {
        return [
            {
                label: "Selecione",
                value: -1
            },
            {
                label: "Documento pessoal",
                value: 1
            },
            {
                label: "Carteirinha do convênio",
                value: 2
            },
            {
                label: "Pedido médico",
                value: 3
            },
            {
                label: "Relatório médico",
                value: 4
            },
            {
                label: "Laudo de exames",
                value: 5
            },
            {
                label: "Formulário",
                value: 6
            }
        ];
    }

    getTipoDocumentoByIndex(index) {
        return this.getTipoDocumentoList()[index];
    }

    getTituloByIndex(index) {
        const lista = [
            "",
            "do documento pessoal",
            "da carteirinha do convênio",
            "do pedido médico",
            "do relatório médico",
            "do laudo de exames",
            "do formulário"
        ];
        return lista[index];
    }
}
