import React from "react";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";

export default function FaleConosco() {

    return (<>

        <div className="public-content-container">
            <div className="public-login-container">
                <div className="public-input-group">
                    <h6 className="public-fale-conosco-contato">
                        Em caso de dúvida, entre em contato com nossos canais de atendimento:
                    </h6>
                </div>
                <div className="public-input-group">
                    <h6 className="public-fale-conosco">
                        Agendamento de consultas e exames
                    </h6>
                    <h6 className="public-fale-conosco-contato">
                        Telefone: <a href="tel:1138896597">(11) 3889-3939</a><br/>

                        E-mail: <a href="mailito:agendapaciente@hcor.com.br">agendapaciente@hcor.com.br</a> <br/>

                    </h6>
                </div>
                <div className="public-input-group">
                    <h6 className="public-fale-conosco">
                        Ouvidoria
                    </h6>
                    <h6 className="public-fale-conosco-contato">
                        Telefone: <a href="tel:1130536597">(11) 3053-6597</a><br/>

                        E-mail: <a href="mailito:ouvidoria@hcor.com.br">ouvidoria@hcor.com.br</a> <br/>

                    </h6>
                </div>
                <div className="public-button-group" style={{marginTop: '30px',}}>
                    <Link to="/public/login">
                        <Button className="public-button" style={{
                            background: 'white',
                            border: '1px solid #A5A5A5',
                            color: '#A5A5A5'
                        }}>Voltar</Button>
                    </Link>
                </div>
            </div>
        </div>
    </>);
}
