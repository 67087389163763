import React, {useEffect} from 'react';
import GerarAgendamentoController from "../controllers/gerar-agendamento.controller";
import {Card} from "primereact/card";
import TipoAgendamento from "../components/TipoAgendamento";
import '../meus-agendamentos.scss'
import Bullets from "../../../components/Bullets/Bullets";
import MedicoEspecialidade from "../components/MedicoEspecialidade";
import DataAgendamento from "../components/DataAgendamento";
import TipoAtendimento from "../components/TipoAtendimento";
import AgendamentoCarteirinha from "../components/AgendamentoCarteirinha";
import AgendamentoDocumento from "../components/AgendamentoDocumento";
import Convenio from "../components/Convenio";
import ConfirmarAgendamento from "../components/ConfirmarAgendamento";
import AgendamentoConstants from "../utils/agendamento.constants";
import Estabelecimento from "../components/Estabelecimento";

export default function GerarAgendamento() {

    const controller = new GerarAgendamentoController();

    useEffect(() => {
        controller.init();
    }, []);

    function getTemplate() {
        if (controller.step === AgendamentoConstants.TIPO_AGENDAMENTO) {
            return (<TipoAgendamento controller={controller}/>);
        }
        if (controller.step === AgendamentoConstants.ESTABELECIMENTO) {
            return (<Estabelecimento controller={controller}/>);
        }

        if (controller.step === AgendamentoConstants.TIPO_ATENDIMENTO) {
            return (<TipoAtendimento controller={controller}/>);
        }
        if (controller.step === AgendamentoConstants.CONVENIO) {
            return (<Convenio controller={controller}/>)
        }
        if (controller.step === AgendamentoConstants.MEDICO_ESPECIALIDADE) {
            return (<MedicoEspecialidade controller={controller}/>);
        }

        if (controller.step === AgendamentoConstants.DATA_AGENDAMENTO) {
            return (<DataAgendamento controller={controller}/>);
        }

        if (controller.step === AgendamentoConstants.CARTEIRINHA) {
            return (<AgendamentoCarteirinha controller={controller}/>);
        }
        if (controller.step === AgendamentoConstants.DOCUMENTO) {
            return (<AgendamentoDocumento controller={controller}/>);
        }
        if (controller.step === AgendamentoConstants.CONFIRMAR) {
            return (<ConfirmarAgendamento controller={controller}/>);
        }

    }

    return (
        <>
            <Card className="form-card">
                <div className="wrapper-card">
                    {getTemplate()}
                </div>
            </Card>
            <Bullets controller={controller}/>
        </>
    );
}
