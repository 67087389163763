import {ConfigService} from "./config.service";
import AuthService from "./auth.service";

export class Interceptors {
    static requestInterceptor = request => {
        const headers = request.headers;
        const actualHeaders = ConfigService.getHeaders();

        if (actualHeaders.headers.Authorization) {
            headers["Authorization"] =
                "Bearer " + actualHeaders.headers.Authorization;
        } else {
            headers["Authorization"] = "";
        }
        return request;
    };

    static responseInterceptorSuccess(response) {
        return response;
    }

    static responseInterceptorError(error, api) {
        if (
            error &&
            error.response &&
            error.response.status &&
            (error.response.status == 401 || error.response.status == 403) &&
            error.response.request.responseURL.indexOf("login") < 0
        ) {
            window.location.href = "/logout";
            AuthService.limpar();
        }
        return Promise.reject(error.response);
    }
}
