import AbstractController from "../../../shared/abstracts/abstract.controller";
import ResultadoExameFilter from "../model/resultado-exame.filter";
import ResultadoExameService from "../service/resultado-exame.service";
import AuthService from "../../../services/auth.service";
import {useState} from "react";
import {BlockuiService} from "../../../services/blockui.service";
import {ToastService} from "../../../services/utils/toast.service";
import {FileService} from "../../../services/file.service";
import DataUtil from "../../../shared/utils/DataUtil";

export default class ResultadoExameController extends AbstractController {

    constructor() {
        super();
        [this.lista, this.setLista] = useState([]);
        [this.isRequest, this.setIsRequest] = useState(false);
        this.filter = new ResultadoExameFilter();
        this.service = new ResultadoExameService();
        this.fileService = new FileService();
        this.tipoList = this.service.getTipos();

    }


    init() {
        super.init();
        this.search({ieTipoExame: 'L'});
    }

    search(filter) {
        if (this.isRequest) {
            return;
        }
        const _filter = {...filter};
        _filter.cdPaciente = AuthService.getUser().cdPessoaFisica;
        _filter.nrProntuario = AuthService.getUser().nrProntuario;
        this.start();
        this.setIsRequest(true);
        if (!!_filter.dtExame) {
            _filter.dtExame = DataUtil.formataData(_filter.dtExame);
        }
        this.service.search(_filter).then(res => {
            this.setIsRequest(false);
            this.stop();
            this.setLista(res.data);
        }).catch(err => {
            this.setIsRequest(false);
            this.stop()
        });
    }

    obterLaudo(row, item) {
        debugger;
        let request;
        const user = AuthService.getUser();
        if (this.filter.ieTipoExame === 'L' && item.ieAnterior === 'N') {
            request = this.service.obterPdfLaboratorial(row.nrPrescricao);
        } else if (this.filter.ieTipoExame === 'I' && item.ieAnterior === 'N') {
            request = this.service.obterPdfImagem(user.nrProntuario, row.nrAcessoDicom);
        } else {
            request = this.service.obterLaudoAnterior({dsCaminhoResult: row.dsCaminhoResult});
        }

        this.start();
        request.then(res => {
            this.fileService.downloadFile(res.data.arquivo, 'resultado_exame', 'application/pdf')
            this.service.dispatch(BlockuiService.stop);
        }, err => {
            this.service.dispatch(BlockuiService.stop);
            ToastService.showError('Resultado não encontrado');
        });
    }


    obterExameImagem(row) {
        console.log(row);
        this.start();
        this.service.obterExameImagem({
            nrProntuario: AuthService.getUser().nrProntuario,
            numExame: row.nrAcessoDicom
        }).then(res => {
            window.open(res.data.url, '_blank');
            this.stop();
        }, err => {
            this.stop();
            ToastService.showError('Resultado não encontrado');
        });
    }

    obterLaudoEvolutivo(row) {
        this.service.dispatch({
            blocked: true,
            type: 'start-message',
            message: 'Estamos montando o laudo evolutivo'
        });
        this.service.obterLaudoEvolutivo({
            nrPrescricao: row.nrPrescricao,
            nrProntuario: AuthService.getUser().nrProntuario
        }).then(res => {
            this.fileService.downloadFile(res.data.pdf, 'resultado_exame', 'application/pdf')
            this.service.dispatch(BlockuiService.stop);
        }, err => {
            this.service.dispatch(BlockuiService.stop);
            ToastService.showError('Resultado não encontrado');
        });
    }

}
