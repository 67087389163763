import React from "react";
import {EmptyComponent} from "../../../../vendors/internalComponents/EmptyComponent/EmptyComponent";

export default function CheckinPedido(props) {

    const pedidoImagem = (pedido, index) =>
        <div className="configuracao-alterar-foto-img" key={index}>
            <img src={pedido.fileImage} className="configuracao-foto" alt=""/>
            <i className="fas fa-times-circle" onClick={() => props.controller.removerPedido(pedido, index)}
               style={{fontSize: '25px', color: '#d25f78', marginRight: '20px'}}/>
        </div>
    ;

    const pedidoUpload = <>
        <div className="configuracao-alterar-foto">
            <div className="configuracao-alterar-foto-icon">
                <label htmlFor="file">
                    <i className="fas fa-camera camera-icon"></i>
                </label>
            </div>
            <div className="configuracao-foto-recomendacao">
                <h2 className="configuracao-recomendacao-title">Selecionar imagem do pedido</h2>
            </div>

        </div>
        <input type="file"
               onChange={event => {
                   props.controller.getFile();
               }}
               name="file" id="file" style={{visibility: 'hidden'}}/>
    </>

    return (
        <>
            <div className="checkin-card">
                <h3>Upload pedido médico:</h3>
                <EmptyComponent condition={props.controller.pedidos.length < 5}
                                body={pedidoUpload}
                />
                <div className="checkin-pedidos-wrapper">
                    {props.controller.pedidos.map((m, index) => pedidoImagem(m, index))}
                </div>


            </div>
        </>
    );
}
