import React from "react";
import Calendar from "react-calendar";
import '../style.scss'
import 'react-calendar/dist/Calendar.css';

export default function CheckinData(props) {
    return (
        <div className="checkin-card">
            <h3>Selecione a data do exame laboratorial:</h3>
            <div className="Sample__container">
                <main className="Sample__container__content">
                    <Calendar
                        id="checkin-calendario"
                        onChange={props.controller.setDataSelect}
                        minDate={props.controller.minDate}
                        maxDate={props.controller.maxDate}
                        locale={'pt-BR'}
                        value={props.controller.dataSelect}
                    />
                </main>
            </div>
        </div>
    );
}
