import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";

import {Card} from "primereact/card";
import {SelectButton} from "primereact/selectbutton";
import AgendamentoService from "../services/agendamento.service";
import '../meus-agendamentos.scss';
import AuthService from "../../../services/auth.service";
import {BlockuiService} from "../../../services/blockui.service";
import {EmptyComponent} from "../../../../vendors/internalComponents/EmptyComponent/EmptyComponent";
import {DataPipe, DataTempoPipe, TempoPipe, UnidadePipe} from "../../../../vendors/internalComponents/Pipe/Pipe";
import {Button} from "primereact/button";
import {ToastService} from "../../../services/utils/toast.service";
import MessageUtil from "../../../shared/utils/MessageUtil";
import DataUtil from "../../../shared/utils/DataUtil";
import {Dialog} from "primereact/dialog";
import AnalyticsUtil from "../../../shared/utils/AnalyticsUtil";
import {white} from "colorette";
import {useSelector} from "react-redux";
import {If} from "../../../../vendors/internalComponents/If/If";

export default function MeusAgendamentos() {
    const history = useHistory();

    const [expaded, setExpaded] = useState(Array);

    const [lista, setLista] = useState([]);
    const [periodo, setPeriodo] = useState(1);
    const agendamentoService = new AgendamentoService();
    const periodoList = agendamentoService.getPeriodos();
    const user = AuthService.getUser();
    const [visible, setVisible] = useState(false);
    const [action, setAction] = useState('CN');
    const [agendamento, setAgendamento] = useState({});
    const [titulo, setTitulo] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const item = false;
    const datas = agendamentoService.obterDatas();
    const state = useSelector(state => state);


    useEffect(() => {
        AnalyticsUtil.acessoAPagina('meus_agendamentos');
        listar(1);
    }, []);

    function listar(index) {
        if (index == null) {
            index = 2;
        }
        agendamentoService.dispatch(BlockuiService.start);
        agendamentoService.obterAgendamentos({
            ...datas[index], cdPessoaFisica: user.cdPessoaFisica
        }).then(res => {
            agendamentoService.dispatch(BlockuiService.stop);
            setLista(res.data);

            let initialExpaded = res.data.map(() => false);
            const newExpaded = [...initialExpaded];
            newExpaded[0] = true;
            setExpaded(newExpaded);
        });
    }

    const footer = (<>
        <Button
            label="Não"
            type="button"
            icon="pi pi-times"
            onClick={() => {
                setShowDialog(false);
            }}
            className="p-button-text"
        />
        <Button
            type="button"
            label="Sim"
            icon="pi pi-check"
            onClick={() => doAction('CA', agendamento)}
        />
    </>);

    function onHide() {
        setVisible(false);
    }

    function handleVisibleScheduling(i) {
        const newExpaded = [...expaded];
        newExpaded[i] = !newExpaded[i];
        setExpaded(newExpaded);
    }

    function confirmarAgendamento(agendamento) {
        agendamentoService.dispatch(BlockuiService.start);
        agendamentoService.confirmarAgendamento({
            cdPessoaFisica: user.cdPessoaFisica,
            ieStatusAgendamento: agendamento.ieStatusAgendamento,
            nrSeqAgenda: agendamento.nrSequencia,
            dtAgenda: agendamento.dtAgenda.substring(0, 10),
            ieAutorizacao: 'A'
        }).then(res => {
            agendamentoService.dispatch(BlockuiService.stop);
            ToastService.showSuccess(MessageUtil.CONFIRMAR_AGENDAMENTO_SUCESSO);
            listar(periodo);
        }).catch(error => {
            agendamentoService.dispatch(BlockuiService.stop);
            ToastService.showError(MessageUtil.CONFIRMAR_AGENDAMENTO_FALHA);
        });
    }


    function reverterAgendamento(agendamento) {
        agendamentoService.dispatch(BlockuiService.start);
        agendamentoService.reverterAgendamento({
            cdPessoaFisica: user.cdPessoaFisica,
            ieStatusAgendamento: agendamento.ieStatusAgendamento,
            nrSeqAgenda: agendamento.nrSequencia,
            dtAgenda: agendamento.dtAgenda.substring(0, 10),
            ieAutorizacao: agendamento.ieAutorizacao,
            ieCheckin: agendamento.ieCheckin
        }).then(res => {
            agendamentoService.dispatch(BlockuiService.stop);
            ToastService.showSuccess(MessageUtil.REVERTER_AGENDAMENTO_SUCESSO);
            listar(periodo);
        }).catch(error => {
            agendamentoService.dispatch(BlockuiService.stop);
            ToastService.showError(MessageUtil.REVERTER_AGENDAMENTO_FALHA);
        });
    }


    function cancelarAgendamento(agendamento) {
        agendamentoService.dispatch(BlockuiService.start);
        agendamentoService.cancelarAgendamento({
            nrSequencia: agendamento.nrSequencia,
            nrSeqAgenda: agendamento.nrSeqAgenda,
            cdEstabelecimento: agendamento.cdEstabelecimento,
            cdPessoaFisica: user.cdPessoaFisica,
            dtAgenda: agendamento.dtAgenda.substring(0, 10)
        }).then(res => {
            agendamentoService.dispatch(BlockuiService.stop);
            ToastService.showSuccess(MessageUtil.CANCELAR_AGENDAMENTO_SUCESSO);
            listar(periodo);
        }).catch(error => {
            agendamentoService.dispatch(BlockuiService.stop);
            ToastService.showError(MessageUtil.CANCELAR_AGENDAMENTO_FALHA);
        });
    }


    function checkin(item) {
        history.push({
            pathname: '/checkin/agendado'
        });
    }

    function callAction(action, agendamento) {
        setAgendamento(agendamento);
        setAction(action);
        if (action == 'CN') {
            doAction(action, agendamento)
        } else if (action == 'CA') {
            setTitulo('cancelar');
            setShowDialog(true);
        } else if (action == 'RV') {
            doAction(action, agendamento);
        }
    }

    function doAction(action, ag = null) {
        if (action == 'CN') {
            confirmarAgendamento(ag);
        } else if (action == 'CA') {
            cancelarAgendamento(agendamento);
        } else if (action == 'RV') {
            reverterAgendamento(ag);
        }
        setShowDialog(false);
    }

    function obterCor(agendamento) {
        if (agendamento.ieStatusAgendamento == 'CN') {
            return 'cor-confirmar';
        }
        if (agendamento.ieStatusAgendamento == 'C') {
            return 'cor-cancelar';
        }
        return '';
    }

    function procedimentoPipe(n) {
        if (n.ieStatusLaudo && n.ieStatusLaudo >= 20) {
            return <Link to="/resultado-exame">{n.dsProcedimento}</Link>
        }
        return <>{n.dsProcedimento}</>
    }

    return (<>
        <Card id="agendamento-card">
            <div className="wrapper-card">
                <div className="agendamento-filtro">
                    <div className="agendamento-separator">
                        <h3>Filtro</h3>

                        <SelectButton optionLabel="nome"
                                      optionValue="id"
                                      id="agenda-periodo"
                                      options={periodoList}
                                      value={periodo}
                                      onChange={(e) => {
                                          setPeriodo(e.value);
                                          listar(e.value);
                                      }}
                        >
                        </SelectButton>
                    </div>
                    <If condition={state.user && state.user.ieFuncionario === 'S'}>
                        <div className="agendamento-separator">
                            <Link to="/meus-agendamentos/gerar">
                                <Button style={{
                                    flex: '1', flexDirection: 'row',
                                    alignItems: "center"
                                }} tooltip="Gerar agendamento" className="btn-gerar-agendamento">
                                    <i className="fas fa-calendar-plus"
                                       style={{
                                           color: 'white',
                                           marginLeft: '10px',
                                           marginRight: '10px'
                                       }}
                                    />
                                    Agendar consulta
                                </Button>
                            </Link>
                        </div>
                    </If>
                </div>

                {lista.map((m, index) => <div className="lista-agendamentos" key={index}>

                    <div key={index} className="agendamento-list">
                        <h3>{DataUtil.formataDataPorExtenso(m.dtAgenda.substring(0, 10))}</h3>
                        <span
                            style={{cursor: 'pointer'}}
                            onClick={() => handleVisibleScheduling(index)}>{!expaded[index] ? 'detalhar' : 'ocultar'}</span>
                    </div>

                    {expaded[index] && <div>
                        <table className="content-table mobile-table">
                            <tbody>
                            {m.lista.map((n, index) => <tr key={index}>
                                <td>
                                    <span className="column-mobile">
                                             {
                                                 n.nrSeqAutorizacao && n.showUpload &&
                                                 <i style={{fontSize: '1.2em', cursor: 'pointer'}}
                                                    onClick={() => {
                                                        history.push('/meus-agendamentos/documentos/' + n.nrSeqAutorizacao)
                                                    }}
                                                    className='fas fa-paperclip'/>
                                             }
                                    </span>
                                    <span className="column-mobile">
                                                            <b>Data: </b>
                                        {DataPipe(n.dtAgenda.substring(0, 10))}
                                                        </span>
                                    <span className="column-mobile">
                                                            <b>Horário: </b>
                                        {TempoPipe(n.dtAgenda)}
                                                        </span>
                                    <span className="column-mobile">
                                                            <b>Serviço: </b>
                                        {procedimentoPipe(n)}
                                                        </span>
                                    <span className="column-mobile">
                                                            <b>Unidade: </b>
                                        {UnidadePipe(n.dsEstabelecimento)}
                                                        </span>
                                    <span className="column-mobile">
                                                            <b>Tipo: </b>
                                        {n.dsTipoAgenda}
                                                        </span>
                                    <span className="column-mobile">
                                                            <b>Status: </b>
                                                            <label className={obterCor(n)}>
                                                                {n.dsStatusAgendamento}
                                                            </label>
                                                        </span>
                                    <span className="column-mobile">
                                                            <b>Status de Autorização: </b>
                                                            <label>
                                                                {n.dsAutorizacao}

                                                            </label>
                                                        </span>
                                    <span className="column-mobile"
                                          style={{marginTop: '10px', alignContent: 'center'}}>
                                                            <div className="btn-agendamento"
                                                                 style={{justifyContent: 'space-between'}}>
                                                                <EmptyComponent condition={n.showConfirmar || item}
                                                                                body={<Button
                                                                                    onClick={() => callAction('CN', n)}
                                                                                    className="confirmar-agendamento-mobile">
                                                                                    Confirmar
                                                                                </Button>}
                                                                />
                                                                <EmptyComponent condition={n.showReverter || item}
                                                                                body={<Button
                                                                                    onClick={() => callAction('RV', n)}
                                                                                    className="reverter-agendamento">
                                                                                    Reverter
                                                                                </Button>}
                                                                />
                                                                <EmptyComponent condition={n.showCancelar || item}
                                                                                body={<Button
                                                                                    onClick={() => callAction('CA', n)}
                                                                                    className="cancelar-agendamento">
                                                                                    Cancelar
                                                                                </Button>}
                                                                />

                                                            </div>
                                                        </span>
                                </td>
                            </tr>)}
                            </tbody>
                        </table>

                        <table className="content-table desktop-table">
                            <thead>
                            <tr>
                                <th></th>
                                <th>Data</th>
                                <th>Horário</th>
                                <th>Serviço</th>
                                <th>Unidade</th>
                                <th>Tipo</th>
                                <th>Status</th>
                                <th>Status de Autorização</th>
                                <th>Ação</th>
                            </tr>
                            </thead>
                            <tbody>
                            {m.lista.map((n, index) => <tr key={index}>
                                <td style={{width: '5%'}}>
                                    {
                                        n.nrSeqAutorizacao && n.showUpload &&
                                        <i style={{fontSize: '1.2em', cursor: 'pointer'}}
                                           onClick={() => {
                                               history.push('/meus-agendamentos/documentos/' + n.nrSeqAutorizacao)
                                           }}
                                           className='fas fa-paperclip'/>
                                    }

                                </td>
                                <td style={{width: '5%'}}>{DataPipe(n.dtAgenda.substring(0, 10))}</td>
                                <td style={{width: '5%'}}>{TempoPipe(n.dtAgenda)}</td>
                                <td style={{width: '20%'}}>{procedimentoPipe(n)}</td>
                                <td style={{width: '10%'}}>{UnidadePipe(n.dsEstabelecimento)}</td>
                                <td style={{width: '10%'}}>{n.dsTipoAgenda}</td>
                                <td style={{width: '10%'}}
                                    className={obterCor(n)}>{n.dsStatusAgendamento}</td>
                                <td style={{width: '5%'}}>{n.dsAutorizacao}</td>
                                <td style={{width: '25%'}}>
                                    <div className="btn-agendamento">
                                        <EmptyComponent condition={n.showConfirmar || item}
                                                        body={<Button
                                                            onClick={() => callAction('CN', n)}
                                                            tooltip="Confirmar"
                                                            className="confirmar-agendamento">
                                                            Confirmar
                                                        </Button>}
                                        />
                                        <EmptyComponent condition={n.showReverter || item}
                                                        body={<Button
                                                            onClick={() => callAction('RV', n)}
                                                            tooltip="Reverter"
                                                            className="reverter-agendamento">
                                                            Reverter
                                                        </Button>}
                                        />
                                        <EmptyComponent condition={n.showCancelar || item}
                                                        body={<Button
                                                            onClick={() => callAction('CA', n)}
                                                            tooltip="Cancelar"
                                                            className="cancelar-agendamento">
                                                            Cancelar
                                                        </Button>}
                                        />

                                        <EmptyComponent
                                            condition={((m.showCheckin && n.showCancelar && n.ieStatusAgendamento == 'CN') || item)}
                                            body={<Button
                                                onClick={() => checkin(n)}
                                                tooltip="Checkin"
                                                className="checkin-agendamento">
                                                <i className="fas fa-map-marker-alt"
                                                   style={{color: 'white'}}/>
                                            </Button>}
                                        />

                                    </div>
                                </td>
                            </tr>)}
                            </tbody>
                        </table>
                    </div>}
                    <hr/>
                </div>)}
            </div>
            <Dialog
                header={<>
                    <h4 style={{color: '#2364AA'}}>Atenção</h4>
                </>}
                draggable={false}
                blockScroll={true}
                closable={false}
                visible={showDialog}
                className="modal-termo" footer={footer}>
                <div style={{lineHeight: '1.8em', fontSize: '1.3em'}}>
                    Você tem certeza que deseja {titulo} o agendamento abaixo?
                    <h4 style={{color: '#2364AA'}}>Serviço</h4>
                    <label>{agendamento.dsProcedimento}</label>
                    <h4 style={{color: '#2364AA'}}>Data</h4>
                    <label>{DataTempoPipe(agendamento.dtAgenda)}</label>
                </div>
            </Dialog>

        </Card>
    </>)

}
