import React, {useMemo} from "react";
// import {Link} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {Link} from "react-router-dom";

export function HeaderMobile() {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            headerLogo: uiService.getStickyLogo(),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            headerMenuSelfDisplay:
                objectPath.get(uiService.config, "header.menu.self.display") === true,
            headerMobileCssClasses: uiService.getClasses("header_mobile", true),
            headerMobileAttributes: uiService.getAttributes("header_mobile")
        };
    }, [uiService]);

    return (
        <>
            {/*begin::Header Mobile*/}
            <div
                id="kt_header_mobile"
                className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
                {...layoutProps.headerMobileAttributes}
            >
                {/*begin::Logo*/}
                {/* <Link to="/">
            <img alt="logo" src={toAbsoluteUrl("/media/logos/logo-c.png")}/>
          </Link> */}
                {/*end::Logo*/}

                {/*begin::Toolbar*/}
                <div
                    className="d-flex align-items-center"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                    }}
                >
                    <div>
                        {layoutProps.asideDisplay && (
                            <>
                                {/*begin::Aside Mobile Toggle*/}
                                <button
                                    className="btn p-0 burger-icon burger-icon-left"
                                    style={{color: "white"}}
                                    id="kt_aside_mobile_toggle"
                                >
                                    <span/>
                                </button>

                                {/*end::Aside Mobile Toggle*/}
                            </>
                        )}
                    </div>
                    <div style={{marginRight: "15px"}}>
            <span>
              <Link
                  to="/menu-configuracao"
                  style={{
                      color: "white",
                      fontWeight: "1000",
                      fontSize: "1.2em"
                  }}
              >
                <>Meu perfil</>
                <i
                    className="fas fa-user"
                    style={{margin: "0 20px", color: "#69C3D7"}}
                ></i>
              </Link>
            </span>
                    </div>

                    {/*end::Topbar Mobile Toggle*/}
                </div>
                {/*end::Toolbar*/}
            </div>
            {/*end::Header Mobile*/}
        </>
    );
}
