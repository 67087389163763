import React from "react";
import {Card} from "primereact/card";
import '../style.scss'
import {Link} from "react-router-dom";

export default function CheckinFinalizado() {
    return (
        <>
            <Card id="agendamento-card">
                <div className="checkin-card">
                    <h3>Seu check-in foi enviado para análise.</h3>

                    <div className="checkin-check-wrapper">
                        <div className="checkin-chek-borda">
                            <i className="fas fa-check chekin-check"/>
                        </div>
                    </div>
                </div>
            </Card>
            <div style={{width: '100%', textAlign: 'center', marginTop: '20px'}}>
                <Link to="/home">
                    <h3 style={{color: '#2364AA'}}>Voltar para tela inicial</h3>
                </Link>
            </div>
        </>
    );
}
