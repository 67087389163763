import React from 'react';
import {Switch} from "react-router-dom";
import {ContentRoute} from "../../../vendors/_metronic/layout";
import CheckinLaboratorial from "./pages/CheckinLaboratorial";
import CheckinChoice from "./pages/CheckinChoice";
import CheckinFinalizado from "./pages/CheckinFinalizado";
import CheckinAgendado from "./pages/CheckinAgendado";
import CheckinProntoSocorro from "./pages/CheckinProntoSocorro";

export default function Checkin() {

    return (
        <Switch>
            <ContentRoute
                from="/checkin/laboratorial"
                exact={true}
                component={CheckinLaboratorial}
            />
            <ContentRoute
                from="/checkin/agendado"
                exact={true}
                component={CheckinAgendado}
            />
            <ContentRoute
                from="/checkin/choice"
                exact={true}
                component={CheckinChoice}
            />
            <ContentRoute
                from="/checkin/finalizado"
                exact={true}
                component={CheckinFinalizado}
            />
            <ContentRoute
                from="/checkin/pronto-socorro"
                exact={true}
                component={CheckinProntoSocorro}
            />
        </Switch>
    );
}
