export class FileService {
    downloadFile(result, fileName, type) {
        const fileUrl = this.createUrl(result, fileName, type);
        const anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = fileUrl;
        anchor.setAttribute('target', '_blank');
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }


    openFile(result, fileName, type) {
        const fileUrl = this.createUrl(result, fileName, type);
        window.open(fileUrl, '_blank')
    }

    createUrl(result, fileName, type) {
        const converter = this.base64ToArrayBuffer(result);
        const blob = new Blob([converter], {type: type});
        return window.URL.createObjectURL(blob);
    }

    base64ToArrayBuffer(base64) {
        const binaryString = atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };
}
