import React, {useEffect, useState} from 'react'
import {Link, useHistory, useParams} from "react-router-dom";
import {Card} from "primereact/card";
import {ToastService} from "../../../services/utils/toast.service";
import AgendamentoService from "../services/agendamento.service";
import {BlockuiService} from "../../../services/blockui.service";
import MessageUtil from "../../../shared/utils/MessageUtil";
import {ConditionalComponent} from "../../../../vendors/internalComponents/ConditionalComponent/ConditionalComponent";
import DocumentoAgendamentoUpload from "../components/DocumentoAgendamentoUpload";

export default function DocumentoAgendamento() {
    const [lista, setLista] = useState([]);
    const [nrSeqAutorizacao, setNrSeqAutorizacao] = useState();
    const [tiposAdicionados, setTiposAdicionados] = useState([]);
    const [labels, setLabels] = useState([]);
    const [isConfirm, setIsConfirm] = useState(false);
    const history = useHistory();
    const agendamentoService = new AgendamentoService();
    const tipoDocumentoList = agendamentoService.getTipoDocumentoList();
    const _useParams = useParams();


    useEffect(() => {
        setNrSeqAutorizacao(_useParams.nr_seq_autorizacao);
    }, []);


    function enviar() {
        if (lista.length === 0) {
            ToastService.showError('Nenhum documento selecionado');
            return;
        }
        agendamentoService.dispatch(BlockuiService.start);
        agendamentoService.enviarDocumentos(lista).then(res => {
            agendamentoService.dispatch(BlockuiService.stop);
            ToastService.showSuccess('Documentos enviados com sucesso');
            history.push('/meus-agendamentos');
        }).catch(err => {
            agendamentoService.dispatch(BlockuiService.stop);
            ToastService.showError(MessageUtil.FALHA_REQUISICAO);
        });
    }

    function finalizar() {
        if (lista.length === 0) {
            ToastService.showError('Nenhum documento selecionado');
            return;
        }
        const temp = tiposAdicionados.map(m => tipoDocumentoList[m].label);
        temp.sort();
        setLabels(temp);
        setIsConfirm(true);
    }

    function getTipoDocumentoByIndex(indice) {
        return agendamentoService.getTipoDocumentoByIndex(indice);
    }


    return (
        <>
            <Link to="/meus-agendamentos">
                <h3 style={{color: '#2364AA'}}>Voltar para meus agendamentos</h3>
            </Link>
            <Card className="form-card">
                <ConditionalComponent condition={isConfirm}
                                      negative={
                                          <DocumentoAgendamentoUpload
                                              lista={lista}
                                              tiposAdicionados={tiposAdicionados}
                                              setTiposAdicionados={setTiposAdicionados}
                                              setLista={setLista}
                                              nrSeqAutorizacao={nrSeqAutorizacao}
                                          />
                                      }
                                      positive={
                                          <div>
                                              {
                                                  labels.map((item, index) => {
                                                      return (
                                                          <div
                                                              key={index}
                                                              style={{marginBottom: '20px'}}
                                                              className="documento-agendamento-drop p-d-flex p-flex-row p-align-center">
                                                              <span>
                                                                    <i className="fas fa-check"/>
                                                              </span>
                                                              <label style={{marginLeft: '10px', fontSize: '1.3em'}}>
                                                                  {item}
                                                              </label>
                                                          </div>
                                                      );
                                                  })
                                              }
                                          </div>
                                      }
                />


            </Card>
            <div className="documento-agendamento-botoes">

                <ConditionalComponent
                    condition={isConfirm}
                    positive={
                        <>

                            <div style={{
                                textAlign: 'center',
                                marginTop: '20px',
                                marginRight: '20px',
                                width: '150px',
                                background: '#50a773',
                                borderRadius: '50px'
                            }}>
                                <h3 style={{color: 'white', cursor: 'pointer', marginTop: '10px'}}
                                    onClick={() => enviar()}>Confirmar</h3>
                            </div>
                            <div style={{
                                textAlign: 'center',
                                marginTop: '20px',
                                width: '150px',
                                background: '#cd5e7a',
                                borderRadius: '50px'
                            }}>
                                <h3 style={{color: 'white', cursor: 'pointer', marginTop: '10px'}}
                                    onClick={() => setIsConfirm(false)}>Cancelar</h3>
                            </div>
                        </>
                    }
                    negative={
                        <>
                            <span></span>
                            <div style={{
                                textAlign: 'center',
                                marginTop: '20px',
                                width: '150px',
                                background: '#50a773',
                                borderRadius: '50px'
                            }}>
                                <h3 style={{color: 'white', cursor: 'pointer', marginTop: '10px'}}
                                    onClick={() => finalizar()}>Finalizar</h3>
                            </div>
                        </>
                    }
                />

            </div>
        </>
    );
}
