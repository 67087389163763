import {AbstractService} from "../../../shared/abstracts/abstract.service";
import {checkInApi} from "../../../services/api";
import AuthService from "../../../services/auth.service";

export default class CheckInService extends AbstractService {
    constructor() {
        super("", "", checkInApi);
    }

    consultaCheckin = checkin => {
        return this.api.get(
            `${this.rota}/documentos/${checkin.cdPessoaFisica}/data/${checkin.dtCheckin}`
        );
    };

    obterCheckinPendente(filter) {
        const serialized = this.serialize(filter);
        return this.api.get(`${this.rota}/pendente?${serialized}`)
    }

    create(entity) {
        return this.api.post(`${this.rota}`, entity);
    }

    atualizaDocumentos(documentoAtualizado) {
        return this.api.put(`${this.rota}/documentos`, documentoAtualizado);
    }

    documentos(documento) {
        return this.api.post(`${this.rota}/documentos`, documento);
    }

    salvarPedidoMedico = documentos => {
        return this.api.post(`${this.rota}/documentos/pedido`, documentos);
    };

    atualizarPedidoMedico = documentos => {
        return this.api.put(`${this.rota}/documentos/pedido`, documentos);
    };

    confirmaCheckin = checkin => {
        return this.api.post(`${this.rota}`, checkin);
    };

    verificaCheckinPsAtivo() {
        return this.api.get(
            `${this.rota}/ps/ativo/${AuthService.getUser().cdPessoaFisica}`
        );
    }
}
