import React, {useState} from "react";
import './alterar-senha.scss';
import AuthService from "../../services/auth.service";
import {CadastroService} from "../../services/common/cadastro.service";
import {Card} from "primereact/card";
import {Password} from "primereact/password";
import Criterio from "../../public/Components/Criterio";
import TituloAzul from "../../../vendors/internalComponents/TituloAzul/TituloAzul";
import "../../public/public-layout.scss";
import {Button} from "primereact/button";
import {ToastService} from "../../services/utils/toast.service";
import Environment from "../../../environments/environment";
import {BlockuiService} from "../../services/blockui.service";
import MessageUtil from "../../shared/utils/MessageUtil";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

export default function AlterarSenha() {
    const maiuscula = /.*[a-z].*/;
    const minuscula = /.*[A-Z].*/;
    const numero = /.*[0-9].*/;
    const user = AuthService.getUser();
    const cadastroService = new CadastroService();
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    let criteriosValidos = () => {
        return password && test(minuscula) &&
            test(maiuscula) &&
            test(numero) &&
            password.length;
    }

    const cadastrar = () => {
        if (!criteriosValidos()) {
            ToastService.showError('A senha informada não atende aos requisitos mínimos de segurança');
            return;
        }
        if (password != confirm) {
            ToastService.showError('Senhas informadas não conferem');
            return;
        }
        const obj = {username: user.nrDocumento, dsSenha: password, appCode: Environment.getAppCode()}
        dispatch(BlockuiService.start);

        cadastroService.atualizarSenha(obj).then(res => {
            ToastService.showSuccess(MessageUtil.OPERACAO_REALIZADA_SUCESSO);
            history.push('/menu-configuracao');
            dispatch(BlockuiService.stop);
        }, err => {
            dispatch(BlockuiService.stop);

        });


    };

    let test = (regex) => {
        if (!password) {
            return false;
        }
        return regex.test(password);
    }
    return (
        <>
            <div className="alterar-senha-wrapper">
                <div className="alterar-senha-card">
                    <TituloAzul titulo="Alteração de Senha"/>
                    <Card id="card-alterar-senha">
                        <div className="alterar-senha-card-wrapper">
                            <h3>Por favor, cadastre sua nova senha</h3>
                            <div className="public-input-group">
                                <label className="public-icon">
                                    <i className="fas fa-lock "></i>
                                </label>
                                <Password placeholder="Senha"
                                          value={password}
                                          feedback={false}
                                          onChange={(e) => setPassword(e.target.value)}
                                          maxLength={15}
                                          className="public-input"
                                          toggleMask={true}/>
                            </div>
                            <div className="public-input-group">
                                <label className="public-icon">
                                    <i className="fas fa-lock "></i>
                                </label>
                                <Password placeholder="Confirmar Senha"
                                          promptLabel="Confirme a senha desejada"
                                          value={confirm}
                                          onChange={(e) => setConfirm(e.target.value)}
                                          maxLength={15}
                                          feedback={false}
                                          className="public-input"
                                          toggleMask={true}/>
                            </div>
                            <Button
                                onClick={(e) => cadastrar()}
                                type="text"
                                className="btn-alterar-senha">Confirmar senha</Button>
                        </div>

                    </Card>
                </div>
                <div className="alterar-senha-criterios">
                    <div className="public-button-group alt-criterio-senha">
                        <label style={{color: '#005DA4'}}>

                            Critérios de segurança:
                        </label>
                        <label>
                            <Criterio condition={test(minuscula)}/>
                            Deve possuir letras maiúsculas.
                        </label>
                        <label>
                            <Criterio condition={test(maiuscula)}/>
                            Deve possuir letras minúsculas.
                        </label>
                        <label>
                            <Criterio condition={test(numero)}/>
                            Deve possuir números.
                        </label>
                        <label>
                            <Criterio condition={password && password.length >= 8}/>
                            Limite de 8 a 15 caracteres.
                        </label>
                    </div>
                </div>

            </div>


        </>
    );
}

